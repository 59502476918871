import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "../../assets/css/styles.css";

const debitCreditSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .required("Card Number is required")
    .max(16, "Must be exactly 16 digits"),
  cardName: yup.string().required("Card Name is required"),
  YYYY: yup
    .string()
    .required("Year is required")
    .min(4, "Must be exactly 4 characters")
    .max(4, "Must be exactly 4 characters"),
  CVV: yup
    .string()
    .required("CVC is required")
    .min(3, `Your card's security code is incomplete`)
    .max(4, "Must be exactly 4 characters"),
  MM: yup
    .number()
    .required("Month is required")

    .min(2, "Must be exactly 2 characters")
    // .max(2,'Must be exactly 2 characters')
    .lessThan(13, "Please enter a valid number"),
});

const initialValues = {
  cardNumber: "",
  cardName: "",
  MM: "",
  YYYY: "",
  CVV: "",
  rememberCard: false,
};

interface IDebitCreditPayment {
  onSubmission: (values: any) => void;
  payableAmount: number;
  transitionError: string;
}

export default function DebitCreditPayment({
  onSubmission,
  payableAmount,
  transitionError,
}: IDebitCreditPayment) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        onSubmission(values);
      }}
      validationSchema={debitCreditSchema}
      validateOnChange={true}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <form className="cartForm" onSubmit={handleSubmit}>
            <Box>
              <TextField
                className={classes.cardsInput}
                id="standard-basic"
                label="Card number"
                name="cardNumber"
                onKeyPress={(e) => {
                  if (!/\d/.test(e.key)) {
                    return e.preventDefault();
                  }
                }}
                value={values.cardNumber}
                onChange={handleChange}
                error={touched.cardNumber && Boolean(errors.cardNumber)}
                helperText={touched.cardNumber && errors.cardNumber}
                variant="standard"
                InputLabelProps={{ style: { paddingLeft: "10px" } }}
              />
              <TextField
                className={classes.cardsInput}
                id="standard-basic"
                label="Name on card"
                name="cardName"
                onKeyPress={(e) => {
                  if (!/[a-zA-Z]/.test(e.key)) {
                    return e.preventDefault();
                  }
                }}
                value={values.cardName}
                onChange={handleChange}
                error={touched.cardName && Boolean(errors.cardName)}
                helperText={touched.cardName && errors.cardName}
                InputLabelProps={{ style: { paddingLeft: "10px" } }}
              />

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  className={classes.cardsInput}
                  id="standard-basic"
                  label="MM"
                  name="MM"
                  value={values.MM}
                  onChange={handleChange}
                  error={touched.MM && Boolean(errors.MM)}
                  helperText={touched.MM && errors.MM}
                  InputLabelProps={{ style: { paddingLeft: "10px" } }}
                  onKeyPress={(e) => {
                    if (!/\d/.test(e.key)) {
                      return e.preventDefault();
                    }
                  }}
                />

                <Typography
                  style={{
                    margin: "30px 20px 0 20px",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  /
                </Typography>
                <TextField
                  className={classes.cardsInput}
                  id="standard-basic"
                  label="YYYY"
                  name="YYYY"
                  value={values.YYYY}
                  onChange={handleChange}
                  error={touched.YYYY && Boolean(errors.YYYY)}
                  helperText={touched.YYYY && errors.YYYY}
                  InputLabelProps={{ style: { paddingLeft: "10px" } }}
                  onKeyPress={(e) => {
                    if (!/\d/.test(e.key)) {
                      return e.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <TextField
                    className={classes.cardsInputCVV}
                    id="standard-basic"
                    label="CVV"
                    name="CVV"
                    value={values.CVV}
                    onChange={handleChange}
                    error={touched.CVV && Boolean(errors.CVV)}
                    helperText={touched.CVV && errors.CVV}
                    InputLabelProps={{
                      style: {
                        paddingLeft: "10px",
                        color: "color: rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    onKeyPress={(e) => {
                      if (!/\d/.test(e.key)) {
                        return e.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel
              control={<Checkbox name="rememberCard" onChange={handleChange} />}
              label={
                <p className={classes.checkboxLabel}>Remember this card</p>
              }
              className={classes.checkbox}
            />
            <Box className={classes.orderAmnTotal}>
              <Typography className={classes.ordertext}>Order Total</Typography>
              <Typography className={classes.totalText}>
                ${payableAmount.toFixed(2)}
              </Typography>
            </Box>
            {transitionError && (
              <Typography style={{ color: "red", fontSize: "22px" }}>
                {transitionError}
              </Typography>
            )}
            <Button
              className={classes.payNowBtn}
              type="submit"
              variant="contained"
            >
              Pay Now
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsInput: {
      width: "100%",
      margin: "10px 0",
      "& .MuiInput-underline": {
        borderRadius: 0,
      },
    },
    cardsInputCVV: {
      width: "100%%",
      margin: "10px 0",
      "& .MuiInput-underline": {
        borderRadius: 0,
      },
    },
    inputLabel: {
      paddingLeft: "10px",
      color: "color: rgba(0, 0, 0, 0.6)",
    },
    checkboxLabel: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#1A1A1A",
    },
    orderAmnTotal: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 15,
      border: "1px solid #92929D",
      borderRadius: 12,
      marginTop: 10,
    },
    ordertext: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      textTransform: "capitalize",
    },
    totalText: {
      fontWeight: 800,
      fontSize: "22px",
      lineHeight: "28px",
      textAlign: "right",
      color: "#01040D",
    },
    checkbox: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
    infoIcon: {
      color: "#979797",
    },
    payNowBtn: {
      width: "100%",
      height: "64px",
      borderRadius: "16px",
      backgroundColor: "#6c328b",
      color: "#fff",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      marginTop: "15px",
      "&:hover": {
        backgroundColor: "#6c328b",
      },
    },
  })
);
