import React from "react";
// Customizable Area Start
import DatePicker from 'react-datepicker';
import CreateRewardController, {
    Props
} from "./CreateRewardController.web";
import CommonSelectIconFromLibrary from "../../imagemanagement2/src/CommonSelectIconFromLibrary.web";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import { Autocomplete } from '@material-ui/lab';
import ControlPoint from '@material-ui/icons/ControlPoint';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { editIcon, iconImg } from "./assets";
import { Box, Typography, Switch, TextField, FormControl, Select, MenuItem, Divider, Button, InputAdornment, IconButton, ListItemText, Radio, Popper, Paper } from "@material-ui/core";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

export default class CreateReward extends CreateRewardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderHeadingContainer = () => {
        return (
            <Box className="headingContainer">
                <Typography style={{ fontSize: "22px", fontWeight: 600, color: "#1C1B1B" }}>General Information</Typography>
            </Box>
        )
    };

    renderRewardAssignee = () => {
        return (
            <FormControl variant="outlined" style={{ width: '100%' }}>
                <Autocomplete
                    onChange={(event, value) => this.handleTagUsers(value)}
                    PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
                    renderInput={(params) => (
                        <TextField
                            placeholder="Select branch name"
                            {...params}
                            variant="outlined"
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon style={{ color: "grey" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    className="selctAssignee"
                    options={this.state.names.data}
                    getOptionLabel={(option) => option.attributes?.name}
                    value={this.state.selectedUser}
                    PaperComponent={(props) => <Paper {...props} elevation={3} />}
                    renderOption={(option, { selected }) => (
                        <AssigneeMenuItem
                            selected={selected}
                            key={option.attributes.id}
                            value={option.attributes.id}
                        >
                            <RadioCheckButton checked={selected} />
                            <FolderOpenIcon />&nbsp;
                            <ListItemText primary={option.attributes.name} />
                        </AssigneeMenuItem>
                    )}
                    getOptionSelected={(option, value) => option.attributes.id === value?.attributes.id}
                />
            </FormControl>
        )
    };

    renderPreviewIcon = () => {
        return (
            <Box className="editIconBox">
                <ImageBox src={editIcon} className="editIcon" onClick={this.openAddIconDialogue} /> &nbsp;&nbsp;&nbsp;
                <ImageBox src={`${this.state.iconPreview?.attributes?.image.url}`} className="imageIcon" />
            </Box>
        )
    };

    renderAddIcon = () => {
        return (
            <Box className="iconSelectBox" onClick={this.openAddIconDialogue} data-test-id="iconSelect">
                <ControlPoint style={{ color: "#6C328B", fontSize: "32px" }} />
                <label htmlFor="icon-upload">
                    <Typography style={{ color: "#545650", fontSize: "16px", fontWeight: 600 }}>Add Icon</Typography>
                </label>
            </Box>
        )
    };

    renderAchievementBox = () => {
        return (
            <Box>
                <InputFieldLabel>Reward Name</InputFieldLabel>
                <TextField variant="outlined" className="achievementName" value={this.state.rewardName} placeholder="Type here" fullWidth style={{ marginTop: "3px", backgroundColor: "#fcf6f6" }} onChange={this.handleAchievementName} data-test-id="achievementId" />
                {this.state.achievementError && <p style={{ color: "red", margin: "10px 0px 0px 0px" }}>Maximum 30 characters allowed.</p>}
            </Box>
        )
    };

    renderOwnerBox = () => {
        return (
            <Box>
                <InputFieldLabel>Owner</InputFieldLabel>
                <FormControl fullWidth style={{ marginTop: "3px" }}>
                    <Select
                        displayEmpty
                        MenuProps={{
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            PaperProps: {
                                style: {
                                    width: "452px",
                                },
                            },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            }
                        }}
                        data-test-id="ownerId"
                        value={this.state.rewardOwnerId}
                        inputProps={{
                            IconComponent: () => <ExpandMoreIcon style={{ color: "grey", marginRight: "20px" }} />,
                        }}
                        variant="outlined"
                        className="ownerSelect"
                        onChange={this.handleOwnerChange}
                    >
                        <MenuItem value="" disabled>
                            <span style={{ color: "grey" }}>Select a user</span>
                        </MenuItem>
                        {this.state.ownerNameList?.data?.map((item) => (
                            <StyledMenuitem key={item.id} value={item.id}>
                                {item.attributes.first_name} {item.attributes.last_name}
                            </StyledMenuitem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    };

    renderDueDateBox = () => {
        return (
            <Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                >
                    <InputFieldLabel>Due Date</InputFieldLabel>
                    <Box
                        display="flex"
                        alignItems="center"
                        border="1px dashed #ccc"
                        borderRadius="10px"
                        padding="12px"
                        bgcolor="#f9f6f6"
                        marginTop="3px"
                    >
                        <label htmlFor="dateInput">
                            <img src={iconImg} style={{ marginRight: '10px', width: "28px", height: "28px" }} />
                        </label>
                        <DatePicker
                            dateFormat="MM-dd-yyyy"
                            data-test-id="duedateId"
                            selected={this.state.dueDate}
                            id="dateInput"
                            onChange={this.handleDueDate}
                            placeholderText="Due Date"
                            minDate={new Date()}
                            customInput={
                                <TextField
                                    name="dateInput"
                                    id="dateInput"
                                    size="small"
                                    placeholder="Due Date"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: true
                                    }}
                                />
                            }
                        />
                    </Box>
                </Box>

            </Box>
        )
    };

    renderStatusContainer = () => {
        return (
            <Box>
                <InputFieldLabel>Status</InputFieldLabel>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            className="statusInput"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            data-test-id="statusId"
                            value={this.state.rewardStatus}
                            onChange={this.handleStatusChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                PaperProps: {
                                    style: {
                                        width: "130px"
                                    }
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            inputProps={{
                                IconComponent: () => <ExpandMoreIcon style={{ color: "grey", marginRight: "10px" }} />,
                            }}
                        >
                            <MenuItem value={"Active"}>Active</MenuItem>
                            <MenuItem value={"Not Active"}>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        )
    };

    renderCreateButtonBox = () => {
        return (
            <Box className="createAchievementContainer">
                <Button variant="contained" className={!this.updateStateWithConditions() ? "createAchievementBtn" : "completeContentActive"} onClick={this.postCraeteReward} data-test-id="createAchievementId" disabled={!this.updateStateWithConditions()}>Create reward
                </Button>
            </Box>
        )
    };

    renderContentToggle = () => {
        return (
            <Box className="toggleButtons">
                <InputFieldLabel data-test-id="completeid">Complete content</InputFieldLabel><Switch checked={this.state.contentToggle} className="criteriaToggleBtn" onClick={this.handleContent} data-test-id="contentId" style={{
                    color: this.state.contentToggle ? "#6C328B" : "#545650",
                }}
                />
            </Box>
        )
    };

    renderLoginToggle = () => {
        return (
            <Box className="toggleButtons">
                <InputFieldLabel>Number of days logged in</InputFieldLabel>
                <Switch checked={this.state.daysLoggedInToggle} className="criteriaToggleBtn" style={{
                    color: this.state.daysLoggedInToggle ? "#6C328B" : "#545650",
                }} onChange={this.handleDaysLoggedInToggle} data-test-id="dayslogId" />
            </Box>
        )
    };

    renderLessonComplete = () => {
        return (
            <Box className="toggleButtons">
                <InputFieldLabel>Number of lessons completed</InputFieldLabel>
                <Switch checked={this.state.lessonsCompletedToggle} className="criteriaToggleBtn" style={{
                    color: this.state.lessonsCompletedToggle ? "#6C328B" : "#545650",
                }} onChange={this.handleLessonsCompletedToggle} data-test-id="lessontoggleid" />
            </Box>
        )
    };

    renderCourseComplete = () => {
        return (
            <Box className="toggleButtons">
                <InputFieldLabel>Number of courses completed</InputFieldLabel><Switch checked={this.state.coursesCompletedToggle} className="criteriaToggleBtn" style={{
                    color: this.state.coursesCompletedToggle ? "#6C328B" : "#545650",
                }} onChange={this.handleCoursesCompletedToggle} data-test-id="coursecompleteid" />
            </Box>
        )
    };

    renderRowToggle = () => {
        return (
            <Box className="toggleButtons">
                <InputFieldLabel>Number of days logged in a row</InputFieldLabel>
                <Switch checked={this.state.loggedInRowToggle} className="criteriaToggleBtn" style={{
                    color: this.state.loggedInRowToggle ? "#6C328B" : "#545650",
                }} onChange={this.handleLoggedInRowToggle} data-test-id="rowtoggleid" />
            </Box>
        )
    };

    renderSelectContentButton = () => {
        return (
            <Button variant="contained" className={!this.state.contentToggle ? "completeContentButton" : "completeContentActive"} onClick={this.handleNavigateContent} disabled={!this.state.contentToggle} data-test-id="selectContentid">
                {
                    this.state.courseIdData.length > 0 || this.state.courseIdData.length > 0 ?
                        <span>
                            Change Content
                        </span>
                        :
                        <span>
                            Select content
                        </span>
                }
            </Button>
        )
    };

    renderLoginInput = () => {
        return (
            <Box>
                <DaysTitle>Number of days logged in</DaysTitle>
                <TextField
                    variant="outlined"
                    disabled={!this.state.daysLoggedInToggle}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment className="updownArrowBox" position="end">
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("daysLoggedInInput")} data-test-id="incrementIdDays">
                                    <KeyboardArrowUpIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("daysLoggedInInput")}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} className={this.state.daysLoggedInToggle ? "selectInput" : "disabledSelectInput"} value={this.state.daysLoggedIn === 0 ? "" : this.state.daysLoggedIn} placeholder="Type here..." fullWidth onChange={this.handleDaysLoggedin} data-test-id="daysInputId" />
            </Box>
        )
    };

    renderLessonInput = () => {
        return (
            <Box>
                <DaysTitle>Number of lessons completed</DaysTitle>
                <TextField
                    variant="outlined"
                    disabled={!this.state.lessonsCompletedToggle}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment className="updownArrowBox" position="end">
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("lessonsCompletedInput")}>
                                    <KeyboardArrowUpIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("lessonsCompletedInput")}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} className={this.state.lessonsCompletedToggle ? "selectInput" : "disabledSelectInput"} value={this.state.lessonsCompleted === 0 ? "" : this.state.lessonsCompleted} placeholder="Type here..." fullWidth onChange={this.handleLessonsCompleted} data-test-id="lessoninputid" />
            </Box>
        )
    };

    renderCourseInput = () => {
        return (
            <Box>
                <DaysTitle>Number of courses completed</DaysTitle>
                <TextField
                    variant="outlined"
                    disabled={!this.state.coursesCompletedToggle}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment className="updownArrowBox" position="end">
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("coursesCompletedInput")}>
                                    <KeyboardArrowUpIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("coursesCompletedInput")}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} className={this.state.coursesCompletedToggle ? "selectInput" : "disabledSelectInput"} value={this.state.coursesCompleted === 0 ? "" : this.state.coursesCompleted} placeholder="Type here..." fullWidth onChange={this.handleCoursesCompleted} data-test-id="courseinputid" />
            </Box>
        )
    };

    renderRowInput = () => {
        return (
            <Box>
                <DaysTitle>Number of days logged in, in a row</DaysTitle>
                <TextField
                    variant="outlined"
                    disabled={!this.state.loggedInRowToggle}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment className="updownArrowBox" position="end">
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("loggedInRowInput")}>
                                    <KeyboardArrowUpIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("loggedInRowInput")}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} className={this.state.loggedInRowToggle ? "selectInput" : "disabledSelectInput"} value={this.state.loggedInRow === 0 ? "" : this.state.loggedInRow} placeholder="Type here..." fullWidth onChange={this.handleLoggedInRow} data-test-id="rowinputid" />
            </Box>
        )
    };

    renderRewardTextField = () => {
        return (
            <>
              <RewardTextFielld>
                    <RewardTextTitle>Reward</RewardTextTitle>
                    <Reawrdlabel>Write the kind of reward you are going to give</Reawrdlabel>
                    <StyledTextField
                        required
                        value={this.state.rewardField}
                        onChange={this.handleReward}
                        error={this.state.rewardError}
                        helperText={this.state.helperText}
                        data-test-id="textFieldId"
                        fullWidth
                        placeholder='Write your reward'
                    />
            </RewardTextFielld>
            </>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <MainWrapper>
                    <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px" , borderRadius: "16px"}}>
                        {this.renderHeadingContainer()}
                        <Box className="generalInfoBox">
                            {this.state.iconPreview?.id ?
                                <>
                                    {this.renderPreviewIcon()}
                                </>
                                :
                                this.renderAddIcon()
                            }
                            <Box className="inputContainer">
                                <Box className="inputWrapper">
                                    {this.renderAchievementBox()}
                                    {this.renderOwnerBox()}
                                </Box>
                                <Box className="assigneesWrapper">
                                    <Box style={{ flex: "1" }}>
                                        <InputFieldLabel>Assignees</InputFieldLabel>
                                        {this.renderRewardAssignee()}
                                    </Box>
                                    <DueStatusContainer>
                                        {this.renderDueDateBox()}
                                        <Divider orientation="vertical" flexItem  className="dividerClass"/>
                                        {this.renderStatusContainer()}
                                    </DueStatusContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {this.renderRewardTextField()}
                    <Box className="footerContainer">
                        <Box className="criteriaContainer">
                            <Typography style={{ color: "1C1B1B", fontSize: "22px", fontWeight: 600, fontFamily: "Poppins" }}>Criteria</Typography>
                            <Divider style={{ marginBottom: "10px" }} />
                            <Box className="toggleContainer">
                                {this.renderContentToggle()}
                                {this.renderLoginToggle()}
                                {this.renderLessonComplete()}
                                {this.renderCourseComplete()}
                                {this.renderRowToggle()}
                            </Box>
                        </Box>
                        <Box className="contentWrapper">
                            {this.renderSelectContentButton()}
                            <Box className="selectContainer">
                                {this.renderLoginInput()}
                                {this.renderLessonInput()}
                                {this.renderCourseInput()}
                                {this.renderRowInput()}
                            </Box>
                        </Box>
                    </Box>
                    {this.renderCreateButtonBox()}
                </MainWrapper>
                {this.state.showChooseIconPrompt &&
                    <DeleteModal
                        handleCancel={this.closeAddIconDialogue}
                        handleOk={() => this.openIconLibrary()}
                        promptOption={true}
                        handleOptionOne={() => this.handleAddIconFromLocal()}
                        data-test-id='option-prompt'
                        openDialog={this.state.showChooseIconPrompt}
                        headingText={`Where do you want to select the icon from?`}
                        btnOkText="Icon library"
                        btnCancelText="Local storage"
                    />
                }
                {this.state.showIconListingDialogue &&
                    <CommonSelectIconFromLibrary
                        data-test-id="choose-icon-from-prompt"
                        open={this.state.showChooseIconPrompt}
                        CloseModal={this.closeAddIconDialogue}
                        id="id"
                        navigation={this.props.navigation}
                        onClose={this.handleAddIconFromIconListDialogue}
                    />
                }
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    padding: "20px",
    "& .selctAssignee .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
        paddingRight: "15px !important",
        backgroundColor: "rgb(252, 246, 246)",
        borderRadius: "8px"
    },
    "& .assigneesWrapper": {
        display: 'flex',
        gap: "10px",
        "@media screen and (max-width: 992px)": {
            display: "grid",
            gridTemplateColumns: "1fr",
        }
    },
    "& .iconSelectBox": {
        display: "flex",
        color: "#C7AFD2",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #6C328B",
        boxSizing: "border-box",
        height: "198px",
        width: "250px",
        borderRadius: "8px",
        marginBottom: "20px",
    },
    "& .editIcon": {
        width: '24px',
        height: '24px',
    },
    "& .imageIcon": {
        width: '120px',
        height: '120px',
        borderRadius: "12px"
    },
    "& .headingContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
    },
    "& .editIconBox": {
        display: "flex",
        color: "#C7AFD2",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "198px",
        width: "250px",
        padding: "10px"
    },
    "& .generalInfoBox": {
        display: "flex",
        padding: "10px 20px",
        gap: "40px",
        placeContent: "space-between",
        "@media screen and (max-width: 768px)": {
            display: "grid",
            placeItems: "center",
        },
        "@media screen and (max-width: 420px)": {
            display: "grid",
            placeItems: "baseline",
        }
    },
    "& .inputContainer": {
        display: "grid",
        gap: "10px",
        flex: 1
    },
    "& .inputWrapper": {
        gap: "20px",
        "@media screen and (max-width: 992px)": {
            gridTemplateColumns: "1fr"
        },
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
    },
    "& .footerContainer": {
        marginTop: "40px",
        display: "flex",
        gap: "30px",
        "@media screen and (max-width: 768px)": {
            display: "grid",
            gridTemplateColumns: "1fr"

        }
    },
    "& .statusInput .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent !important"
    },
    "& .toggleButtons": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .criteriaContainer": {
        minWidth: "40%",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
        height: "260px"
    },
    "& .contentWrapper": {
        flex: 1,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
        padding: "20px",
        borderRadius: "10px",
    },
    "& .selectContainer": {
        marginTop: "20px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
    },
    "& .completeContentButton": {
        background: "#E5E2E1",
        borderRadius: "12px",
        padding: "10px 40px",
        color: "#929090",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600
    },
    "& .criteriaToggleBtn": {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: '#6C328B',
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'var(--sys-color-PrimaryContainer, #EEE8F2)'
            },
        },
    },
    "& .completeContentActive": {
        background: "#652786",
        padding: "10px 40px",
        color: "white",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "16px"
    },

    "& .disabledSelectInput": {
        background: "#E4E2E1",
        borderRadius: "8px",
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
            borderRadius: "8px",
        }
    },
    "& .achievementName": {
        background: "#FCF8F8",
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px !important",
        }
    },
    "& .selectInput": {
        borderRadius: "8px",
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
            borderRadius: "8px",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px !important",
        }
    },
    "& .ownerSelect": {
        background: "#FDF8F8",
        backgroundColor: "#fcf6f6",
        borderRadius: "8px"
    },

    "& .dateInput": {
        background: "#FDF8F8",
        border: "2px dashed gray",
        boxSizing: "border-box",
        borderRadius: "8px",
    },
    "& .createAchievementContainer": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
    },
    "& .assigneeInput": {
        background: "#FDF8F8",
        display: "inline-flex",
        alignItems: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        backgroundColor: "#fcf6f6",
        borderRadius: "8px"
    },
    "& .createAchievementBtn": {
        textTransform: "none",
        color: "#929090",
        background: "#E5E2E1",
        padding: "10px 60px",
        borderRadius: "16px",
    },
    "& .updownArrowBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    "& .selectedAssignee": {
        maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block"
    },
    "& .numberIcon": {
        padding: "0",
        color: "gray",
    },
    "& .statusInput": {
        borderRadius: "27px",
        background: "#EEE8F2",
        color: "#652786",
        border: "0px",
        fotnWeight: 400,
        fontSize: "16px"
    }
});

const DaysTitle = styled(Typography)({
    color: "#787776",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins !important"
});

const StyledMenuitem = styled(MenuItem)({
    "&:hover": {
        background: "#EEE8F2",
    },
    "&.Mui-selected": {
        background: "#EEE8F2 !important",
    }
});

const InputFieldLabel = styled(Typography)({
    color: "1C1B1B",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins !important"
});

const RadioCheckButton = styled(Radio)({
    color: '#673ab7',
    '&.Mui-checked': {
        color: '#673ab7',
    }
});

const AssigneeMenuItem = styled(MenuItem)({
    width: "100%"
});

const StyledTextField =  styled(TextField)({
    borderRadius: "8px",
    backgroundColor:"#FCF8F8",
    outline: "0px",
    "& .MuiInput-underline": {
        borderRadius: "8px",
        backgroundColor: "rgb(252, 246, 246)",
        border: "1px solid  #E5E2E1",
        height: "56px",
        padding: "0px 15px"
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "red !important"
    },
    "& .MuiFormHelperText-root": {
        color: "red !important"
    }
});

const ImageBox = styled("img")({
    width: '24px',
    height: '24px',
});

const RewardTextFielld = styled(Box)({
    display: "flex", 
    flexDirection: "column", 
    gap: "20px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
    width: "100%",
    marginTop: "30px",
    marginBottom: "30px",
    padding: "30px",
    borderRadius: "16px",
    "& .MuiFormHelperText-root.MuiFormHelperText-filled.Mui-required": {
      color: "black",
      fontSize: "12px",
      fontWeight: 400
    }
});

const RewardTextTitle = styled(Typography)({
  fontSize: "22px",
  fontWeight: 500,
  color: "#1C1B1B"
});

const Reawrdlabel = styled(Typography)({
    fontSize: "18px",
  fontWeight: 400,
  color: "#1C1B1B"
});

const DueStatusContainer = styled(Box)({
    display: "flex",
    gap: "10px",
    flexFlow: "wrap", 
    minWidth: "30%",
    "@media (max-width : 400px)" : {
        "& .MuiDivider-vertical":{
            width: "0px"
        }
    },
});

// Customizable Area End