import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sortTableData } from "../../utilities/src/TableHelpers";
import { ChangeEvent} from "react";
import { ContentData } from "../../../blocks/videolibrary/src/VideoLibraryController";
import * as yup from "yup";
import debounce from "lodash.debounce";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export interface CheckboxSelectedListType { value: string, id: string }


export interface InvalidResponseType {
  errors: {
    message: string;
  }[];
}
export interface CheckboxSelectedListTypes { value: string, id: string }
export interface ExpertListings {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    full_name:string,
    profession: string,
    avatar: null,
    is_admin: boolean
  }
}
export const  validationSchema = yup.object().shape({
  AddIcon: yup
    .string()
    .required("Title is required"),
})

  export interface ThumbnailType {
    data: ContentData[];
  }
   
interface DeleteResponse {
    message:string
  
}

interface UploadInputData {
  AddIcon:string
}
interface FileuploadResponse {
  data:{
  id:string,
  type:string,
  attributes:{
    title:string,
    created_at:string,
    image:{
      id:number,
      url:string,
       filename:string
   },
   created_by:string
  } 
}

}
  interface NumberArrays {
    map(arg0: (id: number) => string): unknown;
    length: number;
    [index: number]: number;
}
export interface CalendarValues {
  0: string | number | Date;
  1: string | number | Date;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  location: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  bulkDelete: NumberArrays;
  showFullDescription: boolean;
  fullScreenPdf: boolean;
  latestId: number;
  deleteIconModal: boolean;
  deleteBulkModal: boolean;
  IconData: Array<ContentData>|null;
  anchorFilterContentsEl: null | HTMLElement;
  openContentNavigate: boolean;
  tagsSearchText: string;
  fileSelectedItems: Array<{value:string, id:string}>;
  tagParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  creationNameParams: string,
  fileTypeParams: string;
  searchContentValue: string;
  showContentList: boolean;
  uploadFileProgress:number,
  uploadFileStatus:string,
  isAttachmentChange:boolean,
  attachedFile:File|null,
  deleteToast:{ isOpen: boolean, msg?: string, type?: string };
  showMessage:boolean,
  filteredContentList: Array<string>,
  FileType:string,
  AddIconName
:string,
searchAdvanceValues:string;
filteredContentsLists: Array<string>,
showContentData:boolean;
 filterItemsDatas:boolean;
placeholdersParams: string;
placeholderSelectsItems:Array<CheckboxSelectedListTypes>
creationIconsDate:string;
creationDateParamsdatas: {
  start_date: string;
  end_date: string;
};
isCalendarshow:boolean;
placeholderSearchsTexts:string;
anchorsEl: null | HTMLElement;
dropdownType: string;
expertAdminData: Array<ExpertListings>;
searchList:boolean;
RemoveDeleteButton:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class IconmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllIconCallID:string=""
  deleteSingleContentApiCallId: string = "";
  deleteBulkContentApiCallId: string = "";
  contentDetailApiCallId: string = "";
  UploadFileIconApiCallId: string="";
  getAdvanceSearchApiCallIds:string="";
  GetAdminExpertListCallIds:string="";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openContentNavigate: false,
      anchorFilterContentsEl: null,
      bulkDelete: [],
      IconData: [],
      showFullDescription: false,
      fullScreenPdf: false,
      latestId: 0,
      deleteIconModal: false,
      deleteBulkModal: false,
      tagsSearchText: "",
      tagParams: "",
      fileSelectedItems: [],
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      creationNameParams: "",
      fileTypeParams: "",
      searchContentValue: "",
      showContentList: false,
      uploadFileProgress:0,
      isAttachmentChange:false,
      attachedFile:null,
      uploadFileStatus: "notStarted",
      deleteToast:{ isOpen: false, msg: " ", type: "success" },
      showMessage:false,
      filteredContentList:[],
      FileType:"",
      AddIconName:"",
      searchAdvanceValues:"",
      filteredContentsLists:[],
      showContentData:false,
       filterItemsDatas:false,
      placeholderSelectsItems:[],
      placeholdersParams:"",
      creationIconsDate:"",
      creationDateParamsdatas: {
        start_date: "",
        end_date: ""
      },
      isCalendarshow:false,
      placeholderSearchsTexts:"",
      anchorsEl: null,
      dropdownType: "",
      expertAdminData:[],
      searchList:false,
      RemoveDeleteButton:false
    
      
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  
  
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "From: " + this.state.txtSavedValue + " To: " + value,
        "Change Value"
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
const apiRequestCallIds = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
const responseJsons = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showToast){
  const value=message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showToast
  this.setState((prev) => ({
    ...prev,
    deleteToast: {
      isOpen: true,
      msg: `${value} has been uploaded`
    },attachedFile:null
  }));
  this.handleToastSuccessClose()
}
if (apiRequestCallIds === this.getAllIconCallID) {
  this.getIconLibrarylData(responseJsons)
 
  this.parseApiCatchErrorResponse(errorResponse);
}
if(apiRequestCallIds ===this.deleteSingleContentApiCallId){
 
this.getIconLibraryDelete(responseJsons)
  
}
if(apiRequestCallIds === this.UploadFileIconApiCallId){
  this.fileIconUploadSuccess(responseJsons)
}
if(apiRequestCallIds ===this.deleteBulkContentApiCallId){
    this.getBulkIconLibraryDelete(responseJsons) 
}else if(apiRequestCallIds===this.getAdvanceSearchApiCallIds){
  this.setState({filteredContentsLists:responseJsons.data})
}else if (this.GetAdminExpertListCallIds === apiRequestCallIds) {  
  this.setState({
    expertAdminData: responseJsons.data
  })
}

    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {

    super.componentDidMount();
    
    this.getAllIconApi();
    this.getAdminExpertListings();
  
  }

  isInValidResponse = (responseJsons: InvalidResponseType) => {
    return responseJsons.errors;
  };
  getAdvancedSearchLists=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AdvancedSearchEndPoint}?type=icon&search=${this.state.searchAdvanceValues}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
handleInputChangeSearch = (event:any) => {
  this.setState({searchAdvanceValues:event.target.value},() => {
    this.getAllIconApi()
  })
  this.setState({searchList:false,showContentData:event.target.value !== ""},()=>{
    if (this.state.searchAdvanceValues !== "") {
      this.getAdvancedSearchLists()
    }
  }) 
 }
 handleThumbnailListClick=(item:string)=>{
  this.setState({
    searchList:true,
    searchAdvanceValues: item,
    showContentData: false
  },()=>{
     this.getAllIconApi()
  })
 }
  getIconLibrarylData=(responseJsons:ThumbnailType)=>{
    this.setState({IconData:responseJsons.data.slice().reverse()})
  }

  getSelectedIDChecbox = (list:Array<number>) => {
    this.setState({
      bulkDelete: list
    })
  }
  updateChildState = (newState:any) => {
    this.setState({ RemoveDeleteButton: newState });
  };
  ConstructEndPoints = () => {
    let params = [];
  
    if (this.state.searchAdvanceValues && this.state.searchList) {
      params.push(this.searchValueParams());
    }
    if (this.state.creationDateParamsdatas) {
      params.push(this.addCreationDateRangeParam());
    }
    if (this.state.creationIconsDate) {
      params.push(this.addCreationDateParam());
    }
    if(this.state.placeholdersParams){
      params.push(this.addplaceholdersParams())
    }
    params = params.filter(param => param !== '');
    return params.length > 0 ? params.join('&') : '';
  };
  private searchValueParams(): string{
    return `search=${this.state.searchAdvanceValues ? encodeURIComponent(this.state.searchAdvanceValues):""}`;
  }
  private addCreationDateParam(): string {
    const dateParam = this.getDateParam();
    return dateParam ? `q[created_at]=${dateParam}` : ''
  }

  private addCreationDateRangeParam(): string {
    const { start_date, end_date } = this.state.creationDateParamsdatas;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `q[custom_range]=${queryString}`;
    }
    return '';
  }
  private addplaceholdersParams(): string {
    return this.state.placeholdersParams ? `&${this.state.placeholdersParams}` : '';
  }
  private getDateParam(): string {
    switch (this.state.creationIconsDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }
  handleClicksFilters = () => {
    this.setState({
      anchorFilterContentsEl: null
    }, () => {
      this.getAllIconApi()
    })
  
  }
  placeholderDebounced: () => void = debounce(
    () => this.getAdminExpertListings(),
    700
  )
  handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
       filterItemsDatas: !this.state. filterItemsDatas,
      anchorFilterContentsEl: event.currentTarget,
    })
  };
  dropdownHandlerOpens = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorsEl: event.currentTarget,
      dropdownType
    })
  }
  handleClearFilters=()=>{
    this.setState({
      anchorFilterContentsEl: null,
    placeholdersParams: "",
    placeholderSelectsItems:[],
    creationIconsDate:"",
    creationDateParamsdatas: {
      start_date: "",
      end_date: ""
    },
    isCalendarshow:false,
    placeholderSearchsTexts:"",
    anchorsEl: null ,
    dropdownType: "",
      }, () => {
        this.getAllIconApi()
      })
  }
  handleOpenCalendors = () => {
    this.setState({
      isCalendarshow: true
    })
  }
  handleChangeCalendors = (value: CalendarValues) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDate = startDate.getDate();
    const formattedStartDateNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedStartYear = startDate.getFullYear();
    const formattedEndDate = endDate.getDate();
    const formattedEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndYear = endDate.getFullYear();
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      creationIconsDate: dateRange,
      creationDateParamsdatas: {
        start_date,
        end_date
      }
    });
  }
  
  handlePlaceholderAssignedSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchsTexts: event.target.value
    }, () => {
      this.placeholderDebounced();
    })
  }
  dropdownHandlerClose = () => {
    this.setState({
      anchorsEl: null,
      isCalendarshow: false,
      dropdownType: "",
      // categoryId: [],
    })
  }
  creationChangeHandlerDates = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationIconsDate: event.target.value
    })
  }
  filterContainerDropdownCloseHandlers = () => {
    this.setState({
      anchorFilterContentsEl: null
    })
  }
  
  placeholderCheckboxAssignedExpertChangeHandlers = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectsItems: [...prevState.placeholderSelectsItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectsItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectsItems: prevState.placeholderSelectsItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectsItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholdersParams: params
        });
      });
    }
  }
  getAdminExpertListings=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.GetAdminExpertListCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.expertAdminListApiEndPoint}?search=${this.state.placeholderSearchsTexts}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
getAllIconApi=async()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getAllIconCallID = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getIconEndPoint}?${this.ConstructEndPoints()}`
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}
sortUsers = (dataType: string, keyType: string, sortType: string) => {
  const sortedUsers = sortTableData(
    this.state.IconData,
    dataType,
    keyType,
    sortType,
  );
  this.setState({ IconData: sortedUsers });
};



deleteSingleIcon = (idType: number) => {
 this.setState({
  latestId:idType,
  deleteIconModal: true,
 })  
  } 

handleDeleteContent = (latestId: number) => {
  this.deleteContentValue(latestId);
}

deleteContentValue(latestID: number) {
    this.DeleteSingleContent({
      content_Types: configJSON.validationApiContentType,
      method: configJSON.DeleteApiMethod,
      end_Point: `${configJSON.getIconEndPoint}/${latestID}`
    });
}
DeleteSingleContent(data: {
  content_Types?: string;
  end_Point?: string;
  method?: string;
  body?: {};
  type?: string;
}) {
  const { content_Types, method, end_Point } = data;
  const header = {
    "Content-Type": content_Types,
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.deleteSingleContentApiCallId = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    end_Point
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}

cancelIconAllDeleteDialog = () => {
  this.setState({
    deleteBulkModal: false
  })
}

handleToastSuccessClose=()=>{
  setTimeout(() => {
    this.setState((prev) => ({
      ...prev,
      deleteToast: {
        isOpen: false,
      },
    }));
  }, 3000);
}
handleDeleteResponseMessage=(msg:string,showMessage:boolean)=>{
  this.setState((prev) => ({
    ...prev,
    showMessage,
    deleteToast: {
      isOpen: true,
      msg,
      type: "success"
    },
    deleteBulkModal: false,
    deleteIconModal:false,
    RemoveDeleteButton: false
  }));

  this.getAllIconApi();
  this.handleToastSuccessClose();
}
handleDialogAlert=(response:DeleteResponse)=>{
 if(response.message){
  let showmessage=false;
  this.handleDeleteResponseMessage(response.message,showmessage)
 }
 
}
getIconLibraryDelete=(responseJsons:DeleteResponse)=>{
 this.handleDialogAlert(responseJsons)
}

handleDeleteBulkIcon = () => {
  this.setState({
    deleteBulkModal: true
  })
}
getBulkIconLibraryDelete = (responseJson: any) => {
  const { message1, message2 } = responseJson;

  let msg = "";
  let showMessage = false;

  if (message1.length && message2.length) {
    showMessage = true;
  } else if (message1.length) {
    msg = "Deletion successful";
  } else if (message2.length) {
    msg = "Some thumbnails could not be deleted since they are being used.";
  }
 this.handleDeleteResponseMessage(msg,showMessage)
};

handleDeleteAllContent = () => {
this.deleteBulkContentItem() 
}


deleteBulkContentItem() {
  const bulkDeleteIds = (this.state.bulkDelete as number[])?.map(contentId => `icon_ids[]=${contentId}`).join('&');
  const end_Point = `${configJSON.getIconEndPoint}/bulk_delete?${bulkDeleteIds}`;
  this.doDeleteBulkContentItem({
      content_Types: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      end_Point: end_Point
  });
}

doDeleteBulkContentItem(data: {
content_Types?: string;
method?: string;
end_Point?: string;
body?: {};
type?: string;
}) {
  const { content_Types, method, end_Point } = data;
const header = {
  "Content-Type": content_Types,
  token: localStorage.getItem("token"),
};
const requestMessages = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
requestMessages.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

this.deleteBulkContentApiCallId = requestMessages.messageId
requestMessages.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  end_Point
);
requestMessages.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);
runEngine.sendMessage(requestMessages.id, requestMessages);
return requestMessages.messageId;
}
onSelectFile = (file: File) => {
  this.setState({ isAttachmentChange: true,FileType:"image", attachedFile: file, uploadFileProgress: 0, uploadFileStatus: 'uploaded', })
}

handleAddIcon = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "AddIcon");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}

handleIconUploadClick=(values:UploadInputData)=>{
  this.setState({AddIconName:values.AddIcon})
  const formData = new FormData();
  if(this.state.attachedFile){
    formData.append('icon[image]', this.state.attachedFile);
    formData.append('icon[title]', values.AddIcon);
  }

  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  this.UploadFileIconApiCallId = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getIconEndPoint
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}
fileIconUploadSuccess= async(responseJSON:FileuploadResponse)=>{
  if(responseJSON.data){
    const originScreen = await getStorageData("originScreen");
    // Check if "originScreen" exists and navigate back with the thumbnail data
    if (originScreen) {
      this.props.history.push({
        pathname: originScreen,
        state: {
          icon: responseJSON.data,
          formData : this.props.location.state?.formData
        }
      });
      removeStorageData("originScreen");
    // If "originScreen" doesn't exist, navigate to "Thumbnailmanagement" and show a snackbar
    } 
    else{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Iconmanagement");
    message.addData(getName(MessageEnum.NavigationPayLoadMessage),{showToast:this.state.AddIconName
  });
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  }}
  SelectedIconFileDeleted=()=>{
    this.setState({ isAttachmentChange: true, attachedFile: null})
  }

cancelContentDeleteDialog = () => {
this.setState({
  deleteIconModal: false
})
}

  // Customizable Area End
}
