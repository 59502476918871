import React, { useEffect } from "react";
import {
  Box, Button,
  // Customizable Area Start
  Grid,
  // Customizable Area End
  Paper,
  TextField, Typography,
  Accordion, AccordionDetails,
  AccordionSummary, Backdrop,
  Checkbox, CircularProgress,
  FormControl,
  Card,
  CardActionArea
} from "@material-ui/core";
// Customizable Area Start
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RangeSlider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme, makeStyles, styled } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import { Bar, Line } from 'react-chartjs-2';
import Slider from "react-slick";
import PersonalSlider from "../../../components/src/LibraryComponent/PersonalSlider";
import XpandSlider from "../../../components/src/LibraryComponent/XpandSlider";
import CorporateSlider from "../../../components/src/LibraryComponent/CorporateSlider";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { iconVideo, noContentImg,startRating,emptyStart,iconCart } from "./assets";
import './videostyles.css';
import Rating from '@material-ui/lab/Rating';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import CustomCarousel from "../../../../packages/components/src/CustomCarousel/CustomCarousel";
// Customizable Area End
import Library2Controller, {
  LibraryDataType,
  Props
} from "./Library2Controller.web";
const play = require("../assets/icon_video_white.png")
const cart = require("../assets/Cart_white.png")
const group = require("../assets/Group 8.png")
export default class Library2 extends Library2Controller {
  // Customizable Area Start
  renderContentData = (itemsToShow:number) => {
    return (
<>
                {this.sortingMyContentData(this.state.myContentData).filter((conent:any,index:number)=>index < 5).map((conent: any, index: number) => {
                  const totalVideoCount = conent.attributes.total_video_count || 0;
                  const completedVideoCount = conent.attributes.completed_video_count || 0;
                  const completionPercentage = totalVideoCount
                    ? (completedVideoCount / totalVideoCount) * 100
                    : 0;
                  return (
                <Box style={{
                  margin:"0 15px" ,
                  height: "407px",
                  position: "relative",
                  width:"270px",
                  cursor:"pointer"
                }} key={index} onClick={()=>this.handleNavigateTOProduct(conent)}>
                  <Box
                    style={{
                      height: "181px",
                      padding: "16px 0px 0px 0px",
                      gap: "13px",
                      borderRadius: "12px 0px 0px 0px",
                      opacity: "0px",position:"relative"
                    }}>
                    <img width="100%" height="100%" style={{borderRadius:"12px"}} src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                    {conent.attributes.collection.attributes.expand_type === "Program" && 
                    <Typography style={{...styles.chipTypography,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{conent.attributes.collection.attributes.expand_type}</Typography>}
                  </Box>

                  <Box
                    style={{
                      width:"100%",
                      height: "242px",
                      padding: "24px",
                      borderRadius: "12px ",
                      position: "absolute",
                      bottom:5,
                      backgroundColor:"white",
                      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"
                    }}>
                      <Box style={{
                        display:"flex",
                        gap: "16px",
                        flexDirection:"column"

                      }}>

                     
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "22px",
                        textAlign: "left",
                        color:"#1C1B1B"
                      }}>{conent.attributes.collection.attributes.title}</Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "22px",
                      }}
                    >{conent.attributes.collection.attributes.created_by}</Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        color:"#484646"
                      }}
                    >{(conent.attributes.due_in_days === "Overdue" || conent.attributes.due_in_days === "Completed") ? conent.attributes.due_in_days : `Due in ${conent.attributes.due_in_days} Days`}</Typography>

                    <Box style={{display:"flex",justifyContent:"space-between",position:"absolute",bottom:"20px"
                      ,width:"80%"
                    }}>
                      <Box>
                      <Box style={{ 
    width: "154px", 
    height: "10px", 
    borderRadius: "88px", 
    backgroundColor: "rgba(236, 195, 195, 0.23)", 
    position: "relative" 
  }}>
    <span style={{ 
      display: "block", 
      width: `${completionPercentage}%`, 
      height: "10px", 
      borderRadius: "88px", 
      background: "linear-gradient(155.66deg, #EF2B4B -1.02%, #FF8B3F 68.24%)",
      position: "absolute", 
      top: 0, 
      left: 0 
    }}></span>
  </Box>
                        <Typography style={{
                          color: "var(--sys-color-Secondary, rgba(228, 112, 4, 1))",
                          fontWeight:400,fontSize:"12px",
                          marginTop:"10px"
                        }}>
                      {`${conent.attributes.completed_video_count}/${conent.attributes.total_video_count} Lessons Completed`}
                      </Typography>
                      </Box>
                      <Box style={{
                          width:"40px",height:"40px",borderRadius:"8px",
                          boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                          display:"flex",justifyContent:"center",alignItems:"center"
                        }}>
                        <img src={iconVideo}/>
                        </Box>
                     </Box>
                    </Box>
                    </Box>
                </Box>
              )})}</>
    )
  }

  renderNewlyAddedData = (itemsToShow:number) => {
       return (
<>
                {this.sortingMyContentData(this.state.newlyAddedData).filter((newlyData:any,index:number)=>index < 5).map((newlyData: any, index: number) => {
                  return (
                <Box style={{
                  margin:"0 15px" ,
                  height: "407px",
                  position: "relative",
                  width:"270px",
                  cursor:"pointer"
                }} key={index} onClick={()=>this.handleNavigateTOProduct(newlyData)}>
                  <Box
                    style={{
                      height: "181px",
                      padding: "16px 0px 0px 0px",
                      gap: "13px",
                      borderRadius: "12px 0px 0px 0px",
                      opacity: "0px",position:"relative"
                    }}>
                    <img width="100%" height="100%" style={{borderRadius:"12px"}} src={newlyData.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                    {newlyData.attributes.collection.attributes.expand_type === "Program" && 
                    <Typography style={{...styles.chipTypography,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{newlyData.attributes.collection.attributes.expand_type}</Typography>}
                  </Box>

                  <Box
                    style={{
                      width:"100%",
                      height: "242px",
                      padding: "24px",
                      borderRadius: "12px ",
                      position: "absolute",
                      bottom:5,
                      backgroundColor:"white",
                      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"
                    }}>
                      <Box style={{
                        display:"flex",
                        gap: "16px",
                        flexDirection:"column"

                      }}>


                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        height:"30px",
                        fontWeight: 700,
                        lineHeight: "22px",
                        textAlign: "left",
                        color:"#1C1B1B"
                      }}>{
                        newlyData.attributes.collection.attributes.title.length>20 ? (
                          `${newlyData.attributes.collection.attributes.title.slice(0,20)}...`
                        ) :(
                          newlyData.attributes.collection.attributes.title
                        )
                        }</Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "22px",
                      }}
                    >{newlyData.attributes.collection.attributes.created_by}</Typography>

                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        color:"#484646"
                      }}
                    >{(newlyData.attributes.due_in_days === "Overdue" || newlyData.attributes.due_in_days === "Completed") ? newlyData.attributes.due_in_days : `Due in ${newlyData.attributes.due_in_days} Days`}</Typography>

                      {newlyData.attributes.collection.attributes.is_paid
                        ?

                        <>
                          <Card style={{
                            width: '87px',
                            height: '45px',
                            borderRadius: '30px',
                            float: 'right',
                            marginTop: '-2px', 
                            marginRight: '20px',
                            background: '#1D1F4F'
                          }}>
                            <CardActionArea>
                            </CardActionArea>
                          </Card>
                        </>
                        :
                        <ButtonWrapper>
                          <Box>
                          <Typography
                         style={{
                          fontFamily: 'Poppins',
                          fontSize: '18px',
                          fontWeight: 700,
                          lineHeight: '24px',
                          textAlign: 'left',
                          width: "98px",
                          height: "24px",
                          gap: "0px",
                          opacity: "0px",
                          textTransform:'capitalize'
                          // marginLeft:"24px",

                         }}
                         >{newlyData.attributes.purchase_type==="free"?newlyData.attributes.purchase_type:`$${newlyData.attributes.price}`}</Typography>
                          
                          </Box>
                        
                          {newlyData.attributes.purchase_type==="free"
                          ?
                           <Card 
                          style={{
                            width: '106px', 
                            height: '48px', 
                            borderRadius: '30px',
                            color:"white",
                            float: 'right',
                            marginTop: '-2px',
                            marginRight: '25px',
                            background: "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)",
                            margin:"12px 16px 18px 6px"
                          }}>
                            <CardActionArea
                              id="post-newlyadded-data-play-btn"
                              onClick={() => this.postplayButton(newlyData)}>
                            <img src={play} style={{ margin: 'auto', padding: '12px', display: 'flex',color:"white" }} />
                            </CardActionArea>
                          </Card>
                            
                           : <Card 
                          style={{
                            width: '106px', 
                            height: '48px', 
                            borderRadius: '30px', 
                            float: 'right',
                            marginTop: '-2px',
                            marginRight: '25px',
                            background: "black",
                            margin:"12px 16px 18px 6px"
                          }}>
                            <CardActionArea
                              id="post-newlyadded-data-play-btn"
                              onClick={() => this.postplayButton(newlyData)}>
                            <img src={iconCart} style={{ margin: 'auto', padding: '12px', display: 'flex' }} />
                            </CardActionArea>
                          </Card>}
                        </ButtonWrapper>
                      }
                  </Box>
                  {newlyData.attributes.review > 0 &&
                     <Box style={{display:'flex',alignItems:'center',gap:'3px',position:'absolute',bottom:'10px'}}>
                     <Typography style={{color: '#FF8B3F',
                        fontSize: '16px',
                        fontWeight:600}}>{newlyData.attributes.review % 1 === 0 ? newlyData.attributes.review :newlyData.attributes.review.toFixed(1)}</Typography>
                     <Rating
                        name='read-only'
                        value={newlyData.attributes.review.toFixed(1)}
                        precision={0.5}
                        readOnly
                        icon={<img src={startRating}/>}
                        emptyIcon={<img src={emptyStart}/>}
                    />
                     </Box>
                      }
                  </Box>



                </Box>
              )})}</>
    )
  }

  renderContent = () => {
    return (
      <Box style={{gap:"16px",margin:"10px 0px 10px 20px"}}>
      <Paper className="paper" style={{ display: "flex",justifyContent:"space-between",alignItems:"center", }}>
        <Typography style={{...styles.typography_personal,marginLeft:"5px"}}>My content</Typography>
          <Typography style={{...styles.flexBetween,color:"#652786",cursor:"pointer"}}
            id="navigate-personal-tst-btn" onClick={this.gotoMyContentPage}>View More <ChevronRightIcon />
            </Typography>
          </Paper>
          <Box className="cutomCarouselBox">
        <CustomCarousel data-test-id="contentDataCallId" children={this.renderContentData} datavalue={this.state.myContentData} />
        </Box>
    </Box>
    )
  }

  renderNewlyAdded = () => {
    return (
      <Box style={{gap:"16px",margin:"10px 0px 10px 20px"}}>
      <Paper className="paper" style={{ display: "flex",justifyContent:"space-between",alignItems:"center", }}>
        <Typography style={{...styles.typography_personal,marginLeft:"5px"}}>Newly Added</Typography>
          <Typography style={{...styles.flexBetween,color:"#652786",cursor:"pointer"}}
            data-test-id="navigate-personal-tst-btn" onClick={() => { return this.props.history.push('/course-newlyAdded', { data: {} }) }}>View More <ChevronRightIcon />
            </Typography>
          </Paper>
          <Box className="cutomCarouselBox">
        <CustomCarousel data-test-id="newlyAddedCallId" children={this.renderNewlyAddedData} datavalue={this.state.newlyAddedData} getTransformValue={this.getTransformValue}/>
        </Box>
    </Box>
    )
  }

  renderOffers = (itemsToShow:number) => {
    return (
      this.state.libraryData.map((slide:LibraryDataType, index:number) => (
        <div
          className="carousel-item"
          key={index}
          onClick={()=> this.handleNavigateTOCourse(slide)}
          title={slide.attributes.title.length>20 ? slide.attributes.title : ""}
        >
          <img src={slide.attributes.banner_image.url} alt={`Slide ${index}`} />
          <div className="carousel-text">
              <h2 style={{ fontSize: '24px', fontWeight: 600 }}>
            {
              slide.attributes.title.length>20 ? (
                `${slide.attributes.title.slice(0,20)}...`
              ) :(
                slide.attributes.title
              )
            }
              </h2>
            <p style={{ fontSize: '16px', fontWeight: 600, width:"100%"}}>
              {slide.attributes.description}
            </p>
          </div>
        </div>
      ))
    )
  }
  // Customizable Area End

  render() {
    return (
      <Grid container spacing={4} style={{ paddingTop: "25px" }}>
{(this.state.myContentData.length > 0 || this.state.newlyAddedData.length>0 || this.state.libraryData.length > 0 )? (
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '0px', }}>
          <Box style={{ paddingLeft: '15px' }}>
            <Typography style={styles.mainhadding} variant="h3" className="profile_header">Library</Typography>
          </Box>
          <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box>
          {this.state.libraryData.length > 0 && (<><Box data-test-id="offerBox" style={{
              background: "linear-gradient(262.33deg, #FFFFFF 0%, #F0F3F6 100%)",
              padding:"24px",
              borderRadius:'16px',
              margin:"10px 0 10px 10px"
            }}>
      <Typography style={styles.offerTypography}>Latest offers for you</Typography>
        <CustomCarousel data-test-id="sliderComponentId" children={this.renderOffers} datavalue={this.state.libraryData}/>
        </Box></>)}
                     {this.state.myContentData.length > 0 && this.renderContent()}
                     {this.state.newlyAddedData.length> 0 && this.renderNewlyAdded()}
          </Box>
        </Grid>
        ):(
<Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '0px', gap:"56px" }}>
<Typography style={{fontSize:"48px",fontWeight:700}} variant="h3">Library</Typography>
  <Box style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
    <img width={"100%"} style={{maxWidth:"673px"}} src={noContentImg} alt='Image Loading...'/>
    <Typography style={{
      fontWeight: 600,
      fontSize: "22px",
      textAlign:"center",
    }}>
      Content will be assigned to you soon.</Typography>
  </Box>
  </Grid>
        )}
      </Grid>
    )

  }
}

const ButtonWrapper = styled(Box)({
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
})

const styles = {
  mainhadding: {
    fontWeight:700
  },
  mainContainer: {
    display: 'flex'
  },
  BoxContainer: {
    width: '130px',
    height: '45px',
    marginTop: '3px'
  },
  filterButton: {
    color: '#6C328B',
    fontWeight: 700,
    height: '45px',
    width: '100%',
    border: '1.5px solid #6C328B',
    borderRadius: '10px',
  },
  flexBetween:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  IconBox: {
    width: '100%',
    marginTop: '3px',
    paddingLeft: '15px'
  },
  filterIcon: {
    width: '40px',
    height: '46px',
    borderRadius: '10px',
    border: '1.5px solid #6C328B',
    cursor: 'pointer'
  },
  mainOfferPaper: {
    flex: 1,
    marginTop: '25px',
    padding: '30px',
    borderRadius: '24px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    overflow: 'hidden',
    maxWidth: '90vw',
    height: '384px',
    margin: '25px auto auto auto'
  },
  offerTypography: {
    fontWeight: 600,
    marginBottom: '20px',
    fontSize: '24px'
  },
  BarDropBox: {
    display: 'inline-grid',
    marginTop: '8px',
    marginLeft: '25px',
    justifyContent: 'space-around'
  },
  BarDropTypography: {
    fontWeight: 700,
    fontSize: '17px',
    // float: 'right',
    marginTop: '-30px',
    cursor: 'pointer'
  },
  formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  chipTypography:{
    height:"28px",
    width: "117px",
    borderRadius: "16px",
    padding: "3px 0px",
    fontWeight:400,
    color:"white",
    background: "#E70E6B",
    fontSize:"14px",
  },
  FormControlSlect: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  barCart2MainBox: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  barCart2Typography: {
    fontWeight: 700,
    fontSize: '17px',
    // float: 'right',
    marginTop: '0px',
    cursor: 'pointer'
  },
  barCart2formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  select: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  paper_personal: {
    flex: 1,
    overflow: 'hidden',
    height: '490px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  },
  typography_personal: {
    fontWeight: 700,
    fontSize: '24px'
  },
  typography_view_personal: {
    fontWeight: 700,
    fontSize: '17px',
    float: 'right' as 'right',
    marginTop: '-36px',
    cursor: 'pointer',
    color: 'rgba(97, 97, 97, 1)'
  },
  img_shape: {
    paddingLeft: '13px'
  },
  paper_corporate: {
    overflow: 'hidden',
    flex: 1,
    height: '500px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  },
  paper_xpand: {
    overflow: 'hidden',
    flex: 1,
    height: '500px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  }
}

const useStyles = makeStyles((theme: any) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export const Search = ({ _onFocus }: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.search}>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <TextField onFocus={_onFocus} className="Rounded" inputProps={{ maxlength: 50 }} style={{ width: '100%', borderRadius: '12px', marginTop: '3px' }} placeholder=" Search" variant="outlined" />

    </Box>
  )
}
export const LineChart2 = ({ state,isWeekSelected,maxValue,stepValue }: any) => {
  const data = {
    responsive: true,
    labels: state[0]?.map((data: any) => data.track_date),
    datasets: [
        {
            label: isWeekSelected ? 'This week' : "This month",
            data: state[0]?.map((data: any) => data.count),
            borderColor: 'rgba(231, 14, 107, 1)',
            backgroundColor: 'rgba(231, 14, 107, 0.2)',
            fill: true,
            tension: 0.4,
            borderWidth: 5,
        },
        {
            label: isWeekSelected ? 'Last week' : "Last month",
            data: state[1]?.map((data: any) => data.count),
            borderColor: 'rgba(173, 170, 170, 1)',
            backgroundColor: 'rgba(173, 170, 170, 0.2)',
            fill: true,
            tension: 0.4,
            borderWidth: 5,
        },
    ]
  }

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltipItem: any) => `Lesson: ${tooltipItem.parsed.y}`,
          labelColor: function(context:any) {
            return {
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: 2, 
              backgroundColor: context.dataset.borderColor,
            };
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          borderDash: [stepValue],
          color: "rgba(0, 0, 0, 0.5)",
        lineWidth: 1,
        },
        ticks: {
          font: {
              size: 14,
              weight: 400
          }
      }
      },
      y: {
        min: 0,
        max: maxValue + 3,
        ticks: {
          stepSize: stepValue,
          callback: (value: any) => value,
          font: {
              size: 14,
              weight: 400
          }
        },
        grid: {
        display: false,
        },
      },
    },
  };

  return (
    <Box style={{marginTop: '20px' }}>
      <Line data-test-id="lineChartTwo" className="lineChart2Css" data={data} options={options}></Line>
    </Box>
  )
}

// Customizable Area Start
// Customizable Area End
