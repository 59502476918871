import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from '@material-ui/icons/Close';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Typography, Box, Tooltip, IconButton, Input, FormControl, FormHelperText, styled, Modal, Button, ClickAwayListener } from "@material-ui/core";
import "./index.css";
import { exitCircle, newUpArrow, newDownArrow, dummy_img, optionIcon, editNew, preview, archived, duplicate, trash, editIcon, correctlyCheck, circleCheck } from "./asset";

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    width: 'auto',
    border: '15px',
    padding: '20px',
    height: 'auto',
    borderRadius: "14px"
  },
  arrow: {
    color: '#FFF',
    '&::before': {
      border: '1px solid #fff',
      boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
    },
  },
}))(Tooltip);

const LightTooltipContentRoles = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    width: 'auto',
    border: '15px',
    padding: '8px',
    height: 'auto',
    borderRadius: "14px"
  },
  arrow: {
    color: '#FFF',
    '&::before': {
      border: '1px solid #fff',
      boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    },
  },
}))(Tooltip);

const LightTooltipUserEnrolled = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    width: "558px",
    maxWidth: "558px",
    border: '15px',
    padding: '18px 9px 0px 5px',
    height: 'auto',
    maxHeight: "483px",
    overflowY: "auto",
    borderRadius: "14px"
  },
  arrow: {
    color: '#FFF',
    '&::before': {
      border: '1px solid #fff',
      boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    },
  },
}))(Tooltip);


const LightNewTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    width: '145px',
    border: '15px',
    padding: '10px',
    height: 'auto',
    marginRight: "20px !important",
    borderRadius: "14px"
  }

}))(Tooltip);

const NewToolTipMain = styled("div")({
  width: "100%",

})
const HiddenId = styled("p")({
  display:"none",

})
const NewToolTipTextComponent = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: 400,
  paddingLeft: "10px",
  color: "#484646",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontStyle: "normal",
  lineHeight: "24px",
  cursor: "pointer"

})

const NewToolTipInner = styled("div")({
  display: "flex",
  width: "100%",
})

export const HrComponent = styled("hr")({
  border: "1px solid #652786",
  margin: "6px 0",
  width: "100px"
})

const HrComponentNew = styled("hr")({
  border: "0.01px solid #E5E2E1",
  width: "100%",
  height: "1px"
})

export const RenameComponent = styled(Typography)({
  fontSize: "14px !important",
  color: "#000",
  marginBottom: "10px !important",
  fontWeight: 400
})


export const EditComponent = styled("div")({
  borderRadius: "8px !important",
  backgroundColor: "#fff",
  height: '12px',
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
})

export const EditInnerComponent = styled(Typography)({
  fontSize: "18px !important",
  color: "#6C328B",
  fontWeight: 400
})

export const CourseIdComponent = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center", "& .MuiInputBase-input": {
    padding: "6px 8px 7px"
  }
})

export const IconButtonComponent = styled(IconButton)({
  fontSize: "15px",
  color: "#6C328B"
})

export const ImageComponent = styled("img")({
  marginLeft: "10px",
  cursor: "pointer"
})

const ContentRoleMain = styled(Box)({
  display: "flex",
  width: "100%",
  height: "20px",
  borderRadius: "40px"
})

const ContentRoleInner = styled(Typography)({
  color: "#000",
  fontSize: "15px",
  display: "inline"
})

const UserEnrollMain = styled(Box)({
  margin: "0 20px",
  height: "270px",
})

const UserEnrollInner = styled(Typography)({
  color: "#000",
  fontSize: "11px",
  paddingRight: "5px"
})

const UserEnrollInnerHeading = styled(Typography)({
  color: "#000",
  fontWeight: 500,
  fontSize: '17px'
  ,
})

const CustomTableRow = styled(TableRow)({
  borderRadius: '12px', // Add border radius
  marginBottom: '8px', // Add gap between rows
});

interface Data {
  errorMessageShow: string;
  editModeBtn: boolean;
  idEvent: string;
  expand_type: string;
  id: number;
  course_id: string;
  total_chapter: number;
  user_enrol: number;
  name: string;
  xpert_name: string;
  content_roles: string[];
  last_day_modified: number;
  summary: string;
  status: string;
  url: string;
  categories: any;
  average_rating: number;
  description: string;
  is_sellable:boolean;
}

function createData(
  errorMessageShow: string,
  editModeBtn: boolean,
  idEvent: string,
  expand_type: string,
  id: number,
  name: string,
  course_id: string,
  xpert_name: string,
  content_roles: string[],
  total_chapter: number,
  user_enrol: number,
  last_day_modified: number,
  summary: string,
  status: string,
  url: string,
  categories: any,
  average_rating: number,
  description: string,
  is_sellable:boolean = false,
): Data {
  return {
    errorMessageShow,
    editModeBtn,
    idEvent,
    expand_type,
    id,
    name,
    course_id,
    xpert_name,
    content_roles,
    total_chapter,
    user_enrol,
    last_day_modified,
    summary,
    status,
    url,
    categories,
    average_rating,
    description,
    is_sellable: is_sellable ?? false
  };
}
//@ts-ignore
let rows: any[] = [];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string},
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

 const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Course" },
  { id: "course_id", numeric: true, disablePadding: false, label: "ID" },
  {
    id: "xpert_name",
    numeric: true,
    disablePadding: false,
    label: "Xpert Name",
  },
  {
    id: "content_roles",
    numeric: true,
    disablePadding: false,
    label: "Content Role(s)",
  },
  {
    id: "total_chapter",
    numeric: true,
    disablePadding: false,
    label: "Lessons",
  },
  {
    id: "user_enrol",
    numeric: true,
    disablePadding: false,
    label: "Users Enrolled",
  },
  {
    id: "last_day_modified",
    numeric: true,
    disablePadding: false,
    label: "Last Day Modified"
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
];

 const headCellsProgram: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Program" },
  { id: "course_id", numeric: true, disablePadding: false, label: "ID" },
  {
    id: "xpert_name",
    numeric: true,
    disablePadding: false,
    label: "Xpert Name",
  },
  {
    id: "content_roles",
    numeric: true,
    disablePadding: false,
    label: "Content Role(s)",
  },
  {
    id: "total_chapter",
    numeric: true,
    disablePadding: false,
    label: "Lessons",
  },
  {
    id: "user_enrol",
    numeric: true,
    disablePadding: false,
    label: "Users Enrolled",
  },
  {
    id: "last_day_modified",
    numeric: true,
    disablePadding: false,
    label: "Last Day Modified"
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
    sortType: string
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data, sortType: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    console.log(property, sortType, 'kkkkkkkkkkk');
    onRequestSort(event, property, sortType);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox" /> */}
        {(rows[0].expand_type === "Course" ? headCells : headCellsProgram).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ verticalAlign: "center" }}
            colSpan={headCell.label === "Course" || headCell.label === "Program" ? 2 : 1}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <span style={{ marginLeft: "17px", fontWeight: 600, fontStyle: "normal", lineHeight: "24px", color: "#000", fontFamily: "Poppins, sans-serif" }}>{headCell.label}</span>
              {(headCell.id === "status" || headCell.id === "total_chapter" || headCell.id === "user_enrol") ? (
                <>
                </>
              ) : (
                <>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '4px'
                  }}>
                    <img
                      src={newUpArrow}
                      alt="Up Arrow"
                      width="10px"
                      style={{ paddingBottom: '5px', cursor: 'pointer' }}
                      onClick={createSortHandler(headCell.id, "asc")}
                    />

                    <img
                      src={newDownArrow}
                      alt="Down Arrow"
                      width="10px"
                      style={{ cursor: 'pointer' }}
                      onClick={createSortHandler(headCell.id, "desc")}
                    />
                  </div>
                </>

              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      borderCollapse: "separate",
      borderSpacing: "0 15px",
      "& .MuiTable-root": {
        borderCollapse: "separate",
        borderSpacing: "0 15px"
      },
      "& .MuiTableRow-root": {
        boxShadow: 'unset !important',
        borderRadius: "12px !important",
        borderSpacing: "0 15px !important"
      },
      "& .MuiTableBody-root":{
        "& .MuiTableRow-root": {
         boxShadow: "1px 2px 8px 1px rgba(0,0,0,.05)!important"
      
        },
      }
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    statusNew: {
      display: 'flex',
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#EEE8F2",
      color: "#652786"
    },
    statusDraft: {
      display: 'flex',
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#FFEDE4",
      color: "#E47004",

    },
    statusArchived: {
      display: 'flex',
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#FFD9DF",
      color: "#E70E6B",
    },

    errorInput: {
      borderColor: 'red',
      backgroundColor: "#FFF5F5"
    },
    errorText: {
      color: 'red',
      marginLeft: " 5px",
      fontSize: "10px"
    },
    successText: {
      color: '#9A5E89',
      marginLeft: " 5px"
    },
    automaticText: {
      borderColor: "#652786",
      marginLeft: " 5px"
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: "30px",
      borderBottom: "1px solid lightgray",
    },
    alignRight: {
      "&:last-child": {
        textAlign: "right"
      }
    },
    paper2: {
      padding: "8px 0px",
      // textAlign: "center",
      whiteSpace: "nowrap",
    },
    divider: {
      margin: theme.spacing(2, 0),
    },

  })
);


export default function EnhancedTable(props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("course_id");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [data, sortData] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectAllChecked, setSelectAllChecked] = React.useState<boolean>(false);
  const learnMoreButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [isTooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [inputError, setInputError] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [automaticText, setAutomaticText] = React.useState('');
  const [courseId, setCourseId] = React.useState<string | number>("");
  const [isEditMode, setIsEditMode] = React.useState(true);
  const [alreadyExist, setAlreadyExist] = React.useState(false);
  const [eyeIcon, setEyeIcon] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState<string | number>("");
  const [deleteType, setDeleteType] = React.useState<string | number>("");
  const [deleteTitle, setDeleteTitle] = React.useState<string | number>("");
  const [modalTitle, setModalTitle] = React.useState<string | number>("");
  const [openArchiveModal, setOpenArchiveModal] = React.useState(false);
  const [archiveId, setArchiveId]= React.useState<string | number>("");
  const [archiveType, setArchiveType]= React.useState<string | number>("");
  const [archiveTitle, setArchiveTitle]= React.useState<string>('');
  const [isContentArchived, setIsContentArchived]= React.useState<boolean>(false);
  const [isSellable, setIsSellable] = React.useState<boolean>(false);

  rows = [];
  rows = props.data.map((item: any) =>
    createData(
      item.errorMessageShow,
      item.editModeBtn,
      item.idEvent,
      item.attributes.expand_type,
      item.attributes.id,
      item.attributes.title,
      item.attributes.number,
      item.attributes.expert_name,
      item.attributes.content_roles,
      item.attributes.total_chapters,
      item.attributes.user_enrolled,
      item.attributes.updated_at, "",
      item.attributes.status,
      item.attributes.thumbnail_image == null
        ? ""
        : item.attributes.thumbnail_image.url,
      item.attributes.categories,
      item.attributes.average_rating,
      item.attributes.description,
      item.attributes?.is_sellable
    )
  );

  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof Data,
  //   sortType: string
  // ) => {

  //   const isAsc:any = orderBy === property && order === sortType;
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  //   console.log(orderBy,'kkkkkkkkkkk',order,sortType);
  // };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
    sortType: string
  ) => {
    const columnValues = rows.map(row => row[property]);
    const isAllValuesSame = columnValues.every((value, index, array) => value === array[0]);
    if (!isAllValuesSame) {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };



  const handleSelectAllClick = (event: any) => {


    if (event.target.checked) {
      //@ts-ignore
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleTooltipClick = () => {
    if (learnMoreButtonRef.current) {
      learnMoreButtonRef.current.click();
    }
  };


  const handleDelete = (id: string | number, expand_type: string | number, title:string | number) => {
    props.deleteItem(id, expand_type)
    setOpen(false);
    setModalTitle(title)
  }

  const handleDeleteAndCloseModal = (id: string | number, expand_type: string | number, title:string | number) => {
    props.deleteItem(deleteId, deleteType)
    setOpen(false);
    setModalTitle(deleteTitle)
  };

  const handleModalState = (id: string | number, expand_type: string | number, title:string | number) => {
    setDeleteId(id);
    setDeleteTitle(title);
    setDeleteType(expand_type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleArchiveModalState = (id: string | number, expand_type: string | number, title: string | number, isArchive: boolean, is_sellable: boolean = false) => {
    setIsSellable(is_sellable)
    setArchiveId(id);
    setArchiveType(expand_type);
    setArchiveTitle(`${title}`);
    setOpenArchiveModal(true);
    setIsContentArchived(isArchive);
  }

  const handleArchived = (id: string | number, expand_type: string | number, title: string) => {
    if (isContentArchived) {
      props.unArchivedItem(id, expand_type, archiveTitle)
    } else {
      props.archivedItem(id, expand_type, archiveTitle)
    }
    setEyeIcon(true)
    setOpenArchiveModal(false)
  }


  const handleMouseLeave = () => {
    setEyeIcon(false)
  }

  const handleInputChange = (event: { target: { value: React.SetStateAction<string | number>; }; }) => {
    setCourseId(event.target.value);
  };

  const handleEdit = () => {
    setInputError('');
    setIsEditMode(true);
  };

  const handleMouseEnter = (id: string | number, expand_type: string | number) => {
    props.getUserEnrolled(id, expand_type)
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
  };

  const handleSelectAllClick1 = () => {
    if (selectAllChecked) {
      // If selectAllChecked is true, clear all selected items
      setSelected([]);
    } else {
      // If selectAllChecked is false, select all items
      setSelected(rows.map((item) => item.name));
    }
  }

  useEffect(() => {
    // Update the selectAllChecked state based on selected items
    setSelectAllChecked(selected.length === rows.length);
  }, [selected, rows]);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    expand_type: string,
    id: number
  ) => {
    props.navigateTo(id, expand_type);
  };

  const handle1Click = (event: any, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeSave = (id: string | number, expand_type: string | number) => {
    props.handleSave(courseId, id, expand_type)
  };

  const getArchiveModalLabel = (archiveType: string, is_sellable:boolean) => {
    if(is_sellable){
      return `The ${archiveType.toLowerCase()} became sellable in archived state. After unarchiving users might now have to pay to continue learning.`
    }
    return `The ${archiveType.toLowerCase()} will be in draft mode.`
  }


  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleCloseToolTip = () => {
    props?.onCloseRenameCourse()
  }


  const timestamps = props.data.map((item: any) => item.attributes.updated_at);
  let date: any;
  let time: any;
  timestamps.forEach((timestamp: any) => {
    const parts = timestamp.split("T");
    date = parts[0];
    time = parts[1].split(".")[0];
  });
  const rowCount = Math.ceil(props.enrolledUser.length / 4);
  const colCount = 4;

  return (
    <MainContainer
      className={classes.root}
      style={{ marginTop: "2%" }}
      id="course-program-table"
    >
      <ModalMainBody
        open={open}
        onClose={handleClose}>
        <ModalContent>
          <IconAlign onClick={handleClose}><CloseIcon /></IconAlign>
          <ModatText>{`Are you sure you want to delete this ${deleteType}?`}</ModatText>
          <ModatText1>{`Users will no longer be able to follow this ${deleteType}`}</ModatText1>
          <ButtonBox>
          <ButtonGroup>
            <ButtonShape1 onClick={handleClose}>Cancel</ButtonShape1>
            <ButtonShape2 onClick={() =>handleDeleteAndCloseModal(deleteId, deleteType, deleteTitle)} style={{color:"white", background:"#652786"}}>Delete</ButtonShape2>
          </ButtonGroup>
          </ButtonBox>
        </ModalContent>
      </ModalMainBody>

      <ModalMainBody
        open={openArchiveModal}
        onClose={() => setOpenArchiveModal(false)}>
        <ModalContent>
          <IconAlign onClick={() => setOpenArchiveModal(false)}><CloseIcon /></IconAlign>
          <ModatText>
            {isContentArchived ? `Are you sure you want to unarchive this ${archiveType}?` :`Are you sure you want to archive this ${archiveType}?`}
          </ModatText>
          <ModatText1>
            {isContentArchived ? getArchiveModalLabel(archiveType.toString(),isSellable) : `Users will no longer have access to this.`}
          </ModatText1>
          <ButtonBox>
            <ButtonGroup>
              <ButtonShape1 onClick={() => setOpenArchiveModal(false)}>Cancel</ButtonShape1>
              <ButtonShape2
                onClick={() => {
                  handleArchived(archiveId, archiveType, archiveTitle)
                }}
                style={{ color: "white", background: "#652786" }}>
                {isContentArchived ? 'Confirm' : 'Archive'}
              </ButtonShape2>
            </ButtonGroup>
          </ButtonBox>
        </ModalContent>
      </ModalMainBody>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.length === 0 ? (
              <></>
            ) : stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                //@ts-ignore
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                const moment = require('moment');
                const updatedAtString = row.last_day_modified;
                const updatedAtMoment = moment(updatedAtString);
                const formattedUpdatedAt = updatedAtMoment.format('MM-DD-YYYY');
                return (
                  <CustomTableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      style={{
                        border: "none",
                        padding: '20px'
                      }}
                      padding="checkbox">
                      <img
                        src={row.url === "" ? dummy_img : row.url}
                        style={{
                          borderRadius: '4px',
                          border: ' 1px solid #929090',
                          background: ' lightgray 50% / cover no-repeat',
                          boxShadow: '0px 1px 3px 0px rgba(140, 140, 140, 0.72)',
                          height: "40px",
                          width: "40px",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        cursor:"pointer"
                      }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      onClick={()=>{props.handlePreview(row.id)}}
                    >
                      {row.name}
                    </TableCell>

                    <TableCell
                      style={{
                        border: "none",
                        fontSize:"16px"
                      }}
                      align="right">

                      <LightTooltip
                        enterDelay={200}
                        leaveDelay={100}
                        enterTouchDelay={0}
                      leaveTouchDelay={100000}
                        interactive={true}
                        title={
                            <>
                              <EditComponent>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100px", alignItems: "center" }}>
                                  <EditInnerComponent>
                                    Edit ID
                                  </EditInnerComponent>
                                  <ImageComponent src={editIcon} onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                                    props.handleEditClick1(row.id, 'editModeBtn');
                                  }} />
                                </div>
                                <HrComponent />
                              </EditComponent>
                            </>
                          // )
                        }
                        placement="top"
                        arrow
                      >
                        <span style={{ cursor: "pointer" }}>{row.course_id}</span>
                      </LightTooltip>
                      {row.editModeBtn && props?.activeId === row.id && (
                                              <ClickAwayListener onClickAway={handleCloseToolTip}>
                                              <div>
                                              <LightTooltip
                                              enterDelay={200}
                                              leaveDelay={100}
                                              interactive={true}
                                              open={props.isEditCourseIdOpen}
                                              title={
                                                  <>
                                                    <FormControl
                                                      error={Boolean(inputError)}
                                                    >
                                                      <RenameComponent>
                                                        Rename Course ID
                                                      </RenameComponent>
                                                      <CourseIdComponent>
                                                                                          <Input
                                                          autoFocus
                                                          fullWidth
                                                          value={row.idEvent}
                                                          onChange={(event) => props.handleInputChange(row.id, "idEvent", event)}
                                                          disabled={row.errorMessageShow === "Id correctly edited."}
                                                          className={(row.errorMessageShow === "Course ID has already exist. Please try a different one." || row.errorMessageShow === "Program ID has already exist. Please try a different one." || row.errorMessageShow === "Course ID must start with 'CR' followed by minimum 1 numeric number" || row.errorMessageShow === "Program ID must start with 'PR' followed by minimum 1 numeric number")
                                                            ? classes.errorInput : row.errorMessageShow === "Automatic ID Assigned" ? classes.automaticText : ""}
                                                        />
                                                        <>
                                                          {!(row.errorMessageShow === "Id correctly edited.") ? (
                                                            <IconButtonComponent onClick={() => handleChangeSave(row.id, row.expand_type)}>
                                                              Save
                                                            </IconButtonComponent>
                                                          ) : (
                                                            <ImageComponent src={editIcon} onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                                                              props.handleEditClick(row.id, 'errorMessageShow');
                                                            }} />
                                                          )}
                                                        </>
                                                      </CourseIdComponent>
                                                      {
                                                        (row.errorMessageShow === "Course ID has already exist. Please try a different one." || row.errorMessageShow === "Program ID has already exist. Please try a different one." || row.errorMessageShow === "Course ID must start with 'CR' followed by minimum 1 numeric number" || row.errorMessageShow === "Program ID must start with 'PR' followed by minimum 1 numeric number") && (
                                                          
                                                          <>
                                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                              <img style={{ width: "18px", height: "18px" }} src={exitCircle} />
                                                              <FormHelperText className={classes.errorText}>
                                                                {row.errorMessageShow}
                                                              </FormHelperText>
                                                            </div>
                                                          </>
                                                        )
                                                      }
                                                      {row.errorMessageShow === "Id correctly edited." && (
                                                        <>
                                                          <div style={{ display: "flex" }}>
                                                            <img src={correctlyCheck} />
                                                            <FormHelperText className={classes.successText}>
                                                              {row.errorMessageShow}
                                                            </FormHelperText>
                                                          </div>
                                                        </>
                                                      )}
                                                      {row.errorMessageShow === "Automatic ID Assigned" && (
                                                        <>
                                                          <div style={{ display: "flex" }}>
                                                            <img src={circleCheck} />
                                                            <FormHelperText className={classes.automaticText}>
                                                              {row.errorMessageShow}
                                                           </FormHelperText>
                                                          </div>
                                                        </>
                                                      )}
                                                    </FormControl>
                                                  </>
                                              }
                                              placement="top"
                                              arrow
                                            >
                                              <span>
                                              <HiddenId>{row.course_id}</HiddenId>
                                                </span>
                                            </LightTooltip>
                                            </div>
                                            </ClickAwayListener>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}
                      align="right">{row.xpert_name}</TableCell>
                    <LightTooltipContentRoles enterDelay={500} leaveDelay={100} interactive={true}
                      title={
                        <>
                          <ContentRoleMain>
                            <ContentRoleInner>
                              {Array.isArray(row.content_roles) ? row.content_roles.join(", ") : row.content_roles}
                            </ContentRoleInner>
                          </ContentRoleMain>
                        </>
                      }
                      placement="top" arrow>
                      <TableCell
                        style={{
                          border: "none",
                          cursor: "pointer",
                          fontSize:"16px"
                        }}
                        align="right">
                        {Array.isArray(row.content_roles) ? row.content_roles.join(",").slice(0, 20) + "..." : row.content_roles}
                      </TableCell>
                    </LightTooltipContentRoles>
                    <TableCell
                      style={{
                        border: "none",
                      }}
                      align="right">{row.total_chapter}</TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}
                      align="right">
                      <LightTooltipUserEnrolled enterDelay={500} leaveDelay={100} interactive={true}
                        title={
                          <UserEnrollMain>
                            <UserEnrollInnerHeading>
                              User Enrolled
                            </UserEnrollInnerHeading>
                            {

                              Array(rowCount).fill("_").map((row, i) => {
                                const start_index = i * colCount;
                                const end_index = start_index + colCount
                                return (
                                  <div className={classes.container}>
                                    <>
                                      {props.enrolledUser?.slice(start_index, end_index).map((item: { attributes: { full_name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; }, index: number) => {
                                        return (
                                          <div style={{ gridColumnEnd: 'span 3' }} className={props.enrolledUser?.slice(start_index, end_index).length === 4 ? classes.alignRight : ""}>
                                            <UserEnrollInner className={classes.paper2} key={index}>{item.attributes.full_name}</UserEnrollInner>
                                          </div>
                                        )
                                      })}
                                    </>
                                  </div>
                                )
                              })
                            }

                          </UserEnrollMain>
                        }
                        placement="left" arrow
                      >
                        <span style={{ cursor: "pointer" }} onMouseEnter={() => handleMouseEnter(row.id, row.expand_type)} >{row.user_enrol}</span>
                      </LightTooltipUserEnrolled>
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                      }}
                      align="right">{formattedUpdatedAt}</TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        textTransform: "capitalize"
                      }}
                      align="right">
                      <div className={row.status === "active"
                        ? classes.statusNew
                        : row.status === "draft"
                          ? classes.statusDraft
                          : row.status === "archived"
                            ? classes.statusArchived
                            : ""}>
                        {row.status}
                      </div>
                    </TableCell>
                    <LightNewTooltip
                      enterDelay={500}
                      leaveDelay={100}
                      enterTouchDelay={0}
                      leaveTouchDelay={10000}
                      interactive={true}
                      title={
                        <NewToolTipMain>
                          <NewToolTipInner style={{ cursor: "pointer" }} onClick={()=>{props.handlePreview(row.id)}}>
                            <img style={{ cursor: "pointer" }} src={preview} />
                            <NewToolTipTextComponent>Preview</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner style={{ cursor: "pointer" }} onClick={() => props.gotoEditCourse(row.id)}>
                            <img src={editNew} />
                            <NewToolTipTextComponent>Edit</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner style={{ cursor: "pointer" }} 
                            onClick={() =>{
                               props.handleDuplicate(row.id);  
                               setOpen(false)
                            }}>
                            <img src={archived} />
                            <NewToolTipTextComponent>Duplicate</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          
                          <NewToolTipInner
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              console.log("row.is_sellable ==>",row.is_sellable);
                              handleArchiveModalState(
                                row.id, 
                                row.expand_type, 
                                row.name,
                                row.status === "archived",
                                row.is_sellable ? true : false
                              );
                            }}
                          >
                            <img src={duplicate} />
                            <NewToolTipTextComponent>{row.status === "archived" ? "Unarchive" : "Archive"}</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner>
                            <img style={{ cursor: "pointer" }} src={trash} onClick={() => handleModalState(row.id, row.expand_type, row.name)} />
                            <NewToolTipTextComponent onClick={() => handleModalState(row.id, row.expand_type, row.name)}>Delete</NewToolTipTextComponent>
                          </NewToolTipInner>
                        </NewToolTipMain>
                      }
                      placement="bottom"
                    >
                      <TableCell
                        style={{
                          border: "none",
                        }}
                        align="right">
                        <img style={{ cursor: "pointer" }} src={optionIcon} />
                      </TableCell>
                    </LightNewTooltip>
                  </CustomTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainContainer>
  );
}

const MainContainer = styled("div")({
  position: "relative",
});

const ModalMainBody = styled(Modal)({
  display:"flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth:"400px",
  },
  "@media (max-width: 400px)": {
    maxWidth:"330px",
  }
});

const ModatText = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:500,
  fontSize:"22px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const ModatText1 = styled(Typography)({
  width:"100%",
  display:"flex",
  fontStyle:"Poppins",
  fontWeight:400,
  fontSize:"16px",
  justifyContent:"left",
  padding: "5px 20px 5px 20px"
});

const IconAlign = styled(Box)({
  position: "absolute",
  top: "-10",
  right: "-10",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white"
});

const ButtonGroup = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent:"space-between",
  gap: "23px",
  padding: "0px 2px 0px 2px"
});

const ButtonShape1 = styled(Button)({
  width: "48%",
  height: "42px",
  fontWeight:600,
  fontSize:"16px",
  background: "1px solid #652786 !important",
  border: "1px solid #E47004",
  borderRadius:"12px",
  color: "#E47004",
  textTransform:"none",
});

const ButtonShape2 = styled(Button)({
  width: "48%",
  height: "42px",
  background: "1px solid ##652786 !important",
  borderRadius:"12px",
  fontWeight:600,
  fontSize:"16px",
  color: "#E47004",
  textTransform:"none",
});

const ButtonBox = styled(Box)({
  padding:"10px 10px 0px 10px",
  width:"100%",
  "& .MuiButton-root:hover":{
    backgroundColor: "white",
  }
});
