import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CartItem from "./CartItem.web";

interface ICartItems {
  cartItems: any;
  payableAmount: number;
  orderConfirmed: boolean;
  onRemove: (id: string) => void;
}

export default function CartItems({
  cartItems,
  onRemove,
  payableAmount,
  orderConfirmed,
}: ICartItems) {
  const classes = useStyles();

  let cartItemsContent;
  if (cartItems && cartItems?.length > 0) {
    cartItemsContent = cartItems.map((data: any) => {
      const isCourse =
        data?.attributes?.purchasable_type === "BxBlockCoursecreation::Course"
          ? true
          : false;
      const thumbnail = isCourse
        ? data?.attributes?.course_detail?.thumbnail_image?.url
        : data?.attributes?.program_detail?.thumbnail_image?.url;
      const title = isCourse
        ? data?.attributes?.course_detail?.title
        : data?.attributes?.program_detail?.title;
      const author = isCourse
        ? data?.attributes?.course_detail?.created_by
        : data?.attributes?.program_detail?.created_by;
      const price = isCourse
        ? data?.attributes?.course_detail?.price
        : data?.attributes?.program_detail?.price;
      return (
        <CartItem
          key={data.id}
          itemType={isCourse ? "Course" : "Program"}
          thumbnail={thumbnail}
          title={title}
          author={author}
          price={price}
          onRemoveClick={() => onRemove(data.id)}
        />
      );
    });
  } else {
    cartItemsContent = null;
  }

  let info;
  if (orderConfirmed) {
    info = "Order Summary";
  } else if (cartItems.length > 1) {
    info = `${cartItems.length} items in cart`;
  } else {
    info = `${cartItems.length} item in cart`;
  }

  if (payableAmount === 0) {
    return (
      <p className={clsx(classes.emphasisText, classes.emptyMsg)}>
        This cart is empty
      </p>
    )
  }

  return (
    <>
      <p className={clsx(classes.primaryText, classes.info)}>
        {info}
      </p>
      {cartItemsContent}
      <div className={classes.promotionContainer}>
        <p className={clsx(classes.primaryText, classes.totalLabel)}>Total</p>
        <p className={classes.emphasisText}>${payableAmount.toFixed(2)}</p>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      display: "flex",
      height: "35%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    promotionContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    primaryText: {
      color: "#1a1a1a",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "20px",
    },
    info: {
      marginLeft: "20px",
    },
    totalLabel: {
      marginRight: "20px",
    },
    emphasisText: {
      fontWeight: 800,
      fontSize: "28px",
      lineHeight: "28px",
      textAlign: "right",
      color: "#23262F",
    },
    emptyMsg: {
      textAlign: "left",
      marginLeft: 20,
    },
  })
);