import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
const EmptyNote = require("./empty_notes.svg");
const AddIcon = require("./Icon_add.svg");
const EditIcon = require("./icon_edit.svg");
import PreviewNote from "./PreviewNote"



interface NoteType1 {
    id:string;
    type:string;
    attributes:{
      title:string;
      description:string;
      is_private:boolean;
      created_at:string;
      link:string | null;
      note_type:string;
      course_timestamp:string;
      is_read:boolean;
      image:{
          id:number;
          file_name:string;
          url:string;
      }
      is_editable:boolean;
      course:{
        course_id:number;
        chapter_id:number;
        lesson_name:string
      }
    }
    }

interface IProps {
  classes?: any;
  notes:NoteType1[];
  createNote:boolean;
  handleOpenNote:(id:string) => void;
  openNoteModal:boolean;
  noteId:string
  iconType:string
}

class Notes extends React.Component<IProps, {}> {

  render() {
    const {
      classes,
      notes,
      createNote,
      handleOpenNote,
      openNoteModal,
      noteId,
      iconType
    } = this.props;
    return (
        <Box>
            {notes.length > 0 ?
            <Box className={classes.noteMainContainer} style={{height:notes.length > 4 ? '100vh' : 'auto',overflowY: notes.length > 4 ? 'scroll' : 'visible'}}>
                {notes.map(data => 
            <Box className={classes.noteCardBox} onClick={() => handleOpenNote(data.id)}>
                <Box style={{overflow:'hidden'}}>
                <Typography className={classes.noteTitle}>{data.attributes.title}<span className={classes.noteTimeSpan}>{data.attributes.course_timestamp}</span></Typography>
                <Typography className={classes.lessonName}>{data.attributes.course.lesson_name}</Typography>
                <Typography className={classes.noteDescription}>{data.attributes.description}</Typography>
                </Box>
                <img src={EditIcon}/>
            </Box>
           )}
            </Box>
             :
            <Box className={classes.EmptyNoteMainBox}>
            <img src={EmptyNote} alt="empty-note"/>
            <Typography className={classes.DontHaveText}>You don’t have any notes yet.</Typography>
            {createNote &&
            <Button
                startIcon={<img src={AddIcon} alt="add"/>}
                className={classes.addNoteButton}
            >
                Add note
            </Button>
            }
            </Box>
            }
            <PreviewNote notes={notes} openNoteModal={openNoteModal} handleOpenNote={handleOpenNote} noteId={noteId} iconType={iconType}/>
        </Box>
    );
  }
}


const styles = createStyles({
    EmptyNoteMainBox:{
        padding:'10px',
        textAlign:'center',

    },
    DontHaveText:{
        textAlign:'center',
        fontWeight:600,
        color:'#1C1B1B',
        lineHeight:'16px',
        padding:'15px 0'
    },
    addNoteButton:{
        color:"#652786",
        fontSize:'16px',
        fontWeight:600,
        textTransform:'capitalize'
    },
    noteMainContainer:{
        margin:'15px 0 0'
    },
    noteCardBox:{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        boxShadow: '0px 3px 12px 0px #1A1A1A32',
        padding: '10px',
        borderRadius: '12px',
        borderLeft: '9px solid #652786',
        marginBottom:'12px',
        cursor:'pointer'
    },
    noteTitle:{
        color: '#652786',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px'
    },
    noteTimeSpan:{
        paddingLeft: '25px',
        color: '#1C1B1B',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px'
    },
    lessonName:{
        color:' #484646',
        lineHeight: '18px',
        fontSize: '12px',
        padding: '4px 0 10px'
    },
    noteDescription:{
        fontSize: '12px',
        color: '#000',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
  });

export default withStyles(styles)(Notes);
// Customizable Area End