import React, { useEffect } from "react";
import { RadioGroup } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import SubscriptionCartItem from "./SubscriptionCartItem.web";
import { SelectedSubsCartItem } from "../ShoopingController.web";
interface ISubscriptionCartItems {
  cartItems: any;
  selectedSubsCartItem: SelectedSubsCartItem;
  onRemove: (id: string) => void;
  handleSubsCartItemsSelection: (item: SelectedSubsCartItem) => void;
}

export default function SubscriptionCartItems({
  onRemove,
  cartItems,
  selectedSubsCartItem,
  handleSubsCartItemsSelection,
}: ISubscriptionCartItems) {
  const classes = useStyles();

  const handleChange = (e: any) => {
    const selectedItem = cartItems.find(
      (item: any) => item.id === e.target.value
    );
    if (selectedItem) {
      const itemEssentials = {
        id: selectedItem.id,
        price: selectedItem.attributes.price,
        purchasableId: selectedItem.attributes.purchasable_id,
        purchasableType: selectedItem.attributes.purchasable_type,
        subscriptionPeriod:
          selectedItem.attributes.purchasable_type === "BxBlockCoursecreation::Course" ?
            selectedItem.attributes.course_detail.subscription_period :
            selectedItem.attributes.program_detail.subscription_period,
      };
      handleSubsCartItemsSelection(itemEssentials);
    }
  };

  // Select the 1st subs cart item
  useEffect(() => {
    if (cartItems.length > 0) {
      const id = cartItems[0].id;
      const price = cartItems[0].attributes.price;
      const purchasableId = cartItems[0].attributes.purchasable_id;
      const purchasableType = cartItems[0].attributes.purchasable_type;
      const subscriptionPeriod =
        purchasableType === "BxBlockCoursecreation::Course"
          ? cartItems[0].attributes.course_detail.subscription_period
          : cartItems[0].attributes.program_detail.subscription_period;
      handleSubsCartItemsSelection({
        id,
        price,
        purchasableId,
        purchasableType,
        subscriptionPeriod,
      });
    } else {
      handleSubsCartItemsSelection({
        id: "",
        price: "",
        purchasableId: 0,
        purchasableType: "",
        subscriptionPeriod: "",
      });
    }
  }, [cartItems]);

  // Empty message
  if (cartItems.length === 0) {
    return (
      <p className={clsx(classes.emphasisText, classes.emptyMsg)}>
        This cart is empty
      </p>
    );
  }

  return (
    <>
      <div className={classes.infoContainer}>
        <p className={classes.infoText}>Pay one subscription at a time.</p>
      </div>
      <RadioGroup
        aria-label="method"
        name="paymentMethod"
        value={selectedSubsCartItem.id}
        onChange={handleChange}
      >
        {cartItems.map((item: any) => {
          const {
            course_detail,
            program_detail,
            purchasable_type,
          } = item.attributes;
          const isCourse = purchasable_type === "BxBlockCoursecreation::Course";
          const title = isCourse ? course_detail.title : program_detail.title;
          const author = isCourse
            ? course_detail.created_by
            : program_detail.created_by;
          const price = isCourse ? course_detail.price : program_detail.price;
          const period = isCourse
            ? course_detail.subscription_period
            : program_detail.subscription_period;
          return (
            <SubscriptionCartItem
              key={item.id}
              id={item.id}
              selectedItemId={
                selectedSubsCartItem.id
              }
              title={title}
              author={author}
              price={price}
              period={period}
              onRemoveClick={() => onRemove(item.id)}
            />
          );
        })}
      </RadioGroup>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoContainer: {
      background: "#E6E8EC",
      borderRadius: 4,
      margin: "30px 0",
      padding: "10px 20px",
    },
    infoText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#000000",
      margin: 0,
    },
    emphasisText: {
      fontWeight: 800,
      fontSize: "28px",
      lineHeight: "28px",
      textAlign: "right",
      color: "#23262F",
    },
    emptyMsg: {
      textAlign: "left",
      marginLeft: 20,
    },
  })
);
