import React from "react";
// Customizable Area Start
import './videostyles.css';
import './CoursePage.css';
import LessonPageController from "./LessonPageController.web";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, Card, CircularProgress, Dialog, Grid, LinearProgress, Paper, Slider, Tooltip, Typography, makeStyles, styled } from "@material-ui/core";
import { activeLesson, backButton, backwardIcon, collapseIcon, completedGreenIcon, downloadBackgroundImage, expandIcon, finishedIcon, forwardIcon, iconImage, iconMusic, iconPage, iconQuiz, iconVideo, lessonCompleteIcon, lockIcon, nextButton, pauseIcon, playIcon, startIcon, viewShape, volumeIcon,createNoteIcon } from "./assets";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ReactPlayer from 'react-player'
import VolumeOffOutlinedIcon from '@material-ui/icons/VolumeOffOutlined';
import { Document, Page, pdfjs } from 'react-pdf';
import { FaMusic } from "react-icons/fa";
import ReviewCourse from "../../../components/src/Review/ReviewCourse";
const workerSrc = require('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
import Note from '../../../components/src/Notes/Notes'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: "10px",
        border: '1px solid #E5E2E1',
        borderRadius: '8px',
        cursor:"pointer"
    },
    activeRoot: {
        display: 'flex',
        marginBottom: "10px",
        borderRadius: '8px',
        background: "#E0D4E7",
        border: "1px solid #652786",
        boxShadow: "0px 0px 16px 0px #6C328B54",
        cursor:"pointer"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));
// Customizable Area End
let id: any;
export default class LessonPage extends LessonPageController {
    // Customizable Area Start
    renderImageDialog = () => {
        return (
          <Box>
            <Dialog
              open={this.state.openImageDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen
              PaperProps={{
                style: {
                  backgroundColor: "black",
                  boxShadow: "none",  
                },
              }}
            >
              <img
                style={{
                  maxWidth: "100vw",
                  maxHeight: "100vh",
                  objectFit: "contain",
                  display: "block",
                  margin: "auto",
                  backgroundColor: "black",
                }}
                src={this.state.lessonData.data?.attributes.video.url}
                alt="img"
              />
      
              <Box style={{ position: "absolute", top: 10, right: 10 }}>
                <CancelOutlinedIcon
                  data-test-id="imageDialogCross"
                  style={{ color: "white", cursor: "pointer", fontSize: "2rem" }}
                  onClick={() => {
                    if(this.state.lessonData.data){
                        this.state.lessonData.data.attributes.is_completed
                          ? this.toggleImageDialog()
                          : this.completeLesson();
                    }
                  }}
                />
              </Box>
              <img
                className="cursor"
                data-test-id="fullScreenPdfDialog"
                src={this.state.openImageDialog ? collapseIcon : expandIcon}
                onClick={() => {
                    if(this.state.lessonData.data){
                    this.state.lessonData.data.attributes.is_completed
                      ? this.toggleImageDialog()
                      : this.completeLesson();
                    }
                  }}
                style={{
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                  cursor: "pointer",
                  zIndex: 2,
                }}
              />
            </Dialog>
          </Box>
        );
      };
      

    imageComponent = () => {
        return (
                               <Box
                                style={styles.mainImg_box}>
                                <img data-test-id="imageid" src={this.state.lessonData?.data?.attributes.video.url} style={styles.main_courseImg} alt="img" onClick={this.toggleImageDialog}/>
                                <div style={{background:"#1C1B1B",color:"white",position:"absolute",bottom:0,width:"100%",height:"44px",
        display:"flex",justifyContent:"flex-end",alignItems:"center"
      }}>
                                <img className="cursor" src={expandIcon} style={{marginRight:"10px"}} onClick={this.toggleImageDialog}/>
      </div>
                            </Box>
        )
    }

     ValueLabelComponent(props:any) {
        const { children, open, value } = props;
      
        return (
          <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
          </Tooltip>
        );
      }

       customLoader = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      );

    videoComponent = () => {
        return (
            <div
            className={this.state.isFullScreen ? "videoPlayerlargeScreen" : "videoPlayerId"}
            data-test-id="videoPlayerId"
            ref={this.videoContainerRef}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            >
<ReactPlayer
  data-test-id="reactPlayerId"
  ref={this.playerRef}
  url={this.state.lessonData.data.attributes.youtube_id ? `https://www.youtube.com/watch?v=${this.state.lessonData.data.attributes.youtube_id}` : this.state.lessonData.data.attributes.video.url}
  playing={this.state.playing}
  onProgress={this.handleProgress}
  onDuration={this.handleDuration}
  muted={this.state.muted}
  onEnded={this.handleVideoEnd}
  playbackRate={this.state.playbackRate}
  controls={Boolean(this.state.lessonData.data.attributes.youtube_id)}
/>
        {!this.state.lessonData.data.attributes.youtube_id && <div style={{position:"absolute",bottom:0,left:0,backgroundColor:"#1C1B1B",width:"100%",height:"44px",display:"flex",alignItems:"center",
            gap:"8px",padding:"0 8px"
        }}>
          <img className="cursor" data-test-id="backWardId" src={backwardIcon} onClick={this.handleSeekBackward}/>
          <img className="cursor" data-test-id="playPauseId" style={{width:"24px",height:"24px"}} src={this.state.playing ? pauseIcon : startIcon} onClick={this.handleToggleVideo}/>
          <img className="cursor" data-test-id="forwarId" src={forwardIcon} onClick={this.handleSeekForward}/>
          <select
            id="speedSelect"
            data-test-id="speedSelect"
            value={this.state.playbackRate}
            onChange={this.handleSpeedChange}
            style={{
                width: 'auto',
                height: 'auto',
                padding: '2px 8px',
                gap: '10px',
                borderRadius: '88px',
                appearance: 'none',
                backgroundColor: '#787776',
                border: 'none',
                color:"white",
                 textAlign:"center",
                 fontSize:"10px",
                 fontWeight:500
              }}
          >
            {this.state.speedData.map((speed)=><option value={speed}>{`${speed}x`}</option>)}
          </select>
          <Slider
        ValueLabelComponent={this.ValueLabelComponent}
        data-test-id="sliderchangeId"
        defaultValue={0}
        max={this.state.duration || 0}
        value={this.state.played}
        onChange={this.handleSeekChange}
        style={{margin:"0 5px"}}
        />
          <Typography style={{color:"white"}}>{this.formatTime(this.state.played)}</Typography>
          {this.state.muted ? <VolumeOffOutlinedIcon style={{fontSize:"24px",color:"white"}} onClick={this.handleToggleMute}/> : <img className="cursor" data-test-id="toggleMute" src={volumeIcon} onClick={this.handleToggleMute}/>}
          <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreen ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
        </div>}
        {((!this.state.playing || this.state.showPlayButton) && !this.state.lessonData.data.attributes.youtube_id) && <Box style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        gap: "20px",
        color:"white",
      }}>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto",justifyContent:"center" }}>
        <img 
          className="cursor" 
          src={backButton} 
          style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
          onClick={this.previousLesson}
          data-test-id="previousImageIcon"
        />
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Previous</Typography>
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Lesson</Typography>
    </div>

    <img 
      className="cursor" 
      src={this.state.playing ? pauseIcon : playIcon} 
      style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
      onClick={this.handleToggleVideo} 
    />

    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto" }}>
        <img 
          className="cursor" 
          data-test-id="nextImageIcon"
          src={nextButton} 
          style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
          onClick={()=>{this.state.lessonData?.data?.attributes.is_completed && this.continueToNextLesson()}}
        />
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Next</Typography>
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Lesson</Typography>
    </div>
</Box>
}
        </div>
        )
    }

    audioComponent = () => {
        return (
            <div
            style={{
                position:"relative",
                height:"480px",
                background:"black"
            }}
            ref={this.videoContainerRef}
            data-test-id="videoPlayerId"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            >
                <Box style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}>
                {this.state.lessonData.data.attributes.video_thumbnail ? <img src={this.state.lessonData.data.attributes.video_thumbnail} /> :  <FaMusic fontSize="200px" color="white"/>}
                </Box>
                <ReactPlayer
          ref={this.audioPlayerRef}
          data-test-id="audioReactPlayer"
          onDuration={this.handleAudioDuration}
          url={this.state.lessonData.data.attributes.video.url}
          playing={this.state.isAudioPlaying}
          onProgress={this.handleProgressAudio}
          onEnded={this.handleAudioEnd}
          playbackRate={this.state.playbackRate}
          muted={this.state.muted}
          width="100%"
          height="50px"
        />
        <div style={{position:"absolute",bottom:0,left:0,backgroundColor:"#1C1B1B",width:"100%",height:"44px",display:"flex",alignItems:"center",
            gap:"8px",padding:"0 8px"
        }}>
          <img className="cursor" data-test-id="backWardId" src={backwardIcon} onClick={this.handleSeekBackwardAudio}/>
          <img className="cursor" data-test-id="playPauseId" style={{width:"24px",height:"24px"}} src={this.state.isAudioPlaying ? pauseIcon : startIcon} onClick={this.togglePlay}/>
          <img className="cursor" data-test-id="forwarId" src={forwardIcon} onClick={this.handleSeekForwardAudio}/>
          <select
            id="speedSelect"
            data-test-id="speedSelect"
            value={this.state.playbackRate}
            onChange={this.handleSpeedChange}
            style={styles.selectBox}
          >
            {this.state.speedData.map((speed)=><option value={speed}>{`${speed}x`}</option>)}
          </select>
          <Slider
          step={1}
        ValueLabelComponent={this.ValueLabelComponent}
        data-test-id="sliderchangeId"
        max={this.state.audioLessonDuration || 0}
        value={this.state.audioCurrentTime}
        onChange={this.handleSliderChange}
        style={{margin:"0 5px"}}
        />
          <Typography style={{color:"white"}}>{this.formatTime(this.state.audioCurrentTime)}</Typography>
          {this.state.muted ? <VolumeOffOutlinedIcon data-test-id="toggleMute" style={{fontSize:"24px",color:"white"}} onClick={this.handleToggleMute}/> : <img className="cursor" data-test-id="toggleMute" src={volumeIcon} onClick={this.handleToggleMute}/>}
          <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreen ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
        </div>
        {(!this.state.isAudioPlaying || this.state.showPlayButton) && <Box style={{
            top: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "50%",
        justifyContent: "center",
        display: "flex",
        width: "auto",
        alignItems: "center",
        color:"white",
        gap: "20px",
      }}>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto",justifyContent:"center" }}>
        <img 
          className="cursor" 
          src={backButton} style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
          onClick={this.previousLesson} data-test-id="previousImageIcon"
        />
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Previous</Typography>
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Lesson</Typography>
    </div>

    <img 
      className="cursor" 
      src={this.state.isAudioPlaying ? pauseIcon : playIcon} style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
      onClick={this.togglePlay} 
    />

    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto" }}>
        <img 
          className="cursor" data-test-id="nextImageIcon"
          src={nextButton} style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }}  onClick={()=>{this.state.lessonData?.data?.attributes.is_completed && this.continueToNextLesson()}}
        />
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Next</Typography>
        <Typography style={{ margin: "0", padding: "0",fontWeight:600 }}>Lesson</Typography>
    </div>
</Box>
}
        </div>
        )
    }

    renderPdfComponent = () => {
        return (
    <Box className={this.state.isFullScreen ? "pdfDialogBox" : ""}>
            <div
            ref={this.videoContainerRef}
             className="documentConteiner" style={{position:"relative",display:"flex",justifyContent:"center",height:"482px"}}>
                <Box style={{display:"flex",justifyContent:"center",width:"100%"}}>
                {this.state.pdfProgressLoaading < 100 && (
        <Box style={{ width: '50%', margin: "auto",position:"absolute",zIndex:5,top:"45%" }}>
          <LinearProgress variant="determinate" value={this.state.pdfProgressLoaading} />
        </Box>
      )}
                    <Document
                    data-test-id="DocumentComponent"
                      file={this.state.lessonData.data.attributes.video.url}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                      loading={""}
                      onLoadProgress={this.handleLoadProgress}
                    >
         <div style={{ visibility: this.state.pdfProgressLoaading === 100 ? 'visible' : 'hidden' }}>
         <Page pageNumber={this.state.pagNumber} />
          </div>
      </Document>
      </Box>
      <div style={{background:"#1C1B1B",color:"white",position:"absolute",bottom:0,width:"100%",height:"44px",
        display:"flex",justifyContent:"space-between",alignItems:"center"
      }}>
      <Box style={{display:"flex",justifyContent:"flex-end",alignItems:"center",width:"56%",gap:"10px",height:"100%"}}>
        <Typography data-test-id="prevBtnId" className="cursor" style={{paddingBottom:"5px"}} onClick={() => this.state.pagNumber >= 1 && this.setPageNumber(this.state.pagNumber - 1)}>
        ❮
      </Typography>
        <span style={{fontWeight:600,fontSize:"18px"}}>{this.state.pagNumber}</span>/<span style={{fontWeight:400,fontSize:"16px"}}>{this.state.numPages}</span>
        <Typography data-test-id="nextBtnId" className="cursor" style={{paddingBottom:"5px"}} onClick={() => this.state.pagNumber < this.state.numPages && this.setPageNumber(this.state.pagNumber + 1)}>
        ❯
      </Typography>
      </Box>
      <Box style={{marginRight:"10px"}}>
      <img className="cursor" data-test-id="fullScreenPdf" src={this.state.isFullScreen ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
      </Box>
      </div>
      <div style={{color:"black",position:"absolute",top:"40%",width:"100%",height:"44px",
        display:"flex",justifyContent:"space-between",alignItems:"center",gap:"16px"
      }}>
        <button data-test-id="prevBtnTestId" disabled={this.state.pagNumber <= 1} className="carousel-button prev" onClick={() => this.setPageNumber(this.state.pagNumber - 1)}>
        ❮
      </button>
      <button data-test-id="nextBtnTestId" disabled={this.state.pagNumber === this.state.numPages} className="carousel-button next" onClick={() => this.setPageNumber(this.state.pagNumber + 1)}>
        ❯
      </button>
      </div>
    </div>
    </Box>
        )
    }

    renderDifferentLessons = () => {
            if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "document" && this.state.lessonData.data.attributes.file_extension === "image"){
                return this.imageComponent()
              } else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "video"){
                return this.videoComponent()
              } else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "audio"){
                return this.audioComponent()
              } else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "document"){
                const fileExtension = this.state.lessonData.data.attributes.video.url.split('.').pop();
                if (fileExtension === 'pdf') {
                    return this.renderPdfComponent()
                } else {
                  return this.renderDownloadFileSection()
                }
              }
    }

    renderLessonType = () => {
        if(!this.state.isLoading){
            if(this.state.isLastLessonPage){
                return this.renderLastLessonPage()
            }else if(this.state.isLessonProgramPage){
                return this.renderLastLessonProgramPage()
            }else if(this.state.isReviewCourse){
                return this.renderReviewCourse()
            }else{
                return this.renderDifferentLessons()
            }
        }else{
        return (<Box style={{position:"relative",height:"344px",width:"100%"}}>
        </Box>)
      }
    }

    renderImageLessonInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color:"#652786"
            }}>
                    Click image to complete
               </p>
        )
    }

    renderAudioLessonInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color:"#652786"
            }}>
                    Listen to Complete
               </p>
        )
    }

    renderPdfLessonInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color:"#652786",
            }}>
                    Ready to complete
               </p>
        )
    }

    renderVideoLessonInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color: "#652786"
            }}>
                    Watch to complete
               </p>
        )
    }

    renderLessonCompletedText = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#00882C",
                width:"120px",
                display:"flex",justifyContent:"space-around",alignItems:"center"
            }}>
                <img src={completedGreenIcon} style={{width:"24px", height: "24px"}} />    Completed
               </p>
        )
    }

    renderDownloadFileInstruction = () => {
        return (
            <p style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                color: "#652786"
            }}>
            Download to complete
               </p>
        )
    }

    renderDownloadFileSection = () => {
        return (
            <Box style={styles.downloadFileSectionCss}>
                <img src={downloadBackgroundImage}/>
                <Typography style={styles.downloadTextCss}>To complete this lesson please download the file</Typography>
                <Button data-test-id="downloadBtn" style={{textTransform:"none",background: "#E47004",width:"271px",height:"36px",padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "32px",
                                    color: "#FFFFFF",fontSize:"14px",fontWeight:600
                                   }} onClick={this.downloadFile}>Download file</Button>
            </Box>
        )
    }

    renderLastLessonPage = () => {
        let activePhase;
        if(this.state.lessonData.data && this.state.lessonData.data.attributes.phases){
            const nextActivePhase = this.state.lessonData.data.attributes.phases.findIndex((phase:any)=> this.state.lessonData.data.attributes.phase_id === phase.phase_id)
            activePhase = this.state.lessonData.data.attributes.phases[nextActivePhase+1]
        }
     return (
        <Box style={styles.completePhaseBoxCss}>
        <img src={finishedIcon}/>
        <Typography style={{...styles.downloadTextCss,textAlign:"center"}}>You have finished {`[${this.state.activePhaseName}]`}</Typography>
        {activePhase && <LastPhaseBox>
            <img src={lockIcon}/>
        <Typography style={{fontWeight:400,color:"#787776"}}>The next phase will release on {activePhase.release_date}</Typography>
        </LastPhaseBox>}
        <hr style={{color:"#ADAAAA",width:"50%"}}/>
        <LastPhaseBox>
                    <Typography className="cursor" data-test-id="playAgainButton" style={styles.playAgainText} onClick={this.playAgainPhase}>Play again</Typography>
        <Button data-test-id="reviewBtn" 
        style={{textTransform:"none",background: "#E47004",width:"220px",height:"36px",
        padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "88px",
                            color: "#FFFFFF",fontSize:"14px",fontWeight:600
                           }} onClick={this.handleNavigateToCourse}>Back to Course</Button>
                           </LastPhaseBox>
    </Box>
     )
    }

    renderReviewCourse = () => {
     return (
        <Box style={styles.completePhaseBoxCss}><img src={finishedIcon}/>
        <Typography style={styles.courseCompletedText}>Course completed</Typography>
        <hr style={{color:"#ADAAAA",width:"50%"}}/>
        <LastPhaseBox><Typography className="cursor" data-test-id="playAgainButton" style={styles.playAgainText} onClick={this.playAgainPhase}>Play again</Typography>
        <Button data-test-id="reviewBtn" style={{textTransform:"none",background: "#E47004",width:"220px",height:"36px",padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "88px",color: "#FFFFFF",fontSize:"14px",fontWeight:600}} onClick={this.handleReviewCourse}>Review Course</Button></LastPhaseBox>
    </Box>
     )
    }

    renderLastLessonProgramPage = () => {
        let currentphase;
        let activePhase;
        if(this.state.programData.data.attributes.phases){
            const programphase = this.state.programData.data.attributes.phases.find((phase:any)=>phase.program_include.map((programInc:any)=>programInc.id).includes(this.state.lessonData.data.attributes.course_id)) 
            currentphase = this.state.programData.data.attributes.phases[programphase.phase_number - 1]
             activePhase = this.state.programData.data.attributes.phases[programphase.phase_number]
        }
     return (
        <Box style={styles.completePhaseBoxCss}>
        <img src={finishedIcon}/>
        <Typography style={{...styles.downloadTextCss,textAlign:"center"}}>You have finished {`[${currentphase?.phase_name}]`}</Typography>
        {activePhase && <LastPhaseBox>
            <img src={lockIcon}/>
        <Typography style={{fontWeight:400,color:"#787776"}}>The next phase will release on {activePhase.release_date}</Typography>
        </LastPhaseBox>}
        <hr style={{color:"#ADAAAA",width:"50%"}}/>
        <LastPhaseBox>
                    <Typography className="cursor" 
                    data-test-id="playAgainButton" style={styles.playAgainText} onClick={this.playAgainPhase}>Play again</Typography>
        <Button data-test-id="programBackBtn" 
        style={{textTransform:"none",background: "#E47004",width:"220px",height:"36px",
        padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "88px",
                            color: "#FFFFFF",fontSize:"14px",fontWeight:600
                           }} onClick={this.handleNavigateToProgram}>Back to Program</Button>
             </LastPhaseBox>
    </Box>
     )
    }

    renderInstructions = () => {
        if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "video"){
           return this.renderVideoLessonInstruction()
         }else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "document" && this.state.lessonData.data.attributes.file_extension === "image"){
            return this.renderImageLessonInstruction()
        }else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "audio"){
            return this.renderAudioLessonInstruction()
        } else if(this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type === "document"){
                const fileExtension = this.state.lessonData.data.attributes.video.url.split('.').pop();
                if (fileExtension === 'pdf') {
                    return this.renderPdfLessonInstruction()
                } else {
                    return this.renderDownloadFileInstruction()
                }
        }else{
            return <></>
        }
    }

    renderEnableContinueBtn = () => {
        return (
<Button data-test-id="continueToNextLessonId" onClick={this.continueToNextLesson} className="coniunueNextLessonBtn" style={{textTransform:"none",background: "#E47004",width:"271px",height:"36px",padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "32px",
                                    color:"#FFFFFF",fontSize:"14px",fontWeight:600
                                   }} endIcon={<ArrowForwardOutlinedIcon />}>Continue to next lesson</Button>
        )
    }

    renderDisableContinueBtn = () => {
        return (
            <Button data-test-id="continueToNextLessonId" className="coniunueNextLessonBtn" style={{textTransform:"none",background: "#E5E2E1",width:"271px",height:"36px",padding: "8px 40px 8px 40px",gap: "4px",borderRadius: "32px",
                                    color:"#787776",fontSize:"14px",fontWeight:600
                                   }} endIcon={<ArrowForwardOutlinedIcon />}>Continue to next lesson</Button>
        )
    }

    renderContinueBtn = () => {
        if(!this.state.isLastLessonPage && !this.state.isLessonProgramPage && !this.state.isReviewCourse && this.state.lessonData.data && this.state.lessonData.data.attributes.is_completed){
            return this.renderEnableContinueBtn()
        }else{
            return this.renderDisableContinueBtn()
        }
    }
    // Customizable Area End

    render() {
        if(this.state.displayReviewPage) {
            return (
                <ReviewCourse
                   courseName={this.state.lessonData?.data?.attributes?.course_name}
                   courseId={this.state.lessonData?.data?.attributes?.course_id}
                   programId={this.state.lessonData?.data?.attributes?.program_id}
                   submitReviewCourse={this.submitReviewCourse}
                />
            )
        }

        return (
            // Customizable Area Start
            <>
              <Grid container style={styles.profileHeaderGrid}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography style={styles.profileHeader} variant="h3" className="profile_header" data-test-id ="headerDataId"> 
                    <img data-test-id="backBTn" src={viewShape} style={{padding:"0px 10px 0px 10px", width:"32px", height: "22px"}} onClick={this.handleNavigateToCourse}/>
                    {this.state.lessonData?.data?.attributes?.course_name}
                    </Typography>
                    <Grid container spacing={2} style={styles.mainGridBox} key={this.state.lessonData?.data?.id}>
                        <Grid item lg={8} md={8} sm={12} xs={12}
                        style={{
                            width:"844px"
                        }}
                        >
                            {this.renderLessonType()}
                            <Box style={{
                                background: "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)",
                                height:"80px",
                                width:"100%",
                                padding:"16px 40px",
                                gap:"32px",
                                display:"flex",
                                justifyContent:"flex-end",
                                alignItems:"center"
                            }}>
                                {this.state.lessonData.data && this.state.lessonData.data.attributes.is_completed ? this.renderLessonCompletedText() : this.renderInstructions()}
                            {this.renderContinueBtn()}
                            </Box>
                            <Typography style={styles.titleParagraph}>{this.state.lessonData?.data?.attributes.title}</Typography>
                            <Box style={styles.descriptionNoteBox}>
                            <Box>
                            <p
                            style={styles.data_detailP}>
                                Description</p>
                            <p style={styles.data_detail_main}>{this.state.lessonData?.data?.attributes?.description}</p>
                            </Box>
                            <img src={createNoteIcon} alt="create-note" style={styles.noteIcon}/>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}
                        style={{
                            width:"412px"
                        }}
                        >
                            <Paper elevation={4} style={styles.sidebar_paper}>
                                <StyledTabs>
                                    <StyledTab data-testid="lesson-tab" onClick={() => this.handleActiveTab(0)} className={this.state.activeTabIndex === 0 ? "activeTab": ""}>Lesson</StyledTab>
                                    <StyledTab data-testid="note-tab" onClick={() => this.handleActiveTab(1)} className={this.state.activeTabIndex === 1 ? "activeTab": ""}>Notes</StyledTab>
                                </StyledTabs>
                                {this.state.activeTabIndex === 0 ? 
                                <>
                                <ProgressContainer>
                                <ProgressTop>
                                    <CourseSetting>Course completion</CourseSetting>
                                    <CourseSettingAfter>{this.state.lessonData?.data?.attributes.due_in_days}</CourseSettingAfter>
                                </ProgressTop>
                                <Box><StyledProgressBar variant="determinate" value={this.state.progressPercentage} />
</Box>
                                <ProgressBottom>
                                    <LessonsFont>{this.state.lessonData?.data?.attributes.completed_video_count} of {this.state.lessonData?.data?.attributes.total_video_count} lessons completed</LessonsFont>
                                </ProgressBottom>
                               </ProgressContainer>
                                <Box style={styles.main_Accordion}>
                                    <StyledAccordian className="accordionCoustom" style={styles.course_detail_phase}>
                                        {this.state.lessonData.data && this.state.lessonData.data.attributes.phases ?
                                            <>
                                                {this.state.lessonData.data.attributes.phases && Object.entries(this.state.lessonData.data.attributes.phases).map(([k, v]: any) => {
                                                    const isActive = this.state.activePhase == v.phase_id
                                                    return (
                                                        <Accordion data-test-id="phaseAccoedion" style={styles.course_accordion} expanded={isActive} disabled={this.state.lockedData.includes(v.phase_id)} onClick={()=>this.activeAccordion(v)}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon style={{color:"black"}} />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                {v.is_lock && <img style={{marginTop:"6px"}} src={lockIcon} />}
                                                                <div>
                                                                <p style={styles.course_p}>{v.phase}</p>
                                                                {v.is_lock ? <p style={{fontSize:"12px",margin:0}}><span style={{
                                                                    color:"#787776",fontWeight:700,fontSize:"12px"
                                                                }}>Release: </span>{v.release_date}</p> :
                                                                <p style={{fontSize:"12px",margin:0}}><span style={{
                                                                    color:"#E47004",fontWeight:700,fontSize:"12px"
                                                                }}>Due: </span>{v.due_date}</p>}
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={styles.course_detail}>
                                                                {v?.videos?.map((ele: any, index: any) => {

                                                                    return (
                                                                        <Box key={index}>
                                                                            <MediaControlCard activeId={this.state.activeLesson} isFreeTrial={k} button={this.state.lessonData?.data?.attributes?.button} index={index} ele={ele} handleNavigateToLesson={this.activeLessons}/>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {this.state.lessonData.data && this.state.lessonData.data.attributes.videos.map((ele: any, index: any) => {
                                                    return (
                                                        <Box key={index} style={{ width: "100%" }}>
                                        <MediaControlCard data-test-id="mediaControlComponent" activeId={this.state.activeLesson} button={this.state.lessonData?.data?.attributes?.button} index={index} ele={ele} handleNavigateToLesson={this.activeLessons}/>
                                                        </Box>
                                                    )
                                                })}
                                            </>
                                        }
                                    </StyledAccordian>
                                </Box>
                                </>:
                                <Note 
                                notes={this.state.lessonNotedata}
                                createNote={true}
                                data-testid="lesson-note"
                                handleOpenNote={this.handleOpenNote}
                                openNoteModal={this.state.openNotePreview}
                                noteId={this.state.notePreviewId}
                                iconType={this.state.lessonData.data && this.state.lessonData.data.attributes.icon_type}
                                />
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
        <Backdrop style={{...styles.backdrop,position:"absolute"}} open={this.state.isLoading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </Grid>
            {this.renderImageDialog()}
    </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

const LastPhaseBox = styled(Box)({
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    gap:"16px",
    "@media (max-width:960px)": {
        flexDirection:"column"
      
      },
      "@media (min-width:720px)": {
        
      },
})
const styles = {
    cardMedia: {
        justifyContent: "space-between",
        padding: "10px",
        alignItems: "center",
        display: "flex",
        maxHeight:"72px",
        alignContent: "center",
    },
    downloadFileSectionCss:{
        justifyContent:"center",
        display:"flex",
        flexDirection:"column" as "column",
        alignItems:"center",
        height:"482px",background:"#FCF8F8",
        gap:"16px",width:"100%",
    },
    completePhaseBoxCss:{
        justifyContent:"center",
        display:"flex",
        flexDirection:"column" as "column",
        alignItems:"center",
        height:"482px",background:"#EEE8F2",
        gap:"16px",width:"100%",
    },
    courseCompletedText:{
fontSize: "20px",
fontWeight: 600,
textAlign: "center" as "center",
color:"#484646"
    },
    playAgainText:{color:"#484646",fontWeight:600,fontSize:"16px"},
    downloadTextCss:{
fontWeight:600,fontSize:"18px",color:"#787776"
    },
    imageDialogBox:{
        right:0,
        display:"flex",
        justifyContent:"flex-end",
        position: 'absolute' as 'absolute',
        top:0,
        backgroundColor:"white",
        cursor:"pointer",
    },
    media_mainBox: {
        position: 'relative' as 'relative',
        display:"flex",
        alignItems:"center"
    },
    media_img: {
        height: '65px',
        borderRadius: '9px',
        width: '106px',
    },
    media_boxplay: {
        top: '50%',
        position: 'absolute' as 'absolute',
        transform: 'translate(-50%,-50%)',
        left: '50%',
    },
    media_boxtitle: {
        marginLeft: '10px',
        display:"flex",
        justifyContent:"space-around",
        alignItems:"center",
        flexDirection:"column" as "column",
        height:"43px",
    },
    iconBox:{width:"32px",height:"32px",
    background:"rgba(255, 255, 255, 0.46)",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"4px"
                 },
    media_title: {
        marginLeft: '0px', fontSize: '16px',
        width: '100%',
         cursor: 'pointer',
        fontWeight: 400, lineHeight: '16px',
    },
    duration: {
        marginLeft: '0px', fontSize: '12px',
        width: '100%',
        cursor: 'pointer',
        fontWeight: 400, lineHeight: '16px',
    },
    media_chapter: {
        margin: 'auto',
        lineHeight: '18px',
        fontSize: '14px',
        width: 'auto',
        fontWeight: 400,
    },
    course_box: {
        maxWidth: '83vw',
        height: '500px',
        flex: 1,
        overflow: 'hidden',
    },
    course_h3: {
        fontFamily: 'Montserrat'
    },
    course_detail: {
        padding: 0,
        flexDirection: 'column' as 'column',
        display: 'block',
        alignItems: 'center',
    },
    course_p: {
        fontSize: "16px",
        fontWeight: 700,
        textTransform: 'capitalize' as 'capitalize',
        margin: 0,
    },
    course_accordion: {
        boxShadow: 'none'
    },
    phase_h2: {
        fontFamily: 'Montserrat',
        marginTop: '-5px',
    },
    course_detail_phase: {
        flexDirection: 'column' as 'column',
        maxWidth: "unset",     
        padding: 0,
    },
    main_Accordion: {
        boxShadow: 'none',
        maxWidth: "unset !important",       
        position: 'inherit' as 'inherit',       
    },
    mainh2_sidebar: {
        marginTop: '-5px',
        fontFamily: 'Montserrat',
    },
    sidebar_paper: {
        marginBottom: '2rem',
        borderRadius: '16px',
        padding: '30px',
    },
    data_detail: {
        color: 'gray'
    },
    "& .tabBox": {
        width: "100%",
        marginTop: "15px",
      },
      "& .tabMainBox": {
          minHeight: "0px",
        borderBottom: "1px solid rgba(234, 236, 240, 1)",
      },
    data_detailP: {
        fontSize: "20px",
        fontWeight: 600,
        fontStyle: 'roman' as 'roman',
        lineHeight: "24px",
        fontFamily: "Poppins",

    },
    titleParagraph: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "Poppins",
        marginTop:"20px"
    },
    data_detail_main: {
        color: 'rgba(80, 80, 80, 1)',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
    },
    course_start: {
        color: '#fff',
        background: '#2b2b2b',
        padding: '6px 18px',
        borderRadius: '17px',
        marginLeft: '-10px',
        cursor: 'pointer',
    },
    course_buy: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    start_button: {
        background: '#2b2b2b',
        width: '220px',
        color: '#fff',
        borderRadius: '88px',
        height: '48px',
        cursor: 'pointer',
        padding: '6px 18px',
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "16px",
        fontWeight: 600,
    },
    continue_button:{
        background: '#E47004',
        width: '220px',
        color: '#fff',
        borderRadius: '88px',
        height: '48px',
        cursor: 'pointer',
        padding: '6px 18px',
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "16px",
        fontWeight: 600,
    },
    more_info_button:{
        background: '#E47004',
        width: '343px',
        color: '#fff',
        borderRadius: '88px',
        height: '48px',
        cursor: 'pointer',
        padding: '6px 18px',
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "21px",
        fontWeight: 600,
    },
    completed_button:{
        color: '#fff',
        background: '#652786',
        height: '48px',
        width: '220px',
        padding: '6px 18px',
        borderRadius: '88px',
        fontFamily: "Poppins",
        cursor: 'pointer',
        fontWeight: 600,
        lineHeight: "16px",
        fontSize: "16px",
    },
    ratting: {
        marginTop: '3px',
        fontSize: '16px',
        color: '#f9b651',
    },
    rattingBox: {
        display: 'flex',
    },
    rattingTypo: {
        fontWeight: 400,
        fontSize: '12px',
    },
    cateTypo: {
        paddingBottom: "10px",
        fontFamily: 'Montserrat' as 'Montserrat',
        fontWeight: 400,
        fontSize: '12px',
    },
    box_cate: {
        fontWeight: 400,
        marginTop: '-12px',
        color: "#787776",
        fontSize: "16px",

    },
    boxCreate: {
        fontWeight: 500,
        marginTop: '-9px',
        color: "787776",
        fontSize: "16px",
    },
    create_typo: {
        paddingBottom: "10px",
        fontFamily: 'Montserrat' as 'Montserrat',
        fontWeight: 400,
        fontSize: '12px',
    },
    main_title: {
        fontStyle: 'roman' as 'roman',
        fontSize: "20px",
        fontFamily: 'Montserrat' as 'Montserrat',
        fontWeight: 'bold' as 'bold',
    },
    main_titleBox: {
        flexDirection: 'column' as 'column',
        display: 'flex',
    },
    main_courseImg: {
        borderTopRightRadius: "20px",
        height: '100%',
        cursor:"pointer",
        borderTopLeftRadius: "20px",
        maxWidth:"100%"
    },
    mainImg_box: {
        borderRadius:"16px 16px 0px 0px",
        height: "344px",
        position:"relative" as "relative",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        maxWidth:"100%"
    },
    mainGridBox: {
        marginTop: '15px'
    },
    backdrop: {
        color: '#fff',
        zIndex: 99999,
        position:"absolute" as "absolute"
    },
    profileHeader: {
        fontFamily: 'Poppins',
        fontSize: '48px',
        fontWeight: 700,
        display:"flex",
        justifyContent:"start",
        alignItems:"center"
    },
    profileHeaderGrid: {
        paddingTop: '0px',
    },
    selectBox:{
        width: 'auto',
        height: 'auto',
        padding: '2px 8px',
        gap: '10px',
        borderRadius: '88px',
        appearance: 'none' as 'none',
        backgroundColor: '#787776',
        border: 'none',
        color:"white",
         textAlign:"center" as 'center',
         fontSize:"10px",
         fontWeight:500
      },
      descriptionNoteBox:{
        display:'flex',
        justifyContent:'space-between'
      },
      noteIcon:{
        cursor:'pointer'
      }
}

const StyledAccordian = styled(AccordionDetails)({

    "& .Mui-expanded":{
        maxWidth: "unset !important",
    },

    "&.MuiAccordionDetails-root":{
        maxWidth: "unset !important",
        boxShadow: "none",
        border: "none",
        
    },
});

const IconArrange = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItem: "center",
});

const CourseSetting = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px !important",
});

const CourseSettingAfter = styled(Typography)({
fontSize: "12px",
fontWeight: 700,
lineHeight: "18px",
textAlign: "left",
color: "#E47004",
});

const LessonsFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px !important"
});

const StyledTabs = styled(Box)({
    display: "flex",
    width: "100%",
    position: "relative",
    "&::before":{
        height:"2px",
        content: "' '",
        backgroundColor: "lightgray",
        width: "100%",
        bottom: "0",
        position: "absolute",
        left:"0"
    }
});

const StyledTab = styled(Box)({
    height: "81px",
    color: "#1C1B1B",
    lineHeight: "80px",
    textAlign: "center",
    flex: "0.5",
    position: "relative",
    fontWeight: 600,
    fontSize:"16px",
    "&.activeTab":{
        color: "#652786",
    },
    "&.activeTab::before":{
        height:"4px",
        content: "' '",
        backgroundColor: "#652786",
        width: "100%",
        bottom: "-1px",
        position: "absolute",
        borderRadius: "10px",
        left:"0",
    }
});

const StyledProgressBar = styled(LinearProgress)({
        height: "8px !important",
        backgroundColor: "#EEE8F2 !important",
        borderRadius: "5px",
        "& .MuiLinearProgress-barColorPrimary":{
            background: "linear-gradient(269.95deg, #6C328B 0.04%, #F42074 71.48%, #EF2B4B 99.95%)",
            borderRadius:"4px"
        }
      
});

const ProgressContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "20px 0",
    gap: "8px",
    borderBottom: " 1px solid #E5E2E1",
    paddingBottom:"35px",
});

const ProgressTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const ProgressBottom = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const SequenceNumberShow = styled(Box)({
    height: '48px',
    borderRadius: '4px',
    width: '48px',
    textAlign: "center",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    color:"#652786",
    fontWeight: 600,
    fontSize: "20px",
    border: "1px solid #E5E2E1 !important",
    background:"rgba(255, 255, 255, 0.46)",
    boxShadow: "6px 6px 25px 0px #E5E5E540"
});

const NameWithCount = styled("p")({
    flexDirection: "column",
    display: "flex",
});

export function MediaControlCard({ ele, button, isFreeTrial,handleNavigateToLesson,activeId }: any) {
    const classes = useStyles();
    function showIcons(element: any) {
        switch (element.icon_type) {
            case "video":
                return iconVideo
            case "document":
                if(element.file_extension === "image"){
                return iconImage
                }
                return iconPage
            case "audio":
                return iconMusic
            case "image":
                return iconImage
            case "quiz":
                return iconQuiz
            default:
                return;
        }
    };
    return (
        <Card className={ele.id === Number(activeId) ? classes.activeRoot : classes.root} style={styles.cardMedia} onClick={(event)=> handleNavigateToLesson(event,ele)}>
            <Box style={styles.media_mainBox} >
                <SequenceNumberShow> {ele.is_lock   ? <img src={lockIcon} /> : ele.sequence_number}
                </SequenceNumberShow>
                <Box style={styles.media_boxtitle}>
                        <Typography style={styles.media_title}>{ele?.title}</Typography>
                        {ele.duration && <Typography data-test-id="audioDuration" style={styles.duration}>{ele.duration}</Typography>}
                    </Box></Box><IconArrange>
                <Box style={styles.iconBox}>
                    {ele.is_completed ? <img src={lessonCompleteIcon} style={{width:"24px",height:"24px"}}/> : <img src={showIcons(ele)} style={{width:"24px",height:"24px"}}/>}
                </Box>
            </IconArrange>
        </Card>
    )
}
// Customizable Area End