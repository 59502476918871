import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import { Backdrop, Box, Button, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { CardImg, TrashIcon } from "../assets";

interface Card {
  last4Digits: string;
  expMonth: string;
  expYear: string;
  isSelected: boolean;
  onCardClick: (e: SyntheticEvent) => void;
  onDeleteClick: (e: SyntheticEvent) => void;
  onNoBtnClick: (e: SyntheticEvent) => void;
  onYesBtnClick: (e: SyntheticEvent) => void;
}

export default function SavedCard({
  onCardClick,
  last4Digits,
  expMonth,
  expYear,
  isSelected,
  onDeleteClick,
  onNoBtnClick,
  onYesBtnClick,
}: Card) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.cardContainer} onClick={onCardClick}>
        <Box className={classes.cardInner}>
          <Typography className={classes.cardNo}>xxxx xxxx {last4Digits}</Typography>
          <Typography className={classes.cardDate}>
            Valid thru
            <span className={classes.validtyDate}>
              {`${expMonth}/${expYear}`}
            </span>
          </Typography>
        </Box>
        {isSelected && (
          <div className={classes.dialogContainer}>
            <p className={classes.dialogTitle}>
              Are you sure to delete this card?
            </p>
            <Button
              className={clsx(classes.btn, classes.noBtn)}
              onClick={onNoBtnClick}
            >
              no
            </Button>
            <Button
              variant="contained"
              className={clsx(classes.btn, classes.yesBtn)}
              onClick={onYesBtnClick}
            >
              yes
            </Button>
          </div>
        )}
        <Backdrop open={isSelected} className={classes.backdrop} />
      </Box>
      <Button
        className={classes.deleteBtn}
        startIcon={
          <img src={TrashIcon} className={classes.trashIcon} alt="trash" />
        }
        onClick={onDeleteClick}
        style={{ visibility: isSelected ? "hidden" : "visible" }}
      >
        delete
      </Button>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      width: 213,
      height: 135,
      cursor: "pointer",
      position: "relative",
      marginRight: 10,
    },
    cardInner: {
      backgroundImage: `url(${CardImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "absolute",
      inset: 0,
      borderRadius: 12,
    },
    cardNo: {
      color: "white",
      position: "absolute",
      width: "100%",
      marginTop: 51,
      marginLeft: 70,
      fontSize: 10,
      fontWeight: 700,
    },
    cardDate: {
      position: "absolute",
      width: "100%",
      color: "white",
      marginTop: 107,
      marginLeft: 34,
      fontSize: 10,
      fontWeight: 700,
    },
    validtyDate: {
      marginLeft: 10,
    },
    trashIcon: {
      width: 12,
      height: 16,
    },
    deleteBtn: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#01040D",
      marginLeft: 15,
      marginTop: 3,
      "&.MuiButton-root": {
        textTransform: "capitalize",
      },
    },
    dialogContainer: {
      position: "absolute",
      inset: 15,
      background: "white",
      zIndex: theme.zIndex.drawer + 2,
      borderRadius: 12,
      textAlign: "center",
    },
    dialogTitle: {
      color: "#01040D",
      textAlign: "center",
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16.5px",
      letterSpacing: "0.086px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      position: "absolute",
      borderRadius: 12,
    },
    btn: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0.086px",
      textTransform: "capitalize",
    },
    yesBtn: {
      background: "linear-gradient(223deg, #6C328B 0%, #EF2B4B 100%)",
      borderRadius: 16,
      color: "#fff",
      padding: "2px 0",
    },
    noBtn: {
      color: "#EF2B4B",
      marginRight: 5,
    },
  })
);
