import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { trashIcon } from "../assets";

interface ICartItem {
  thumbnail: string;
  title: string;
  author: string;
  price?: string;
  itemType: string;
  onRemoveClick?: () => void;
  danger?: boolean;
}

export default function CartItem({
  thumbnail,
  title,
  author,
  price,
  itemType,
  onRemoveClick,
  danger,
}: ICartItem) {
  const classes = useStyles();

  const courseOrProgramPrice = price ? parseFloat(price).toFixed(2) : null;

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <Grid container spacing={2}>
          <Grid item>
            <div className={classes.thumbnailContainer}>
              <img
                className={classes.thumbnail}
                alt="complex"
                src={thumbnail}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={10} container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  className={clsx(classes.cardH1, danger && classes.danger)}
                >
                  {itemType}
                </Typography>
                <Typography className={classes.courseName}>{title}</Typography>
                <Typography className={classes.authorName}>
                  {author && `By ${author}`}
                </Typography>
                <Typography className={classes.coursePrice}>
                  {courseOrProgramPrice && `$${courseOrProgramPrice}`}
                </Typography>
              </Grid>
            </Grid>
            {onRemoveClick && (
              <Grid container item xs={12} sm={2} justifyContent="center">
                <Button
                  startIcon={
                    <img
                      src={trashIcon}
                      className={classes.trashIcon}
                      alt="remove from cart"
                    />
                  }
                  className={classes.removeBtn}
                  onClick={onRemoveClick}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    itemContainer: {
      padding: theme.spacing(2),
      borderBottom: "1px solid rgba(225, 225, 225, 0.577997);",
    },
    thumbnailContainer: {
      width: 132,
      height: 132,
    },
    thumbnail: {
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 10,
    },
    cardH1: {
      color: "#6c328b",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "20px",
      padding: "10px 0",
      textTransform: "capitalize",
    },
    danger: {
      color: "#EF2B4B",
    },
    courseName: {
      color: "#1a1a1a",
      padding: "2px 0",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "20px",
      textTransform: "capitalize",
    },
    coursePrice: {
      color: "#545650",
      fontSize: "28px",
      fontWeight: 700,
      lineHeight: "36px",
    },
    authorName: {
      color: "#797979",
      fontSize: "15px",
      fontWeight: 400,
      margin: "10px 0",
      textTransform: "capitalize",
    },
    removeBtn: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "16px",
      color: "#1A1A1A",
      "&.MuiButton-root": {
        textTransform: "capitalize",
      },
    },
    trashIcon: {
      width: 19,
      height: 24,
    },
  })
);
