export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const NotFoundImage = require("../assets/NotFoundImage.jpeg");
export const addContentImg = require("../assets/image_select.png");
export const imageHtmlCourse2 = require("../assets/image_html_course2.png");
export const rightArrow = require("../assets/right_arrow.png");
export const EditIcon3 = require("../assets/icon_edit.png");
export const closeIcon = require("../assets/close_icon.svg");
export const deleteIcon = require("../assets/delete_Icons.png");
export const plusIcon = require("../assets/plus-icon.png");
export const searchIcon = require("../assets/image_.svg");
export const iconInfo = require("../assets/icon_info.png");
export const Document = require("../assets/document.svg");
export const AddIcon = require("../assets/icon_add.svg");