import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {toast} from 'react-toastify';
interface CountryData {
    id: string,
    type: string,
    attributes: {
      name: string,
      emoji_flag: string,
      country_code: string   
    }  
  }

  export interface ResponseContentData {
    id: string
    data: [];
  };
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location?:any;
  classes:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  open:boolean;
  showPassword: boolean;
  alert:{isOpen:boolean,msg:string,type:string};
  CountryFlagCode:Array<CountryData>;
  selectOpen:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  getRegisteredApiCallIds:any;
  getCountryCodeFlagCallIds:string=""
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      open:false,
      showPassword:false,
      alert:{isOpen:false,msg:'',type:'error'},
      CountryFlagCode:[],
      selectOpen:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
    if (this.getRegisteredApiCallIds === apiRequestCallId) {
       if (responseJson && responseJson.data && responseJson.data.attributes) {
            toast.success("Please Confirm Email to Activate Account",{autoClose:2000});
            this.props.navigation.navigate("EmailAccountLoginBlock")
          }
          else {
                if (responseJson.errors){
                let value = Object.keys(responseJson.errors).map(item => responseJson.errors[item])
                toast.error(value[0].message,{autoClose:2000});
              }
    }
    } 
    else if(this.getCountryCodeFlagCallIds === apiRequestCallId){
      this.getCountryRes(responseJson)
    }
    else if (responseJson && responseJson.errors) {
         toast.error("Something went wrong",{autoClose:2000});
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    super.componentDidMount();
    
 this.CountryCodeApi()
  
  }
  getRegistered (data:any) {
      this.doRegisterUser({
        contentType: configJSON.formDataContentType,
        method: configJSON.apiMethodTypeAddDetail,
        endPoint: configJSON.accountsAPiEndPoint,
        body: JSON.stringify(data)
    });
    }

     handleClickOpen = () => {
      this.setState({open:true});
    };
  
     handleClose = () => {
      this.setState({open:false});
    };
    goToHomePage = () => {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg)
    }
    navigateToLogin = () => {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg)
    }

    isStringNullOrBlank = () => {
      return false;
    }
    handleSelectOpen = () => {
      this.setState({selectOpen:true})
    };
  
    handleSelectClose = () => {
      this.setState({selectOpen:false})
    };
    // API Call API Call 
  doRegisterUser(data: any) {
      const { contentType, method, endPoint, body } = data;
      const header = {
        "Content-Type": contentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    this.getRegisteredApiCallIds = requestMessage.messageId
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  CountryCodeApi=async()=>{
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getCountryCodeFlagCallIds = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.CountryFlagApiEndPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

getCountryRes=(responseJSON:ResponseContentData)=>{
this.setState({CountryFlagCode:responseJSON.data})
}
  // Customizable Area End
}
