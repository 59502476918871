import React, { useEffect } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CartItem from "./CartItem.web";
import Loader from "../../../../components/src/Loader.web";
import { check } from "../assets";

interface IOrderSuccess {
  cartType: string;
  isLoading: boolean;
  getOrders: () => void;
  orderItems: any;
  lastSubscriptionOrder: any;
  onGoToHomeClick: () => void;
  onGoToPersoalLibClick: () => void;
  onPayNextSubsClick: () => void;
}

export default function OrderSuccess({ 
  cartType,
  isLoading,
  getOrders,
  orderItems, 
  onGoToHomeClick, 
  onGoToPersoalLibClick,
  lastSubscriptionOrder,
  onPayNextSubsClick,
}: IOrderSuccess) {
  const classes = useStyles();

  let orderItemsContent;
  if (cartType === "subscription") {
    const { expand_type, image, title, created_by_name } = lastSubscriptionOrder;
    orderItemsContent = (
      <CartItem
        itemType={expand_type === "BxBlockCoursecreation::Course" ? "Course" : "Program"}
        thumbnail={image}
        title={title}
        author={created_by_name}
      />
    );
  } else if (cartType === "otp" && orderItems && orderItems?.length > 0) {
    orderItemsContent = orderItems.map((data: any) => {
      const isCourse =
        data?.attributes?.purchasable_type === "BxBlockCoursecreation::Course"
          ? true
          : false;
      const thumbnail = isCourse
        ? data?.attributes?.course_detail?.thumbnail_image?.url
        : data?.attributes?.program_detail?.thumbnail_image?.url;
      const title = isCourse
        ? data?.attributes?.course_detail?.title
        : data?.attributes?.program_detail?.title;
      const author = isCourse
        ? data?.attributes?.course_detail?.created_by
        : data?.attributes?.program_detail?.created_by;
      return (
        <CartItem
          key={data.id}
          itemType={isCourse ? "Course" : "Program"}
          thumbnail={thumbnail}
          title={title}
          author={author}
        />
      );
    });
  } else {
    orderItemsContent = null;
  }

  useEffect(() => {
    if (cartType === "otp") {
      getOrders();
    }
  }, [cartType]);

  return (
    <>
      <Loader loading={isLoading} />
      <Container maxWidth="sm" className={classes.mainContainer}>
        <Grid container>
          <Grid item md={4} xs={12} className={classes.centeredContainer}>
            <img 
              src={check} 
              alt="order success" 
              className={classes.img}
            />
          </Grid>
          <Grid item md={8} xs={12} className={classes.centeredContainer}>
            <h1 className={classes.headingText}>Congratulations!</h1>
            <p className={classes.descriptionText}>
              Your order is successfully placed
            </p>
            <p className={classes.descriptionText}>
              Please go to the{" "}
              <span className={classes.emphasis}>Personal Library</span> to view
              your courses.
            </p>
          </Grid>
        </Grid>
        <p className={classes.secondaryText}>
          {cartType === "otp" ? "Order Summary" : "You subscribed to"}
        </p>
        {orderItemsContent}
        {
          cartType === "otp" ? (
            <Grid container className={classes.btnContainer}>
              <Grid item>
                <Button 
                  fullWidth 
                  className={classes.btn}
                  onClick={onGoToHomeClick}
                >
                  Go to Home Page
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  className={`${classes.btn} ${classes.containedBtn}`}
                  onClick={onGoToPersoalLibClick}
                >
                  Go to Personal Library
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.btnContainer}>
              <Grid item>
                <Button 
                  fullWidth 
                  className={classes.btn}
                  onClick={onGoToPersoalLibClick}
                >
                  Go to Personal Library
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  className={`${classes.btn} ${classes.containedBtn}`}
                  onClick={onPayNextSubsClick}
                >
                  Pay next subscription
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      background: "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)",
      borderRadius: 16,
      marginTop: 50,
    },
    centeredContainer: {
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    infoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headingText: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "39px",
      color: "#1E1F20",
    },
    descriptionText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#1A1A1A",
    },
    img: {
      width: 140,
      height: 140,
    },
    secondaryText: {
      fontWeight: 800,
      fontSize: "24px",
      lineHeight: "24px",
      color: "#545650",
    },
    emphasis: {
      fontWeight: 700,
    },
    btnContainer: {
      justifyContent: "space-around",
      padding: "25px 0",
    },
    btn: {
      width: "100%",
      height: "64px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      color: "#6C328B",
      padding: "0 40px",
    },
    containedBtn: {
      borderRadius: "16px",
      backgroundColor: "#6c328b",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#6c328b",
      },
    },
  })
);