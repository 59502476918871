Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "imagemanagement2";
exports.labelBodyText = "imagemanagement2 Body";
exports.DeleteApiMethod = "DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.getAllThumbailEndPoint="bx_block_image_management/thumbnail_libraries"
exports.getIconEndPoint="bx_block_image_management/icons"
exports.AdvancedSearchEndPoint="/bx_block_advanced_search/search/filter"
exports.expertAdminListApiEndPoint = "bx_block_library2/experts";

// Customizable Area End