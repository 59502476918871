import React, { useRef } from "react";
import {
    Box,
    Button, IconButton,
    Paper, Typography
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { play, group, shop } from "./assets";

interface Props {
    style: any;
    addCartItem: (id: number, type: string) => void;
    postXpandLibraryData: (id: number) => void;
    playButton: (id: number) => void;
    data: any;
}

export default function RecommendedCourses({ style, addCartItem, postXpandLibraryData, playButton, data }: Props) {
    let slider = useRef<any>();

    const next = () => {
        // @ts-ignore
        slider.slickNext();
    }
    const previous = () => {
        // @ts-ignore
        slider.slickPrev();
    }

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    if (data?.length === 0) {
        return null;
    }

    return (
        <>
            <Box style={styles.course_box}>
                <Typography style={styles.course_h3}>Recommended courses for you !</Typography>
                <Box style={styles.main_cardBox}>
                    <Slider
                        {...settings} className="slider1">
                        {data ?
                            data?.map((ele: any, i: number) => {
                                return (
                                    <Box key={i}>
                                        <Box style={styles.box_data} >
                                            <CardMedia
                                                style={styles.img_media}
                                                image={ele?.attributes?.thumbnail_image?.url}
                                            />
                                            <Paper style={styles.paper_data}>
                                                <Typography
                                                    style={styles.typo_title}
                                                    onClick={() => playButton(ele.id)}>
                                                    {ele?.attributes?.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p"
                                                    style={styles.typo_create}>
                                                    {ele?.attributes?.created_by}
                                                </Typography>

                                                <Typography variant="body2" color="textSecondary" component="p"
                                                    style={styles.typo_due}>
                                                    Due in {ele?.attributes?.course_timeline} Days
                                                </Typography>

                                                {ele?.attributes?.is_paid ?
                                                    <>
                                                        <Card style={styles.card_shop}>
                                                            <CardActionArea onClick={() => addCartItem(ele.id, ele.type)}>
                                                                <img src={shop} style={styles.shop_img} />
                                                            </CardActionArea>
                                                        </Card>
                                                    </>
                                                    :
                                                    <> <Card style={styles.group_cardImg}>
                                                        <CardActionArea onClick={() => postXpandLibraryData(ele.id)}>
                                                            <img src={group} style={styles.group_img} />
                                                        </CardActionArea>
                                                    </Card>

                                                        <Card style={styles.img_card}>
                                                            <CardActionArea onClick={() => playButton(ele.id)}>
                                                                <img src={play} style={styles.img_play} />
                                                            </CardActionArea>
                                                        </Card>
                                                    </>}
                                            </Paper>
                                        </Box>
                                    </Box>
                                )
                            }) :
                            <></>}
                    </Slider>

                    {/* <Box>
                        <IconButton aria-label="delete" onClick={next} style={styles.IconButton}>
                            <NavigateNextOutlinedIcon style={styles.NavigateNextOutlinedIcon} />
                        </IconButton>
                    </Box> */}
                </Box>
            </Box>
        </>
    )
}

const styles = {
    IconButton: {
        position: 'absolute' as 'absolute',
        right: 0,
        top: '45%',
        background: 'black'
    },
    course_box: {
        height: '500px',
        maxWidth: '83vw',
        overflow: 'hidden',
        flex: 1
    },
    course_h3: {
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontWeight: 700,
    },
    NavigateNextOutlinedIcon: {
        color: '#fff'
    },
    img_play: {
        margin: 'auto',
        padding: '8px',
        display: 'flex'
    },
    img_card: {
        width: '87px',
        height: '45px',
        borderRadius: '30px',
        float: 'right' as 'right',
        marginTop: '-2px',
        marginRight: '25px',
        background: '#6C328B'
    },
    group_img: {
        margin: 'auto',
        padding: '14px',
        display: 'flex'
    },
    group_cardImg: {
        width: '87px',
        height: '45px',
        borderRadius: '30px',
        float: 'left' as 'left',
        marginTop: '-2px',
        marginLeft: '25px',
        background: '#EF2B4B'
    },
    shop_img: {
        margin: 'auto',
        padding: '27px',
        display: 'flex',
        marginTop: '-21px',
        width: '100%',
        height: 'auto'
    },
    card_shop: {
        width: '87px',
        height: '45px',
        borderRadius: '30px',
        float: 'right' as 'right',
        marginTop: '-2px',
        marginRight: '20px',
        background: '#1D1F4F'
    },
    typo_due: {
        margin: 'auto',
        fontSize: '12px',
        marginBottom: '10px',
        padding: '0 17px',
        color: '#44444F'
    },
    typo_create: {
        margin: 'auto',
        marginTop: '-15px',
        padding: '0 17px',
        color: 'black'
    },
    typo_title: {
        width: '100%',
        minHeight: 65,
        marginBottom: '-10px',
        padding: '17px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        cursor: 'pointer'
    },
    paper_data: {
        width: '100%',
        borderRadius: '25px',
        marginTop: '-20px',
        height: '168px'
    },
    img_media: {
        height: "240px",
        width: '100%',
        borderRadius: '25px 25px 0 0'
    },
    box_data: {
        padding: "0 10px 0 10px",
        borderRadius: '25px 25px 0 0',
        marginTop: '20px'
    },
    main_cardBox: {
        position: 'relative' as 'relative'
    },
}