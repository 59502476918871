import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Checkbox, styled } from "@material-ui/core";
import { makeStyles, createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Table, Thead, Tbody, Th, Tr, Td, Pagination } from "../../components/src/CustomTableV3";
import { dummy_img } from "./Table/asset";
import { DeleteAll, ContentDelete, EditLibrary, DownloadIcon, iconCheckbox,UpArrow,DownArrow,checkboxIcon,CheckedIcon} from "../../blocks/videolibrary/src/assets";
import { ContentData } from "../../blocks/videolibrary/src/VideoLibraryController";

const TABLE_HEADERS = [
  { id: 1, label: "", sort: false },
  { id: 2, label: "Name", sort: true, dataType: "string", key: "title" },
  { id: 3, label: "Type", sort: true, dataType: "string", key: "content_type" },
  { id: 4, label: "Created by", sort: true, dataType: "string", key: "expert_name" },
  { id: 5, label: "Created on", sort: true, dataType: "date", key: "created_at" },
  { id: 6, label: "", sort: false },
];

interface TableHeader {
  id: number;
  label: string;
  sort: boolean;
  dataType?: string;
  key?: string;
}

interface Props {
  users?: Array<ContentData>;
  onSortIconClick: (dataType: string, key: string, sortType: string) => void;
  prevPageExists?: boolean;
  nextPageExists?: boolean;
  handleNextPageNavigation?: () => void;
  handlePrevPageNavigation?: () => void;
  getSelectedChecboxID: (list: Array<number>)=> void;
  deleteItem: (id: number) => void;
  handleDeleteAll: () => void;
  handleOpenObjectModal?: (id: number) => void;
  thumbnailList?:Array<ContentData>;
  isThumbnail?:boolean;
  TableHeader?:TableHeader[];
  closeDeleteButton?:any;
  updateChildState?:any;
  gotoEditContent?:(contentType:string,contentid:number) => void;
  hideSelectAndSelectAll?: boolean
  }

const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
};

export default function UserTable({
  users,
  onSortIconClick,
    prevPageExists,
  nextPageExists,
  handleNextPageNavigation,
  handlePrevPageNavigation,
  getSelectedChecboxID,
  deleteItem,
  handleDeleteAll,
  handleOpenObjectModal,
  thumbnailList,
  isThumbnail,
  TableHeader,
  closeDeleteButton,
  updateChildState,gotoEditContent,
  hideSelectAndSelectAll
}: Props) {
  const classes = useStyles();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteButton , setDeleteButton] = useState(false)
  const [userId, setUserId] = useState(0);

  const handleHover = (
    event: React.MouseEvent<HTMLSpanElement>,
    id: number
  ) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
      setUserId(id);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    const item=isThumbnail?thumbnailList:users
    let selectedIds: number[]|undefined = [];
    if (selectedCheckboxes.length === item?.length) {
      setSelectedCheckboxes([]);
       updateChildState(false);
    } else {
      selectedIds = item?.map((user:ContentData) => user.id)||[];
      setSelectedCheckboxes(selectedIds);
      updateChildState(true);
    }
  };

  const handleDeleteAllButton = () => {
    handleDeleteAll()
  }

  const handleCheckboxChange = (userId: number) => {
    const selectedIndex = selectedCheckboxes.indexOf(userId);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = [...selectedCheckboxes, userId];
    } else {
      newSelected = selectedCheckboxes.filter(id => id !== userId);
    }
    setSelectedCheckboxes(newSelected);
    updateChildState(newSelected.length > 0);
  };
  const handleSrc=(user:any)=>{
    if(isThumbnail){
    if( user?.attributes.image !== null && user?.attributes.image.url){
      return user?.attributes.image.url
    }
  }else if(!!user?.attributes?.thumbnail?.url  && user?.attributes?.thumbnail?.url !== ""){
      return user.attributes.thumbnail.url
    }else{
      return dummy_img
    }
  }

  useEffect(()=>{
    getSelectedChecboxID(selectedCheckboxes)
   

  },[selectedCheckboxes])
  const isChecked = selectedCheckboxes.length === (isThumbnail ? thumbnailList?.length : users?.length);
   const headershow=isThumbnail?TableHeader:TABLE_HEADERS
const thumbTable=isThumbnail?thumbnailList:users
  
  return (
    <Card className={classes.card} classes={{ root: classes.customPaper }}>
      <CardContent classes={{ root: classes.MuiCardContentRoute }}>
        <div className={classes.checkboxContainer}>
          {!hideSelectAndSelectAll &&(
            <>
          <div className={classes.InnerCheckbox} onClick={handleSelectAll}>
          <CheckboxOne icon={<img src={iconCheckbox}/>}  checkedIcon={<img src={CheckedIcon}/>} data-test-id="filterTestId" checked={isChecked} />
            <span className={classes.selectAll}>&nbsp; &nbsp; Select all</span>
          </div>
          {closeDeleteButton? (
           <Button onClick={handleDeleteAllButton} className={classes.editButton} data-test-id="delete_icon">
           <img  src={DeleteAll} /> &nbsp;&nbsp;
           Delete
         </Button>
          ):(
            ""
          )}
          </>
          )}
        </div>
        <Table>
          <Thead>
            <Tr>
              {headershow?.map((header: TableHeader) => {
                return header.label === "" ? (
                  <Th key={header.id} />
                ) : (
                  <Th key={header.id}>
                    <p className={classes.headingLabel}>
                      {header.label}{" "}
                      <span className={classes.arrowIconsContainer}>
                        {header.sort && (
                          <>
                            <img
                              src={DownArrow}
                              width="12px"
                              onClick={() =>
                                onSortIconClick(
                                  header?.dataType ?? "",
                                  header?.key ?? "",
                                  "asc"
                                )
                              }
                            />

                            <img
                              src={UpArrow}
                              width="12px"
                              onClick={() =>
                                onSortIconClick(
                                  header?.dataType ?? "",
                                  header?.key ?? "",
                                  "desc"
                                )
                              }
                            />
                          </>
                        )}
                      </span>
                    </p>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {thumbTable?.map((user:ContentData) => {
              return (
                <Tr key={user.id} RowClick= {()=> handleOpenObjectModal && handleOpenObjectModal(user.id)}>

                  <Td rowBg={selectedCheckboxes.includes(user.id)}>
                  {!hideSelectAndSelectAll &&
                    <CheckboxMain
                     icon={<img src={checkboxIcon} />}
                     checkedIcon={<img src={CheckedIcon}/>}
                      data-test-id={`checkbox-${user.id}`}
                      checked={selectedCheckboxes.includes(user.id)}
                      onChange={() => handleCheckboxChange(user.id)}
                    />
                  }
                  </Td>
                  <Td rowBg={selectedCheckboxes.includes(user.id)}>
                  <img
                      src={handleSrc(user)}
                      style={{
                        borderRadius: '4px',
                        border: '1px solid #929090',
                        background: 'lightgray 50% / cover no-repeat',
                        boxShadow: '0px 1px 3px 0px rgba(140, 140, 140, 0.72)',
                        height: '56px',
                        width: '56px',
                        marginRight:"20px",
                      }}
                    />
                    <span style={{
                        cursor:"pointer"
                    }}>{user.attributes.title}</span>
                  </Td>
                  {/* <Td></Td> */}
                  {isThumbnail &&  <Td rowBg={selectedCheckboxes.includes(user.id)}>{user.attributes.created_by}</Td> }
                   
                    {!isThumbnail && (<>
                      <Td rowBg={selectedCheckboxes.includes(user.id)}>{ user.attributes.content_type}</Td> 
                      <Td rowBg={selectedCheckboxes.includes(user.id)}>{ user.attributes.expert_name}</Td>
                    </>
                    )}
                  <Td rowBg={selectedCheckboxes.includes(user.id)}>
                    
                    {user.attributes.created_at
                      ? formatDate(user.attributes.created_at)
                      : "-"}
                  </Td>
                  {!isThumbnail  &&
                  <Td rowBg={selectedCheckboxes.includes(user.id)}>
                    <img onClick={(event)=> {event.stopPropagation(); gotoEditContent && gotoEditContent(user.attributes.content_type as string,user.id)}} className={classes.image} src={DownloadIcon} />
                  </Td>
                  }
                  <Td rowBg={selectedCheckboxes.includes(user.id)}>
                    <img onClick={(event) => {event.stopPropagation(); deleteItem(user.id)}} className={classes.image}  src={ContentDelete} />
                </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <div className={classes.tableFooer}>
          <div />
          {!isThumbnail && handleNextPageNavigation && handlePrevPageNavigation &&
          <Pagination
            disabledPrevBtn={!prevPageExists}
            disabledNextBtn={!nextPageExists}
            onNextClick={handleNextPageNavigation}
            onPrevClick={handlePrevPageNavigation}
          />
}
        </div>
      </CardContent>
    </Card>

  );
}
const CheckboxOne = withStyles({
  root: {
    color: '#652786 !important',
  height: "24px !important",
    '&$checked': {
      color: '#652786',
    },
  },
  checked: {}, 
})(Checkbox);


const CheckboxMain = withStyles({
  root: {
    color: '#B0B0B0', 
    width: '24px',
    height: '24px',
    borderRadius:"20px",
    '&$checked': {
      color: '#652786', 
    },
  },
  checked: {}, 
})(Checkbox);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: "19.5px",
      marginTop: "37px",
      width: "100%"
    },
    "& . MuiCardContent-root": {
      padding: "1px"
    },
    MuiCardContentRoute: {
      padding: "2px !important"
    },
    headingLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        fontSize: "18px",
        minWidth:"140px"
      }, 
    },
    checkTd: {
     textTranform: "capitalize"
    },
    checkImage: {
      paddingLeft: "0px",
      paddingRight: "8px"

    },
    arrowIconsContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "3px 0px 0px 10px",
      "& > img": {
        cursor: "pointer",
      },
      "& > img:first-child": {
        marginBottom: 5,
      },
    },
    image:{
     cursor: "pointer"
    },
    editButton: {
      width: "148px",
      height: "36px",
      border: "1px solid #E47004",
      borderRadius: "8px",
      color: "#E47004",
      fontFamily: "Poppins Arial, Helvetica, sans-serif",
      textTransform: "capitalize"
    },
    tableFooer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    teamCount: {
      width: "50%",
      margin: "0 auto",
    },
    customPaper: {
      boxShadow: "none"
    },
    paper: {
      borderRadius: "18px",
      background: "#FFF",
      padding: "0 10px",
      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    },
    checkboxContainer: {
      display: "flex",
       gap:"20px"
    },
    InnerCheckbox: {
      display: "flex",
      alignItems: "center"
    },
    checkboxMainClass: {
      width: "24px",
      height: "24px",
    },
    customCheckbox: {
      width: "18px",
      height: "18px",
      backgroundColor: "#000",
      "&:focus": {
        backgroundColor: "#000",
        outline: "none"
      }
    },
    selectAll: {
      color: "#484646",
      fontSize: "18px",
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600
    },
    teamNamesContainer: {
      "& > p": {
        color: "#1A1A1A",
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize",
        margin: "10px 0",
      },
      "& > p:first-child": {
        color: "#12142B",
        fontSize: "20px",
        fontWeight: 600,
        margin: "10px 0",
      },
    },
    teamHeader: {
      color: "#12142B",
      fontSize: "20px",
      fontWeight: 600,
      margin: "10px 0",
     
    },
    tableCellCSS:{
      minWidth:"120px"
    }

  })
);