import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "../../../framework/src/Utilities";
import { getCurrentPathName } from "../../../components/src/Utilities";
export const configJSON = require("./config");
import * as H from "history";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { IconType } from "../../imagemanagement2/src/CommonSelectIconFromLibraryController.web";
const navigation = require("react-navigation");

interface ImageThumbnail {
    url: string;
    id: number;
}

interface LibraryCourseAttributes {
    title: string;
    thumbnail_image: ImageThumbnail;
    id: number;
    status: string;
    content_type: string;
}

interface LibraryItem {
    id: string; type: string; attributes: LibraryCourseAttributes
}

interface MessageError {
    message: string;
}

interface ErrorResponseIcon {
    icon: string;
}

interface ResponseError {
    errors: MessageError[];
}

interface OwnerAccount {
    id: string;
    type: string;
    attributes: {
        email: string;
        full_name: string;
        avatar: string | null;
        first_name: string;
        last_name: string;
        profession: string;
        is_admin: boolean;
    };
}

export interface OwnerData {
    data: Array<OwnerAccount>
}

export interface AssigneItem {
    type: string;
    checked?: boolean
    id: string;
    attributes: {
        name: string;
        id: number;
    };
}

export interface AssigneeData {
    data: Array<AssigneItem>
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: typeof navigation;
    navigation: typeof navigation;
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    rewardStatus: string;
    dueDate: Date | null;
    daysLoggedInToggle: boolean;
    lessonsCompletedToggle: boolean;
    checkvalue: boolean;
    statusActive: boolean;
    coursesCompletedToggle: boolean;
    user: string;
    icon: File | null;
    rewardName: string;
    ownerText: string;
    personName: string;
    completeContentToggle: boolean;
    contentToggle: boolean;
    names: AssigneeData;
    loggedInRowToggle: boolean;
    daysLoggedIn: number;
    lessonsCompleted: number;
    coursesCompleted: number;
    loggedInRow: number;
    anchorEl: boolean;
    ownerNameList: OwnerData;
    error: string;
    lableText: string;
    placeholderSearchText: string;
    achievementError: boolean;
    courseIdData: Array<string>;
    programIdData: Array<string>;
    person: AssigneItem;
    selectedUser: AssigneItem | null;
    isCreate: boolean;
    dataForContent: LibraryItem[];
    showChooseIconPrompt: boolean;
    ownerError: boolean;
    assignesError: boolean;
    rewardOwnerId: string;
    dueDateError: boolean;
    showIconListingDialogue: boolean;
    iconPreview: IconType;
    searchTerm: string;
    rewardError: boolean;
    rewardField: string;
    helperText: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class CreateRewardController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    getRewardOwnerListCallId: string = "";
    getAssignesListCallId: string = "";
    postCreateRewardApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            programIdData: [],
            isCreate: false,
            dataForContent: [],
            showChooseIconPrompt: false,
            user: "",
            icon: null,
            rewardName: "",
            personName: "",
            names: {
                data: []
            },
            rewardStatus: "Active",
            contentToggle: false,
            courseIdData: [],
            showIconListingDialogue: false,
            lessonsCompleted: 0,
            coursesCompleted: 0,
            loggedInRow: 0,
            ownerNameList: {
                data: []
            },
            ownerText: "",
            error: "error msg",
            lableText: "Lable Text",
            placeholderSearchText: "",
            anchorEl: false,
            checkvalue: false,
            statusActive: true,
            achievementError: false,
            ownerError: false,
            assignesError: false,
            dueDate: null as Date | null,
            completeContentToggle: false,
            daysLoggedInToggle: false,
            lessonsCompletedToggle: false,
            person: {
                id: "",
                type: "",
                attributes: {
                    id: 0,
                    name: ""
                }
            },
            coursesCompletedToggle: false,
            loggedInRowToggle: false,
            daysLoggedIn: 0,
            rewardOwnerId: "",
            dueDateError: false,
            iconPreview: {} as IconType,
            searchTerm: "",
            selectedUser: null,
            rewardError: false,
            rewardField: "",
            helperText: ""
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getRewardOwnerList();
        this.getAssigneListApiCall();
        this.getIconFromAddIconPageAndSetFormValues()
        const ownerExpertId = localStorage.getItem("expert_id")
        this.setState({ rewardOwnerId: `${ownerExpertId}` });
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson) {
                if (responseJson.data) {
                    this.apiSuccessFunctions(apiRequestCallId, responseJson);
                }
                if (responseJson.errors) {
                    this.apiFailureFunctions(apiRequestCallId, responseJson);
                }
            }
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let rewardData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
            if (rewardData) {
                this.setState({
                    daysLoggedIn: rewardData.propsData.createData.daysLoggedIn,
                    coursesCompletedToggle: rewardData.propsData.createData.coursesCompletedToggle,
                    coursesCompleted: rewardData.propsData.createData.coursesCompleted,
                    loggedInRow: rewardData.propsData.createData.loggedInRow,
                    searchTerm: rewardData.propsData.createData.personName,
                    iconPreview: rewardData.propsData.createData.iconPreview,
                    lessonsCompletedToggle: rewardData.propsData.createData.lessonsCompletedToggle,
                    lessonsCompleted: rewardData.propsData.createData.lessonsCompleted,
                    rewardOwnerId: rewardData.propsData.createData.rewardOwnerId,
                    rewardName: rewardData.propsData.createData.rewardName,
                    rewardStatus: rewardData.propsData.createData.status,
                    dueDate: rewardData.propsData.createData.dueDate,
                    contentToggle: rewardData.propsData.createData.contentToggle,
                    daysLoggedInToggle: rewardData.propsData.createData.daysLoggedInToggle,
                    loggedInRowToggle: rewardData.propsData.createData.loggedInRowToggle,
                    rewardField: rewardData.propsData.createData.rewardFieldvalue
                });

                const assineeData = rewardData.assignedData;
                const newAssignedData = JSON.parse(assineeData);
                this.setState({ dataForContent: newAssignedData });
                const courses = newAssignedData.filter((item: { attributes: { content_type: string; }; }) => item.attributes.content_type === "BxBlockCoursecreation::Course");
                const programs = newAssignedData.filter((item: { attributes: { content_type: string; }; }) => item.attributes.content_type === "BxBlockCoursecreation::Program");

                const assignedIdsForCourse = courses.map((item: { id: string; }) => item.id);
                const assignedIdsForProgram = programs.map((item: { id: string; }) => item.id);
                {
                    newAssignedData.length > 0 && toast.success("Content for has been selected successfully", { icon: false, closeButton: false })
                }
                this.setState({ courseIdData: assignedIdsForCourse });
                this.setState({ programIdData: assignedIdsForProgram });
            }
        }
        const foundObject = this.state.names.data.find((obje) => obje.id === this.state.searchTerm);
        if (foundObject) {
            this.setState({ selectedUser: foundObject });
        }
    };

    apiSuccessFunctions = async (apiCallId: string, response: OwnerData & AssigneeData & object) => {
        if (apiCallId === this.getAssignesListCallId) {
            this.getAssignessListSuccess(response)
        }
        if (apiCallId === this.getRewardOwnerListCallId) {
            this.getRewardOwnerListSuccess(response)
        }
        if (apiCallId === this.postCreateRewardApiCallId) {
            this.postRewardSuccessFunction(response)
        }
    };

    apiFailureFunctions = async (apiCallId: string, response: ResponseError & ErrorResponseIcon) => {
        if (apiCallId === this.postCreateRewardApiCallId) {
            this.postAchievementFailureCallBack(response)
        }
    };

    rewardApiCallId = async (valueData: {
        body?: {};
        type?: string;
        contentType?: string;
        method?: string;
        endPoint?: string;
    }) => {
        let token = localStorage.getItem("token");
        let { contentType, method, endPoint, body, type } = valueData;
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        let header = {
            "Content-Type": contentType,
            token,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getRewardOwnerList = async () => {
        this.getRewardOwnerListCallId = await this.rewardApiCallId({
            endPoint: configJSON.getOwnerListEndpoint,
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
        });
    };

    getRewardOwnerListSuccess = (response: OwnerData) => {
        this.setState({ ownerNameList: response });
    };

    getAssigneListApiCall = async () => {
        this.getAssignesListCallId = await this.rewardApiCallId({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.getBranchNameEndpoint
        });
    };

    getAssignessListSuccess = (response: AssigneeData) => {
        response.data.forEach(item => {
            item.checked = false;
        });
        this.setState({ names: response });
    };

    handleContent = () => {
        this.setState({ contentToggle: !this.state.contentToggle });
    };

    handleNavigateContent = () => {
        if (this.state.rewardName && this.state.iconPreview?.id) {
            const createData = {
                rewardOwnerId: this.state.rewardOwnerId,
                rewardName: this.state.rewardName,
                status: this.state.rewardStatus,
                dueDate: this.state.dueDate,
                contentToggle: this.state.contentToggle,
                daysLoggedInToggle: this.state.daysLoggedInToggle,
                daysLoggedIn: this.state.daysLoggedIn,
                coursesCompletedToggle: this.state.coursesCompletedToggle,
                coursesCompleted: this.state.coursesCompleted,
                lessonsCompletedToggle: this.state.lessonsCompletedToggle,
                lessonsCompleted: this.state.lessonsCompleted,
                loggedInRowToggle: this.state.loggedInRowToggle,
                loggedInRow: this.state.loggedInRow,
                personName: this.state.searchTerm,
                iconPreview: this.state.iconPreview,
                rewardFieldvalue: this.state.rewardField
            };

            localStorage.setItem("rewardName", this.state.rewardName);
            localStorage.setItem("isReward", "true");

            const manageAccountNavigationId: Message = new Message(
                getName(MessageEnum.NavigationMessage)
            );
            const raiseMessage = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            if (this.state.courseIdData.length > 0 || this.state.programIdData.length > 0) {
                raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { createData: createData, assigned: this.state.dataForContent });
            } else {
                raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { createData: createData });
                raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage1), { createData: createData });
            }
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "SelectContent");
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(manageAccountNavigationId);
        }
        else {
            toast.error("Please add icon and reward name")
        }
    };

    handleStatusChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        this.setState({ rewardStatus: event.target.value as string });
    };

    handleDueDate = (date: Date) => {
        this.setState({ dueDate: date });
    };

    handleAchievementName = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        const regex = /^[a-zA-Z0-9 _-]{0,30}$/;
        if (regex.test(newValue)) {
            this.setState({
                rewardName: newValue,
                achievementError: false
            });
        }
        else {
            this.setState({ achievementError: true });
        }
    };

    handleOwnerChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        const value = event.target.value as string;
        this.setState({
            rewardOwnerId: value
        });
    };

    handleDaysLoggedInToggle = () => {
        this.setState({
            daysLoggedInToggle: !this.state.daysLoggedInToggle
        });
    };

    handleLessonsCompletedToggle = () => {
        this.setState({ lessonsCompletedToggle: !this.state.lessonsCompletedToggle });
    };

    handleCoursesCompletedToggle = () => {
        this.setState({ coursesCompletedToggle: !this.state.coursesCompletedToggle })
    };

    handleLoggedInRowToggle = () => {
        this.setState({ loggedInRowToggle: !this.state.loggedInRowToggle });
    };

    handleDaysLoggedin = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ daysLoggedIn: Number(newValue) });
        }
    };

    handleLessonsCompleted = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ lessonsCompleted: Number(newValue) });
        }
    };

    handleCoursesCompleted = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ coursesCompleted: Number(newValue) })
        }
    };

    handleLoggedInRow = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ loggedInRow: Number(newValue) })
        }
    };

    handleIncrement = (inputName: string) => {
        if (inputName === "loggedInRowInput" && this.state.loggedInRowToggle === true) {
            const newvalue = Number(this.state.loggedInRow) + 1;
            this.setState({ loggedInRow: newvalue });
        }
        if (inputName === "lessonsCompletedInput" && this.state.lessonsCompletedToggle === true) {
            const newValue = Number(this.state.lessonsCompleted) + 1;
            this.setState({ lessonsCompleted: newValue });
        }
        if (inputName === "coursesCompletedInput" && this.state.coursesCompletedToggle === true) {
            const newValue = Number(this.state.coursesCompleted) + 1;
            this.setState({ coursesCompleted: newValue });
        }
        if (inputName === "daysLoggedInInput" && this.state.daysLoggedInToggle === true) {
            const newValue = Number(this.state.daysLoggedIn) + 1;
            this.setState({ daysLoggedIn: newValue });
        } 
    };

    handleDecrement = (inputName: string) => {
        if (inputName === "coursesCompletedInput" && this.state.coursesCompletedToggle === true && Number(this.state.coursesCompleted) > 0) {
            const newValue = Number(this.state.coursesCompleted) - 1;
            this.setState({ coursesCompleted: newValue });
        }
        if (inputName === "daysLoggedInInput" && this.state.daysLoggedInToggle === true && Number(this.state.daysLoggedIn) > 0) {
            const newValue = Number(this.state.daysLoggedIn) - 1;
            this.setState({ daysLoggedIn: newValue });
        }
        if (inputName === "loggedInRowInput" && this.state.loggedInRowToggle === true && Number(this.state.loggedInRow) > 0) {
            const newvalue = Number(this.state.loggedInRow) - 1;
            this.setState({ loggedInRow: newvalue });
        }
        if (inputName === "lessonsCompletedInput" && this.state.lessonsCompletedToggle === true && Number(this.state.lessonsCompleted) > 0) {
            const newValue = Number(this.state.lessonsCompleted) - 1;
            this.setState({ lessonsCompleted: newValue });
        }
    };

    postCraeteReward = async () => {
        let formdata = new FormData();
        let courseableIds = this.state.courseIdData;
        let programIds = this.state.programIdData;

        if(this.state.rewardField.length < 5) { 
            return  toast.error("*Minimum 5 characters required.", { autoClose: 10000 }) 
        }

        if(this.state.rewardField.length >150) {
           return toast.error("*Maximum 150 characters required.", { autoClose: 10000 })    
        }

        if (this.state.programIdData.length > 0) {
            programIds.forEach((courseId, index) => {
                formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_id]`, courseId);
            });
        }

        if (this.state.courseIdData.length > 0) {
            courseableIds.forEach((courseId, index) => {
                formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_id]`, courseId);
            });
        }

        let status = this.state.rewardStatus === "Not Active" ? "inactive" : "active";

        if (this.state.iconPreview?.id) {
            formdata.append("icon_id", `${this.state.iconPreview?.id}`)
        }

        formdata.append("reward[description]", this.state.rewardField)
        formdata.append("reward[name]", this.state.rewardName)
        formdata.append("reward[status]", status)
        formdata.append("admin_user_id", this.state.rewardOwnerId)
        formdata.append("reward[branch_id]", this.state.searchTerm)

        formdata.append("reward[due_date]", `${this.state.dueDate}`)
        formdata.append("reward[complete_content]", `${this.state.contentToggle}`)
        
        formdata.append("reward[is_course_completion_days]", `${this.state.coursesCompletedToggle}`)
        if (this.state.coursesCompletedToggle) {
            formdata.append("reward[course_completion_days]", `${this.state.coursesCompleted}`)
        }
        formdata.append("reward[is_num_of_days_login]", `${this.state.daysLoggedInToggle}`)
        if (this.state.daysLoggedInToggle) {
            formdata.append("reward[num_of_days_login]", `${this.state.daysLoggedIn}`)
        }
        formdata.append("reward[is_num_of_days_raw_login]", `${this.state.loggedInRowToggle}`)
        if (this.state.loggedInRowToggle) {
            formdata.append("reward[num_of_days_raw_login]", `${this.state.loggedInRow}`)
        }
        formdata.append("reward[is_lesson_completion_days]", `${this.state.lessonsCompletedToggle}`)
        if (this.state.lessonsCompletedToggle) {
            formdata.append("reward[lesson_completion_days]", `${this.state.lessonsCompleted}`)
        }
        if (this.state.programIdData.length > 0) {
            programIds.forEach((programId, index) => {
                formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_type]`, "BxBlockCoursecreation::Program");
            });
        }
        if (this.state.courseIdData.length > 0) {
            courseableIds.forEach((courseId, index) => {
                formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_type]`, "BxBlockCoursecreation::Course");
            });
        }

        this.postCreateRewardApiCallId = await this.rewardApiCallId({
            endPoint: configJSON.rewardAPiEndPoint,
            method: configJSON.exampleAPiMethod,
            body: formdata,
            type: 'formData'
        })
    };

    postRewardSuccessFunction = (response: object) => {
        if (response) {
            this.setState({
                rewardOwnerId: "",
                rewardName: "",
                rewardStatus: "Active",
                dueDate: null,
                contentToggle: false,
                daysLoggedInToggle: false,
                daysLoggedIn: 0,
                coursesCompletedToggle: false,
                coursesCompleted: 0,
                lessonsCompletedToggle: false,
                lessonsCompleted: 0,
                loggedInRowToggle: false,
                loggedInRow: 0,
                personName: "",
                searchTerm: "",
                rewardField: ""
            }, () => {
                this.props.history.push("/RewardsAndAchievement")
                toast.success("Reward created successfully")
                localStorage.removeItem("rewardName");
            });
        }
    };

    postAchievementFailureCallBack = (errorResponse: ResponseError & ErrorResponseIcon) => {
        if (errorResponse.errors.length > 0) {
            errorResponse.errors.forEach((error) => {
                toast.error(error.message, { autoClose: 10000 })
            });
        } else if (errorResponse.icon) {
            toast.error(errorResponse.icon, { autoClose: 10000 })
        }
    };

    updateStateWithConditions = () => {
        const mandatoryStatesValid = this.state.rewardOwnerId.length > 0 &&
            this.state.rewardName &&
            this.state.dueDate &&
            this.state.searchTerm !== "" &&
            this.state.iconPreview?.id;

        const optionalStatesValid = this.state.daysLoggedIn > 1 ||
            this.state.coursesCompleted > 1 ||
            this.state.lessonsCompleted > 1 ||
            this.state.loggedInRow > 1 ||
            this.state.contentToggle;

        return mandatoryStatesValid && optionalStatesValid;
    };

    openAddIconDialogue = () => {
        this.setState({ showChooseIconPrompt: true, showIconListingDialogue: false })
    };

    closeAddIconDialogue = () => {
        this.setState({ showChooseIconPrompt: false })
    };

    openIconLibrary = () => {
        this.setState({ showIconListingDialogue: true })
    };

    closeIconLibrary = () => {
        this.setState({ showIconListingDialogue: false })
    };

    handleAddIconFromLocal = () => {
        setStorageData("originScreen", getCurrentPathName());
        const filledFormdata = {
            rewardOwnerId: this.state.rewardOwnerId,
            rewardName: this.state.rewardName,
            status: this.state.rewardStatus,
            dueDate: this.state.dueDate,
            contentToggle: this.state.contentToggle,
            daysLoggedInToggle: this.state.daysLoggedInToggle,
            daysLoggedIn: this.state.daysLoggedIn,
            coursesCompletedToggle: this.state.coursesCompletedToggle,
            coursesCompleted: this.state.coursesCompleted,
            lessonsCompletedToggle: this.state.lessonsCompletedToggle,
            lessonsCompleted: this.state.lessonsCompleted,
            loggedInRowToggle: this.state.loggedInRowToggle,
            loggedInRow: this.state.loggedInRow,
            personName: this.state.searchTerm,
            courseIdData: this.state.courseIdData,
            programIdData: this.state.programIdData,
            dataForContent: this.state.dataForContent
        }
        this.props.history.push({
            pathname: "/AddIcon",
            state: {
                formData: {
                    ...filledFormdata
                }
            }
        });
    };

    handleAddIconFromIconListDialogue = (selectIconResponse: IconType) => {
        this.setState({ iconPreview: selectIconResponse });
        this.closeAddIconDialogue();
        this.closeIconLibrary()
    };

    getIconFromAddIconPageAndSetFormValues = () => {
        if (this.props.location?.state) {
            this.handleAddIconFromIconListDialogue(this.props.location.state?.icon)
            const preFillFormdata: State = this.props.location?.state?.formData;
            this.setState({ ...preFillFormdata });
        }
    };

    handleTagUsers = (value: AssigneItem | null) => {
        this.setState({ searchTerm: `${value?.attributes.id}`, selectedUser: value })
    };

    handleReward = (event: {target: {value: string}}) => {
        const value = event.target.value;
        let error = false;
        let helperText = "";
        if (value.length < 5) {
            error = true;
            helperText = "*Minimum 5 characters required.";
        } else if (value.length > 150) {
            error = true;
            helperText = "*Maximum 150 characters allowed.";
        }
        this.setState({ rewardField: value, rewardError: this.state.rewardError, helperText });
    };

    // Customizable Area End
}