import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { IBlock } from "./../../../framework/src/IBlock";
import { Message } from "./../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "./../../../framework/src/Messages/MessageEnum";
import { runEngine } from "./../../../framework/src/RunEngine";
export const configJSON = require("./config");
import * as H from "history";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { IconType } from "../../../blocks/imagemanagement2/src/CommonSelectIconFromLibraryController.web";
import { setStorageData } from "./../../../framework/src/Utilities";
import { getCurrentPathName } from "../../../components/src/Utilities";

interface ThumbnailImage {
    id: number;
    url: string;
}

interface CourseAttributes {
    id: number;
    status: string;
    title: string;
    thumbnail_image: ThumbnailImage;
    content_type: string;
}

interface Item {
    id: string; type: string; attributes: CourseAttributes
}

interface ErrorMessage {
    message: string;
}
  
interface ErrorResponse {
    errors: ErrorMessage[];
}

interface ErrorResponseIcon {
    icon: string;
}

interface ExpertAccount {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      email: string;
      profession: string;
      full_name: string;
      avatar: string | null;
      is_admin: boolean;
    };
}

export interface OwnerData {
    data: Array<ExpertAccount>
}

export interface BranchList {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
    };
    checked?: boolean
}

export interface AssigneeData {
    data: Array<BranchList>
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: any
    navigation: any
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    user: string;
    icon: File | null;
    achievementName: string;
    ownerText: string;
    personName: string;
    names: AssigneeData;
    status: string;
    dueDate: Date | null;
    completeContentToggle: boolean;
    contentToggle: boolean;
    daysLoggedInToggle: boolean;
    lessonsCompletedToggle: boolean;
    coursesCompletedToggle: boolean;
    loggedInRowToggle: boolean;
    daysLoggedIn: number;
    lessonsCompleted: number;
    coursesCompleted: number;
    loggedInRow: number;
    ownerNameList: OwnerData;
    error : string;
    lableText: string;
    placeholderSearchText: string;
    anchorEl: boolean;
    checkvalue: boolean;
    statusActive: boolean;
    achievementError: boolean;
    ownerError: boolean;
    assignesError: boolean;
    ownerId: string;
    dueDateError: boolean;
    courseIdData: Array<string>;
    programIdData: Array<string>;
    isCreate: boolean;
    dataForContent: Item[];
    showChooseIconPrompt: boolean;
    showIconListingDialogue: boolean;
    iconPreview: IconType;
    searchTerm: string;
    person: BranchList;
    selectedUser: BranchList | null;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class CreateAchievementController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    getOwnerLsitApiCallId: string = "";
    getBranchNameApiCallId: string = "";
    postCreateAchievementApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            user: "",
            icon: null,
            achievementName: "",
            personName: "",
            names: {
                data: []
            },
            status: "Active",
            dueDate: null as Date | null,
            completeContentToggle: false,
            daysLoggedInToggle: false,
            lessonsCompletedToggle: false,
            coursesCompletedToggle: false,
            loggedInRowToggle: false,
            daysLoggedIn: 0,
            contentToggle: false,
            lessonsCompleted: 0,
            coursesCompleted: 0,
            loggedInRow: 0,
            ownerNameList: {
                data: []
            },
            ownerText: "",
            error : "error msg",
            lableText: "Lable Text",
            placeholderSearchText: "",
            anchorEl: false,
            checkvalue: false,
            statusActive: true,
            achievementError: false,
            ownerError: false,
            assignesError: false,
            ownerId: "",
            dueDateError: false,
            courseIdData: [],
            programIdData: [],
            isCreate: false,
            dataForContent: [],
            showChooseIconPrompt: false,
            showIconListingDialogue: false,
            iconPreview: {}  as IconType,
            searchTerm: "",
            person: {
                id: "",
                type: "",
                attributes: {
                    id: 0,
                    name: ""
                }
            },
            selectedUser: null,
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIRequestMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.NavigationTargetMessage),
          getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getOwnerListApiCall();
        this.getBranchNameApiCall();
        // display icon from add icon screen and pre fill form fields
        this.getIconFromAddIconPageAndSetFormValues()
        
        const expertId = localStorage.getItem("expert_id")
        this.setState({ownerId: `${expertId}`});
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson) {
                if (responseJson.data) {
                    this.apiSuccessCallBack(apiRequestCallId, responseJson);
                }
                if (responseJson.errors) {
                    this.apiFailureCallBack(apiRequestCallId, responseJson);
                }
            }
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
             if(data){
                 this.setState({
                     ownerId: data.propsData.createData.ownerId,
                     achievementName: data.propsData.createData.achievementName,
                     status: data.propsData.createData.status,
                     dueDate: data.propsData.createData.dueDate,
                     contentToggle: data.propsData.createData.contentToggle,
                     daysLoggedInToggle: data.propsData.createData.daysLoggedInToggle,
                     daysLoggedIn: data.propsData.createData.daysLoggedIn,
                     coursesCompletedToggle: data.propsData.createData.coursesCompletedToggle,
                     coursesCompleted: data.propsData.createData.coursesCompleted,
                     lessonsCompletedToggle: data.propsData.createData.lessonsCompletedToggle,
                     lessonsCompleted: data.propsData.createData.lessonsCompleted,
                     loggedInRowToggle: data.propsData.createData.loggedInRowToggle,
                     loggedInRow: data.propsData.createData.loggedInRow,
                     searchTerm: data.propsData.createData.personName,
                     iconPreview: data.propsData.createData.iconPreview,
                 });
     
                 const assineeData = data.assignedData;
                 const newAssignedData = JSON.parse(assineeData);
                 this.setState({dataForContent : newAssignedData});
                 const courses = newAssignedData.filter((item: { attributes: { content_type: string; }; }) => item.attributes.content_type === "BxBlockCoursecreation::Course");
                 const programs = newAssignedData.filter((item: { attributes: { content_type: string; }; }) => item.attributes.content_type === "BxBlockCoursecreation::Program");
                 
                 const assignedIdsForCourse = courses.map((item: { id: string; }) => item.id);
                 const assignedIdsForProgram = programs.map((item: { id: string; }) => item.id);
                 {
                    newAssignedData.length > 0 && toast.success( "Content for has been selected successfully", { icon: false, closeButton: false})
                 }
                 this.setState({courseIdData: assignedIdsForCourse});
                 this.setState({programIdData: assignedIdsForProgram});
            }
        }
                 const foundObject = this.state.names.data.find((obje) => obje.id === this.state.searchTerm);
                 if(foundObject){
                    this.setState({selectedUser: foundObject});
                 }
    }

    apiSuccessCallBack = async (apiCallId: string, response: OwnerData & AssigneeData & object) => {
        if (apiCallId === this.getOwnerLsitApiCallId) {
            this.getOwnerListSuccessCallBack(response)
        }
        if (apiCallId === this.getBranchNameApiCallId) {
            this.getBranchNameSuccessCallBack(response)
        }
        if (apiCallId === this.postCreateAchievementApiCallId) {
            this.postAchievementSuccessCallBack(response)
        }
    };

    apiFailureCallBack = async (apiCallId: string, response: ErrorResponse & ErrorResponseIcon) => {
        if (apiCallId === this.postCreateAchievementApiCallId) {
            this.postAchievementFailureCallBack(response)
        }
    };

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
    }) => {
        let { contentType, method, endPoint, body ,type} = valueData;
        const token = localStorage.getItem("token");
        let header = {
            "Content-Type": contentType,
            token,
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getOwnerListApiCall = async () => {
        this.getOwnerLsitApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getOwnerListEndpoint
        });
    };

    getOwnerListSuccessCallBack = (response: OwnerData) => {
        this.setState({ownerNameList : response});
    };

    getBranchNameApiCall = async () => {
        this.getBranchNameApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getBranchNameEndpoint
        });
    };

    getBranchNameSuccessCallBack = (response: AssigneeData) => {
        response.data.forEach(item => {
            item.checked = false;
        });
       this.setState({names: response});
    };

    handleContent = () => {
      this.setState({contentToggle: !this.state.contentToggle});
    };

    handleSelectContent = () => {
        if(this.state.achievementName && this.state.iconPreview?.id){  
        const createData = {
            ownerId: this.state.ownerId,
            achievementName: this.state.achievementName,
            status: this.state.status,
            dueDate: this.state.dueDate,
            contentToggle: this.state.contentToggle,
            daysLoggedInToggle: this.state.daysLoggedInToggle,
            daysLoggedIn: this.state.daysLoggedIn,
            coursesCompletedToggle: this.state.coursesCompletedToggle,
            coursesCompleted: this.state.coursesCompleted,
            lessonsCompletedToggle: this.state.lessonsCompletedToggle,
            lessonsCompleted: this.state.lessonsCompleted,
            loggedInRowToggle: this.state.loggedInRowToggle,
            loggedInRow: this.state.loggedInRow,
            personName: this.state.searchTerm,
            iconPreview: this.state.iconPreview
        };

        localStorage.setItem("achievement", this.state.achievementName);
        localStorage.setItem("isReward", "false");
     
        const manageAccountNavigationId: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        const raiseMessage = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        if (this.state.courseIdData.length > 0 || this.state.programIdData.length > 0) {
            raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { createData: createData, assigned: this.state.dataForContent});
        } else {
            raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { createData: createData });
            raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage1), { createData: createData });
        }
        manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "SelectContent");
        manageAccountNavigationId.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
        manageAccountNavigationId.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(manageAccountNavigationId);
    }
    else{
        toast.error("Please add icon and achievement name") 
    }
    };

    handleStatusChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        this.setState({ status: event.target.value as string});
    };

    handleDueDate = (date: Date) => {
        this.setState({ dueDate: date });
    };

    handleAchievementName = (event: {target : {value: string}}) => {
        const newValue = event.target.value;
        const regex = /^[a-zA-Z0-9 _-]{0,30}$/;
        if (regex.test(newValue)) {
          this.setState({ 
            achievementName: newValue,
            achievementError: false
        });
        }
        else {
          this.setState({achievementError: true});
        }
    };

    handleOwnerChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        const value = event.target.value as string;
        this.setState({
            ownerId: value
        });
    };

    handleDaysLoggedInToggle = () => {
        this.setState({
            daysLoggedInToggle: !this.state.daysLoggedInToggle
        });
    };

    handleLessonsCompletedToggle = () => {
        this.setState({ lessonsCompletedToggle: !this.state.lessonsCompletedToggle});
    };

    handleCoursesCompletedToggle = () => {
        this.setState({ coursesCompletedToggle: !this.state.coursesCompletedToggle })
    };

    handleLoggedInRowToggle = () => {
        this.setState({ loggedInRowToggle: !this.state.loggedInRowToggle});
    };

    handleDaysLoggedin = (event: {target : {value: string}}) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
          this.setState({ daysLoggedIn: Number(newValue) });
        }
    };

    handleLessonsCompleted = (event: {target : {value: string}}) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ lessonsCompleted: Number(newValue) });
        }
    };

    handleCoursesCompleted = (event: {target : {value: string}}) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ coursesCompleted: Number(newValue) })
        }
    };

    handleLoggedInRow = (event: {target : {value: string}}) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ loggedInRow: Number(newValue) })
        }
    };

    handleIncrement = (inputName: string) => {
        if (inputName === "daysLoggedInInput" && this.state.daysLoggedInToggle === true) {
            const newValue = Number(this.state.daysLoggedIn) + 1;
            this.setState({ daysLoggedIn: newValue });
        }
        if (inputName === "lessonsCompletedInput" && this.state.lessonsCompletedToggle === true) {
            const newValue = Number(this.state.lessonsCompleted) + 1;
            this.setState({ lessonsCompleted: newValue });
        }
        if (inputName === "coursesCompletedInput" && this.state.coursesCompletedToggle === true) {
            const newValue = Number(this.state.coursesCompleted) + 1;
            this.setState({ coursesCompleted: newValue });
        }
        if (inputName === "loggedInRowInput" && this.state.loggedInRowToggle === true) {
            const newvalue = Number(this.state.loggedInRow) + 1;
            this.setState({ loggedInRow: newvalue });
        }
    };

    handleDecrement = (inputName: string) => {
        if (inputName === "daysLoggedInInput" && this.state.daysLoggedInToggle === true && Number(this.state.daysLoggedIn) > 0) {
            const newValue = Number(this.state.daysLoggedIn) - 1;
            this.setState({ daysLoggedIn: newValue });
        }
        if (inputName === "lessonsCompletedInput" && this.state.lessonsCompletedToggle === true && Number(this.state.lessonsCompleted) > 0) {
            const newValue = Number(this.state.lessonsCompleted) - 1;
            this.setState({ lessonsCompleted: newValue });
        }
        if (inputName === "coursesCompletedInput" && this.state.coursesCompletedToggle === true && Number(this.state.coursesCompleted) > 0) {
            const newValue = Number(this.state.coursesCompleted) - 1;
            this.setState({ coursesCompleted: newValue });
        }
        if (inputName === "loggedInRowInput" && this.state.loggedInRowToggle === true && Number(this.state.loggedInRow) > 0) {
            const newvalue = Number(this.state.loggedInRow) - 1;
            this.setState({ loggedInRow: newvalue });
        }
    };

    postCreateAchievement = async () => {
        const personNames = this.state.personName; 
        const courseableIds = this.state.courseIdData;
        const programIds = this.state.programIdData;
        let formdata = new FormData();

        if(this.state.courseIdData.length > 0){
            courseableIds.forEach((courseId, index) => {
                formdata.append(`achievement[content_reward_achievements_attributes][${index}][courseable_id]`, courseId);
            });
        }

        if (this.state.programIdData.length > 0) {
            programIds.forEach((courseId, index) => {
                formdata.append(`achievement[content_reward_achievements_attributes][${index}][courseable_id]`, courseId);
            });
        }

        const status = this.state.status === "Not Active" ? "inactive" : "active";

        formdata.append("achievement[name]", this.state.achievementName)
        formdata.append("achievement[branch_id]", this.state.searchTerm)
        formdata.append("achievement[status]", status)
        formdata.append("admin_user_id", this.state.ownerId)
        if(this.state.iconPreview?.id){
            formdata.append("icon_id", `${this.state.iconPreview?.id}`)
        }
        formdata.append("achievement[due_date]", `${this.state.dueDate}`)
        formdata.append("achievement[complete_content]", `${this.state.contentToggle}`)
        formdata.append("achievement[is_num_of_days_login]", `${this.state.daysLoggedInToggle}`)
        if(this.state.daysLoggedInToggle){
          formdata.append("achievement[num_of_days_login]", `${this.state.daysLoggedIn}`)
        }
        formdata.append("achievement[is_course_completion_days]", `${this.state.coursesCompletedToggle}`)
        if(this.state.coursesCompletedToggle){
          formdata.append("achievement[course_completion_days]", `${this.state.coursesCompleted}`)
        }
        formdata.append("achievement[is_lesson_completion_days]", `${this.state.lessonsCompletedToggle}`)
        if(this.state.lessonsCompletedToggle){
          formdata.append("achievement[lesson_completion_days]", `${this.state.lessonsCompleted}`)
        }
        formdata.append("achievement[is_num_of_days_raw_login]", `${this.state.loggedInRowToggle}`)
        if(this.state.loggedInRowToggle){
          formdata.append("achievement[num_of_days_raw_login]", `${this.state.loggedInRow}`)
        }
        if (this.state.courseIdData.length > 0) {
            courseableIds.forEach((courseId,index) => {
                formdata.append(`achievement[content_reward_achievements_attributes][${index}][courseable_type]`, "BxBlockCoursecreation::Course");
            });
        }
        if(this.state.programIdData.length > 0){
            programIds.forEach((programId,index) => {
                formdata.append(`achievement[content_reward_achievements_attributes][${index}][courseable_type]`, "BxBlockCoursecreation::Program");
            });
        }
        
        this.postCreateAchievementApiCallId = await this.apiCall({
            method: configJSON.exampleAPiMethod,
            endPoint: configJSON.postCreateAchievementEndPoint,
            body: formdata,
            type: 'formData'
        })
    };

    postAchievementSuccessCallBack = (response: object) => {
        if(response){
            this.setState({
                ownerId: "",
                achievementName: "",
                status: "Active",
                dueDate: null,
                contentToggle: false,
                daysLoggedInToggle: false,
                daysLoggedIn: 0,
                coursesCompletedToggle: false,
                coursesCompleted: 0,
                lessonsCompletedToggle: false,
                lessonsCompleted: 0,
                loggedInRowToggle: false,
                loggedInRow: 0,
                personName: "",
                searchTerm: ""
            } , () => {
                this.props.history.push("/RewardsAndAchievement")
                toast.success("Achievement created successfully")
                localStorage.removeItem("achievement")
                localStorage.removeItem("isReward")
            });
        }
    };

    postAchievementFailureCallBack = (errorResponse: ErrorResponse & ErrorResponseIcon) => {
        if (errorResponse.errors.length > 0) {
            errorResponse.errors.forEach((error) => {
                toast.error(error.message, { autoClose: 10000 })
            });
        }else if(errorResponse.icon){
            toast.error(errorResponse.icon, { autoClose: 10000 })
        }
    };

    updateStateWithConditions = () => {
        const mandatoryStatesValid = this.state.ownerId.length > 0 &&
            this.state.achievementName &&
            this.state.dueDate &&
            this.state.searchTerm !== "" &&
            this.state.iconPreview?.id ;

            const optionalStatesValid = this.state.daysLoggedIn > 1 ||
            this.state.coursesCompleted > 1 ||
            this.state.lessonsCompleted > 1 ||
            this.state.loggedInRow > 1 ||
            this.state.contentToggle;

        return mandatoryStatesValid && optionalStatesValid;
    };
    
    openAddIconDialogue = () => {
        this.setState({showChooseIconPrompt : true, showIconListingDialogue: false})
    }
    closeAddIconDialogue = () => {
        this.setState({showChooseIconPrompt : false})
    }
    openIconLibrary = () => {
        this.setState({showIconListingDialogue : true})
    }
    closeIconLibrary = () => {
        this.setState({showIconListingDialogue : false})
    }
    handleAddIconFromLocal = () => {
        setStorageData("originScreen", getCurrentPathName());
        const filledFormdata = {
            ownerId: this.state.ownerId,
            achievementName: this.state.achievementName,
            status: this.state.status,
            dueDate: this.state.dueDate,
            contentToggle: this.state.contentToggle,
            daysLoggedInToggle: this.state.daysLoggedInToggle,
            daysLoggedIn: this.state.daysLoggedIn,
            coursesCompletedToggle: this.state.coursesCompletedToggle,
            coursesCompleted: this.state.coursesCompleted,
            lessonsCompletedToggle: this.state.lessonsCompletedToggle,
            lessonsCompleted: this.state.lessonsCompleted,
            loggedInRowToggle: this.state.loggedInRowToggle,
            loggedInRow: this.state.loggedInRow,
            personName: this.state.searchTerm,
            courseIdData: this.state.courseIdData , 
            programIdData: this.state.programIdData,
            dataForContent: this.state.dataForContent
        }
        this.props.history.push({
            pathname: "/AddIcon",
            state: {
                formData: {
                    ...filledFormdata
                }
            }
        });
    }
    handleAddIconFromIconListDialogue = (selectIconResponse: IconType) => {
        this.setState({iconPreview : selectIconResponse});
        this.closeAddIconDialogue();
        this.closeIconLibrary()
    }
    getIconFromAddIconPageAndSetFormValues = () => {
        if (this.props.location?.state) {
          this.handleAddIconFromIconListDialogue(this.props.location.state?.icon)
          // set other form fields
          const preFillFormdata: State = this.props.location?.state?.formData;
          this.setState({...preFillFormdata});
        }
      };

      handleTagUsers = (value: BranchList | null) => {
        this.setState({searchTerm:`${value?.attributes.id}`, selectedUser: value})
      };

    // Customizable Area End
}