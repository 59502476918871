import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

interface ICartTypeSelectionButtons {
  cartType: string;
  handleCartTypeChange: (type: string) => void;
}

export default function CartTypeSelectionButtons({
  cartType,
  handleCartTypeChange,
}: ICartTypeSelectionButtons) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        onClick={() => handleCartTypeChange("otp")}
        className={clsx(classes.btn, classes.oneTimePaymentBtn)}
        style={{ background: cartType === "otp" ? "#F6E9FB" : "#FFFFFF" }}
      >
        One time payment
      </Button>
      <Button
        variant="outlined"
        onClick={() => handleCartTypeChange("subscription")}
        className={clsx(classes.btn, classes.subscriptionBtn)}
        style={{ background: cartType === "subscription" ? "#F6E9FB" : "#FFFFFF" }}
      >
        Subscription
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      [theme.breakpoints.up("sm")]: {
        margin: "20px 0 10px 15px",
      }
    },
    btn: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#777E90",
      textTransform: "capitalize",
      borderColor: "#777E90",
      padding: 5,
      [theme.breakpoints.up("sm")]: {
        padding: "10px 15px",
      },
    },
    oneTimePaymentBtn: {
      borderRadius: "20px 0 0 20px",
    },
    subscriptionBtn: {
      borderRadius: "0 20px 20px 0",
      [theme.breakpoints.up("sm")]: {
        width: 170,
      },
    },
  })
);
