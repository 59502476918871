import { Message } from '../../../framework/src/Message';
import { runEngine } from '../../../framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';

interface Header {
  token: string;
  "Content-Type"?: string;
}

export const apiCall = async (data: any) => {
  const { method, endPoint, payload, contentType } = data;
  const token = localStorage.getItem("token") ?? "";
  const header: Header = { token };

  if (contentType) {
    header["Content-Type"] = contentType;
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  payload &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};