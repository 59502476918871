import React from "react";
// Customizable Area Start
import { Box, Typography, Switch, TextField, FormControl, Select, MenuItem, Divider, Button, InputAdornment, IconButton, ListItemText ,Radio, Popper, Paper} from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import ControlPoint from '@material-ui/icons/ControlPoint';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DatePicker from 'react-datepicker';
import CreateAchievementController, {
    Props
} from "./CreateAchievementController.web";
import  {editIcon, iconImg}  from "./assets";
import MaterialUIBox from "@material-ui/core/Box";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonSelectIconFromLibrary from "../../../blocks/imagemanagement2/src/CommonSelectIconFromLibrary.web";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import { Autocomplete } from '@material-ui/lab';
// Customizable Area End

export default class CreateAchievement extends CreateAchievementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderAutoComplete = () => {
        return (
            <FormControl variant="outlined" style={{ width: '100%' }}>
                <Autocomplete
                   className="autoComplete"
                    options={this.state.names.data}
                    getOptionLabel={(option) => option.attributes?.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select branch name"
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon style={{color: "grey"}}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    value={this.state.selectedUser}
                    onChange={(event, value) => this.handleTagUsers(value)}
                    PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
                    PaperComponent={(props) => <Paper {...props} elevation={3}/>}
                    renderOption={(option, { selected }) => (
                        <StyledMenuItem
                            key={option.attributes.id}
                            value={option.attributes.id}
                            selected={selected}
                        >
                            <PinkRadioButton checked={selected} />
                            <FolderOpenIcon />&nbsp;
                            <ListItemText primary={option.attributes.name}/>
                        </StyledMenuItem>
                    )}
                    getOptionSelected={(option, value) => option.attributes.id === value?.attributes.id}
                />
            </FormControl>
        )
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <Wrapper>
                    <div style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px"}}>
                    <Box className="headingContainer">
                        <Typography style={{fontSize: "22px", fontWeight: 600, color: "#1C1B1B"}}>General Information</Typography>
                        <Box style={{display: "none"}}>
                            <Typography style={{color: "#1C1B1B", fontSize: "16px" , fontWeight: 400}}>Status</Typography>
                            <Box className="switchContainer">
                                <span style={{ fontWeight: 600 , fontSize: "18px", color: "#484646"}} className="active">Active</span>
                                <Switch className="criteriaToggleBtn" checked={this.state.statusActive} style={{
                                    color: this.state.statusActive ? "#6C328B" : "#545650"
                                }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="infoContainer">
                        {this.state.iconPreview?.id ?
                        <>
                        <Box className="displayEditIconBox">
                            <img src={editIcon} alt="edit" className="editIcon" onClick={this.openAddIconDialogue} /> &nbsp;&nbsp;&nbsp;
                            <img src={`${this.state.iconPreview?.attributes?.image.url}`} alt="" className="selectedIcon" /> 
                        </Box>
                        </> 
                        :
                        <Box className="iconBox" data-test-id="SelectIcon" onClick={this.openAddIconDialogue}>
                            <ControlPoint style={{color: "#6C328B", fontSize: "32px"}}/>
                            <label htmlFor="icon-upload">
                                <Typography style={{color: "#545650" , fontSize: "16px", fontWeight: 600}}>Add Icon</Typography>
                            </label>
                        </Box>
                        }
                        <Box className="inputContainer">
                            <Box className="inputWrapper">
                                <Box>
                                    <FormFieldTitle>Achievement Name</FormFieldTitle>
                                    <TextField variant="outlined" className="achievementName" value={this.state.achievementName} placeholder="Type here" fullWidth style={{ marginTop: "3px" , backgroundColor: "#fcf6f6"}} onChange={this.handleAchievementName} data-test-id="achievementId"/>
                                    {this.state.achievementError && <p style={{color: "red" , margin: "10px 0px 0px 0px"}}>Maximum 30 characters allowed.</p>}         
                                </Box>
                                <Box>
                                    <FormFieldTitle>Owner</FormFieldTitle>
                                    <FormControl fullWidth style={{ marginTop: "3px"}}>
                                            <Select
                                                variant="outlined"
                                                className="ownerSelect"
                                                value={this.state.ownerId}
                                                onChange={this.handleOwnerChange}
                                                displayEmpty
                                                data-test-id="ownerId"
                                                inputProps={{
                                                    IconComponent: () => <ExpandMoreIcon style={{ color: "grey", marginRight: "20px" }} />,
                                                }}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    },
                                                    PaperProps: {
                                                        style: {
                                                            width: "452px",
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    <span style={{ color: "grey" }}>Select a user</span>
                                                </MenuItem>
                                                {this.state.ownerNameList?.data?.map((item) => (
                                                    <StyledMenuitem key={item.id} value={item.id}>
                                                        {item.attributes.first_name} {item.attributes.last_name}
                                                    </StyledMenuitem>
                                                ))}
                                            </Select>
                                    </FormControl>
                                    {this.state.ownerError && <p style={{color: "red" , margin: "10px 0px 0px 0px"}}>Please select the user.</p>}  
                                </Box>
                            </Box>
                            <Box className="assigneesWrapper">
                                <Box style={{ flex: "1" }}>
                                    <FormFieldTitle>Assignees</FormFieldTitle>
                                    {this.renderAutoComplete()}
                                    {this.state.assignesError && <p style={{color: "red" , margin: "10px 0px 0px 0px"}}>Please select the branch.</p>} 
                                </Box>
                                <Box style={{ display: "flex", gap: "10px", minWidth: "30%" , flexFlow: "wrap"}}>
                                    <Box>
                                        <Box 
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            <FormFieldTitle>Due Date</FormFieldTitle>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                border="1px dashed #ccc"
                                                borderRadius="10px"
                                                padding="12px"
                                                bgcolor="#f9f6f6"
                                                marginTop="3px"
                                            >
                                                <label htmlFor="dateInput">
                                                <img src={iconImg} style={{ marginRight: '10px' , width: "28px", height: "28px"}}/>
                                                </label>
                                                <DatePicker
                                                    dateFormat="MM-dd-yyyy"
                                                    data-test-id="duedateId"
                                                    selected={this.state.dueDate}
                                                    id="dateInput"
                                                    onChange={this.handleDueDate}
                                                    placeholderText="Due Date"
                                                    minDate={new Date()}
                                                    customInput={
                                                        <TextField
                                                            name="dateInput"
                                                            id="dateInput"
                                                            size="small"
                                                            placeholder="Due Date"
                                                            fullWidth
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            {this.state.dueDateError && <p style={{color: "red" , margin: "10px 0px 0px 0px"}}>Please select the due date.</p>}
                                        </Box>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box>
                                        <FormFieldTitle>Status</FormFieldTitle>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select
                                                    className="statusInput"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    data-test-id="statusId"
                                                    value={this.state.status}
                                                    onChange={this.handleStatusChange}
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        },
                                                        PaperProps: {
                                                            style: {
                                                                width: "130px"
                                                            }
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                    }}
                                                        inputProps={{
                                                            IconComponent: () => <ExpandMoreIcon style={{ color: "grey", marginRight: "10px" }} />,
}}
                                                >
                                                    <MenuItem value={"Active"}>Active</MenuItem>
                                                    <MenuItem value={"Not Active"}>Inactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    </div>
                    <Box className="footerContainer">
                        <Box className="criteriaContainer">
                            <Typography style={{color: "1C1B1B", fontSize: "22px", fontWeight: 600, fontFamily: "Poppins"}}>Criteria</Typography>
                            <Divider style={{marginBottom: "10px"}}/>
                            <Box className="toggleContainer">
                                <Box className="criteriaToggle"><FormFieldTitle data-test-id="completeid">Complete content</FormFieldTitle><Switch checked={this.state.contentToggle} className="criteriaToggleBtn" onClick={this.handleContent} data-test-id="contentId" style={{
                                    color: this.state.contentToggle ? "#6C328B" : "#545650",
                                }} 
                                /></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of days logged in</FormFieldTitle><Switch checked={this.state.daysLoggedInToggle}className="criteriaToggleBtn" style={{
                                    color: this.state.daysLoggedInToggle ? "#6C328B" : "#545650",
                                }} onChange={this.handleDaysLoggedInToggle} data-test-id="dayslogId"/></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of lessons completed</FormFieldTitle><Switch checked={this.state.lessonsCompletedToggle}  className="criteriaToggleBtn" style={{
                                    color: this.state.lessonsCompletedToggle ? "#6C328B" : "#545650",
                                }} onChange={this.handleLessonsCompletedToggle} data-test-id="lessontoggleid"/></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of courses completed</FormFieldTitle><Switch checked={this.state.coursesCompletedToggle} className="criteriaToggleBtn" style={{
                                    color: this.state.coursesCompletedToggle ? "#6C328B" : "#545650",
                                }}  onChange={this.handleCoursesCompletedToggle} data-test-id="coursecompleteid"/></Box>
                                <Box className="criteriaToggle"><FormFieldTitle>Number of days logged in a row</FormFieldTitle><Switch checked={this.state.loggedInRowToggle} className="criteriaToggleBtn" style={{
                                    color: this.state.loggedInRowToggle ? "#6C328B" : "#545650",
                                }}  onChange={this.handleLoggedInRowToggle} data-test-id="rowtoggleid"/></Box>
                            </Box>
                        </Box>
                        <Box className="contentWrapper">
                            <Button variant="contained" className={!this.state.contentToggle ?"selectContentButton" : "selectContentButtonActive"} onClick={this.handleSelectContent} disabled={!this.state.contentToggle} data-test-id="selectContentid">
                                {
                                    this.state.courseIdData.length > 0 || this.state.courseIdData.length > 0 ?
                                        <span>
                                            Change Content
                                        </span>
                                        :
                                        <span>
                                            Select content
                                        </span>
                                }
                            </Button>
                            <Box className="selectContainer">
                                <Box>
                                    <DaysTitle>Number of days logged in</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.daysLoggedInToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("daysLoggedInInput")} data-test-id="incrementIdDays">
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("daysLoggedInInput")}>
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.daysLoggedInToggle ? "selectInput" : "disabledSelectInput"} value={this.state.daysLoggedIn === 0 ? "" : this.state.daysLoggedIn} placeholder="Type here..." fullWidth onChange={this.handleDaysLoggedin} data-test-id="daysInputId"/>
                                </Box>
                                <Box>
                                    <DaysTitle>Number of lessons completed</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.lessonsCompletedToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("lessonsCompletedInput")}>
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("lessonsCompletedInput")}>
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.lessonsCompletedToggle ? "selectInput" : "disabledSelectInput"} value={this.state.lessonsCompleted === 0 ? "" : this.state.lessonsCompleted} placeholder="Type here..." fullWidth onChange={this.handleLessonsCompleted} data-test-id="lessoninputid"/>
                                </Box>
                                <Box>
                                    <DaysTitle>Number of courses completed</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.coursesCompletedToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton  size="small" className="numberIcon" onClick={() => this.handleIncrement("coursesCompletedInput")}>
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton  size="small" className="numberIcon" onClick={() => this.handleDecrement("coursesCompletedInput")}>
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.coursesCompletedToggle ? "selectInput" : "disabledSelectInput"} value={this.state.coursesCompleted === 0 ? "" :this.state.coursesCompleted } placeholder="Type here..." fullWidth onChange={this.handleCoursesCompleted} data-test-id="courseinputid"/>
                                </Box>
                                <Box>
                                    <DaysTitle>Number of days logged in, in a row</DaysTitle>
                                    <TextField
                                        variant="outlined"
                                        disabled={!this.state.loggedInRowToggle}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment className="updownArrowBox" position="end">
                                                    <IconButton size="small" className="numberIcon" onClick={() => this.handleIncrement("loggedInRowInput")}>
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" className="numberIcon" onClick={() => this.handleDecrement("loggedInRowInput")}>
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} className={this.state.loggedInRowToggle ? "selectInput" : "disabledSelectInput"} value={this.state.loggedInRow === 0 ? "" : this.state.loggedInRow} placeholder="Type here..." fullWidth onChange={this.handleLoggedInRow} data-test-id="rowinputid"/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="createAchievementContainer">
                          <Button variant="contained" className={!this.updateStateWithConditions() ? "createAchievementBtn" : "selectContentButtonActive"} onClick={this.postCreateAchievement} data-test-id="createAchievementId" disabled={!this.updateStateWithConditions()}>Create achievement
                          </Button>
                    </Box>
                </Wrapper>
                {this.state.showChooseIconPrompt &&
                    <DeleteModal
                    data-test-id='option-prompt'
                    openDialog={this.state.showChooseIconPrompt}
                    headingText={`Where do you want to select the icon from?`}
                    btnOkText="Icon library"
                    btnCancelText="Local storage"
                    handleCancel={this.closeAddIconDialogue}
                    handleOk={() => this.openIconLibrary()}
                    promptOption={true}
                    handleOptionOne={() => this.handleAddIconFromLocal()}
                  />
                }
                {this.state.showIconListingDialogue &&
                 <CommonSelectIconFromLibrary
                    data-test-id="choose-icon-from-prompt"
                    open={this.state.showChooseIconPrompt}
                    CloseModal={this.closeAddIconDialogue}
                    id="id"
                    navigation={this.props.navigation}
                    onClose={this.handleAddIconFromIconListDialogue}
                 />
                }
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const Wrapper = styled(Box)({
    padding: "20px",
    "& .autoComplete .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
        paddingRight: "15px !important",
        backgroundColor: "rgb(252, 246, 246)",
        borderRadius: "8px"
    },
    "& .headingContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
    },
    "& .infoContainer": {
        display: "flex",
        padding: "10px 20px",
        gap:"40px",
        placeContent: "space-between",
        "@media screen and (max-width: 768px)" : {
            display:"grid",
            placeItems:"center",
    },
    "@media screen and (max-width: 420px)" : {
        display:"grid",
        placeItems:"baseline",
    }
    },
    "& .inputContainer": {
        display: "grid",
        gap: "10px",
        flex: 1
    },
    "& .inputWrapper": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        "@media screen and (max-width: 992px)" : {
                gridTemplateColumns:"1fr"
        }
    },
    "& .assigneesWrapper": {
        display: 'flex',
        gap: "10px",
        "@media screen and (max-width: 992px)" : {
            display:"grid",
            gridTemplateColumns:"1fr",
    },
    "@media (max-width : 400px)" : {
        "& .MuiDivider-vertical":{
            width: "0px"
        }
    }
    },
    "& .iconBox": {
        display: "flex",
        color: "#C7AFD2",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #6C328B",
        boxSizing: "border-box",
        height: "198px",
        width:"250px",
        borderRadius: "8px",
        marginBottom:"20px",
        cursor: "pointer"
    },
    "& .displayEditIconBox": {
        display: "flex",
        color: "#C7AFD2",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "198px",
        width:"250px",
        padding: "10px"
    },
    "& .editIcon": {
        width: '24px',
        height: '24px',
    },
    "& .selectedIcon": {
        width: '120px',
        height: '120px',
        borderRadius: "12px"
    },
    "& .footerContainer": {
        marginTop: "40px",
        display: "flex",
        gap: "30px",
        "@media screen and (max-width: 768px)" : {
           display:"grid",
           gridTemplateColumns:"1fr"

        }

    },
    "& .criteriaContainer": {
        minWidth: "40%",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
        height: "260px"
    },
    "& .criteriaToggle": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .criteriaToggleBtn": {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: '#6C328B',
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'var(--sys-color-PrimaryContainer, #EEE8F2)'
            },
        },
    },
    "& .contentWrapper": {
        flex: 1,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 4px",
        padding: "20px",
        borderRadius: "10px",
    },
    "& .selectContainer": {
        marginTop: "20px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
    },
    "& .selectContentButton": {
        background: "#E5E2E1",
        borderRadius: "12px",
        padding: "10px 40px",
        color: "#929090",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600
    },
    "& .selectContentButtonActive": {
        background: "#652786",
        padding: "10px 40px",
        color: "white",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "16px"
    },
    "& .selectInput": {
        borderRadius: "8px",
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
          borderRadius: "8px",
        },
        "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
      }
    },
    "& .disabledSelectInput": {
        background: "#E4E2E1",
        borderRadius: "8px",
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
            borderRadius: "8px",
        }     
    },
    "& .achievementName": {
        background: "#FCF8F8",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        }
    },
    "& .ownerSelect": {
        background: "#FDF8F8",
        backgroundColor: "#fcf6f6",
        borderRadius: "8px"
    },
    "& .assigneeInput": {
        background: "#FDF8F8",
        display: "inline-flex",
        alignItems: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        backgroundColor: "#fcf6f6",
        borderRadius: "8px"
    },
    "& .dateInput": {
        background: "#FDF8F8",
        border: "2px dashed gray",
        boxSizing: "border-box",
        borderRadius: "8px",
    },
    "& .createAchievementContainer": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
    },
    "& .createAchievementBtn": {
        textTransform: "none",
        color: "#929090",
        background: "#E5E2E1",
        padding: "10px 60px",
        borderRadius: "16px",
    },
    "& .updownArrowBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    "& .numberIcon": {
        padding: "0",
        color: "gray",
    },
    "& .statusInput": {
        borderRadius: "27px",
        background: "#EEE8F2",
        color: "#652786",
        border: "0px",
        fotnWeight: 400,
        fontSize: "16px"
    },
    "& .selectedAssignee" : {
        maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" 
    },
    "& .statusInput .MuiOutlinedInput-notchedOutline" : {
        borderColor: "transparent !important"
      }
});

const FormFieldTitle = styled(Typography)({
    color: "1C1B1B",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins !important"
});

const DaysTitle = styled(Typography)({
    color: "#787776",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins !important"
});

const StyledDropdownClickBox = styled('div')({
    marginTop:"6px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    fontSize: "16px",
    padding: "14px 16px !important",
    border: "1px solid #78777682",
    borderRadius: "8px",
    backgroundColor: "#fcf6f6",
    cursor: "pointer",
    position:"relative",
    "&.selectCommonStyle .contentContainer":{
      display: "flex",
      width: "100%",
      gap: "3px",
    },
    "&.selectActive":{
      borderColor: "#652786",
      backgroundColor: "#EEE8F2"
    },
    "&.selectActive .title":{
      color: "#652786"
    },
   "&.creationDate":{
    display: "flex",
    justifyContent: "space-between"
   },
   "& .contentRole ":{
     display: "flex !important",
    width: "100% !important",
   justifyContent: "space-between !important"
   },
    "& .innerContentBox":{
      display: "flex",
      gap: "3px",
      fontSize:"16px",
      fontWeight:500,
      color:'#ADAAAA'
    },
    "& .title":{
      whiteSpace: "nowrap",
      fontSize: "16px",
      color: "#484646",
      fontWeight: 400,
      fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle":{
      color: "#652786"
    }
  });

  const DropdownContent = styled(MaterialUIBox)({
    position:"absolute",
    boxSizing:"border-box",
    top:"60px",
    zIndex:9999,
    background:"#fff",
    width:"100%",
    padding: "8px 16px",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius:"12px",
    "& .checkboxList": {
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginBottom: "16px"
    },
    "& .checkboxContent": {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid lightgray",
      fontFamily: "Poppins , sans-serif",
      textTransform: "capitalize",
    }, 
     "& .MuiTypography-body1":{
      fontFamily:"Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      color: "#1C1B1B"
    },
    "& .selectedStyle": {
      backgroundColor: "#EEE8F2",
      borderBottom: "1px solid transparent !important",
      borderRadius: "8px",
    },
    "& .selectedStyle .MuiTypography-body1":{
      color: "#542070 !important"
    },
    "& .checkboxContent:hover": {
      backgroundColor: "#EEE8F2",
      borderBottom: "1px solid transparent",
      borderRadius: "8px",
    },
    "& .applyBtn": {
      display: "block",
      margin: "auto",
      color: "#542070",
      textTransform: "capitalize",
      fontSize: "16px"
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
      borderBottom: "1px solid lightgray",
      fontFamily:"Poppins, sans-serif"
    },
    "& .react-calendar":{
      border: "unset",
      fontWeight: 500,
      fontSize: "14px"
    },
    "& .react-calendar abbr":{
      textDecoration: "none"
    },
    "& .react-calendar__month-view__days__day":{
      width: "31px",
      height: "31px",
      borderRadius: "50%",
      fontWeight: 400,
      fontSize: "14px !important"
    },
    "& .react-calendar__month-view__weekdays":{
      fontWeight: 400,
      fontSize: "10px !important"
    },
    "& .react-calendar__tile--now:enabled":{
      backgroundColor: "#652786",
      color: "#fff"
    },
    "& .react-calendar__tile--now:enabled:hover":{
      backgroundColor: "#652786"
    },
    "& .react-calendar__month-view__days__day--weekend": {
      color: "rgba(0, 0, 0, 0.87)"
    },
    "& .react-calendar__month-view__days__day--neighboringMonth":{
      color: "#757575"
    },
    "& .react-calendar__tile--active":{
      color: "#fff",
      backgroundColor: "#652786 !important"
    },
    "& .react-calendar__year-view__months__month":{
      width: "74px",
      height: "74px",
      borderRadius:"50%"
    },
    "& .react-calendar__navigation__prev2-button":{
      display: "none"
    },
    "& .react-calendar__navigation__next2-button":{
      display: "none"
    },
    "& .react-calendar__navigation button:enabled:hover":{
      backgroundColor: "unset"
    },
    "& .react-calendar__navigation button":{
      minWidth: "auto"
    },
    "& .calendarBtnContainer":{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      marginTop: "16px"
    }
  });

  const StyledMenuitem = styled(MenuItem)({
    "&:hover": {
        background: "#EEE8F2",
    },
    "&.Mui-selected": {
        background: "#EEE8F2 !important",
    }
});

const StyledMenuItem = styled(MenuItem)({
    width: "100%"
});

const PinkRadioButton = styled(Radio)({
    color: '#673ab7',
    '&.Mui-checked': {
        color: '#673ab7',
    }
});

// Customizable Area End