// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface SubBranch {
  attributes: {
  id: number;
  name: string;
  branch_id: string;
  branch_status: string;
  is_primary_branch: boolean;
  teams_counts: number;
  manager: string;
  active_users: number;
  total_users: number;
  payment_status: string;
  sub_branches: SubBranch[];
}
}

export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    profession: string,
    full_name:string,
    avatar: null,
    is_admin: boolean,
  }
}

export interface CheckboxSelectedListTypes { value: string, id: string }

export interface AttributesType {
  id: number;
  name: string
  uniq_id: string;
  lessons_count: number;
  status: string;
  updated_at: string;
  is_selected: boolean;
}
export interface ContentRolesType {
  id: string,
  type: string,
  attributes: AttributesType;
}


export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}

interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}
export interface AdminCatagoryListing {
  id: string,
  type: string,
  attributes: {
    name: string,
  }
}

export interface UserBranchesListing{
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
  }
}

export interface AdminContentRoles {
  id: string;
     type: string;
     attributes:
      { id: number;
  uniq_id:string; 
  name:string; 
  title: string;
  description:string; 
  status:string;
  is_sellable: boolean;
  price: string;
  expert_id: number;
  expert_type: string;
  created_at: string;
  subscription_period: string;
  is_selected: boolean;
   thumbnail_image: null;
   };
   }

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  anchorTeamFilter:boolean;
  AdvanceSearchValue:string;
  filteredContentData:Array<string>;
  showContentLists:boolean;
  placeholderSelectItems:string;
  branchData : Array<SubBranch>;
  isExpanded: boolean,
  expandedRows: { [key: string]: boolean },
  selectedStatus: string;
  catagoryParams:string;
  pagination: Pagination;
  anchorFilterContainerEl: null | HTMLElement,
  filterItems: boolean;
  isCalendarOpen: boolean;
  placeholderParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  contentRolesParams: string;
  creationDate: string;
  anchorsEl: null | HTMLElement;
  dropdownType: string ;
  categorySearchText: string;
  contentRoleTrue:boolean;
  contentRolesData:Array<ContentRolesType>;
  contentRoleSelectedItems: Array<CheckboxSelectedListTypes>;
  contentRoleSearchText: string;
  categorySelectedItems: Array<CheckboxSelectedListTypes>;
  adminCatagoryList: Array<AdminCatagoryListing>;
  adminContentRolesList: Array<AdminContentRoles>;
  searchList:boolean;
  placeholderSearchBranchText:string;
  expertAdminsContent:Array<ExpertListing>;
  isCalendarshow:boolean;
  placeholderSearchsTexts:string;
  placeholdersParams: string;
  placeholderSelectsTeamsItems:Array<CheckboxSelectedListTypes>
  placeholderSelectedItems: Array<CheckboxSelectedListTypes>;
  creationIconsDate:string;
  creationDateParamsdatas: {
  start_date: string;
  end_date: string;
};
filterItemsDatas:boolean;

  
}

interface SS {
  id: any;
}

export default class ShowBranchController extends BlockComponent<
  Props,
  S,
  SS
> {

  getTeamCollectionCallId:string="";
  getAdvanceSearchApiCallIds:string="";
  getDeleteApiCallIds:string="";
  getBranchApiCallid : string = "";
  expertDataApiCallIds:string="";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchorTeamFilter:false,
      filteredContentData:[],
      AdvanceSearchValue:"",
      showContentLists:false,
      placeholderSelectItems:"",
      branchData:[],
      isExpanded: false,
      expandedRows: {},
      selectedStatus: "",
      catagoryParams:"",
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      anchorFilterContainerEl: null,
      filterItems: true,
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      contentRolesParams: "",
      creationDate: "",
      anchorsEl: null,
      dropdownType: "",
      categorySearchText: "",
      contentRoleTrue:false,
      contentRolesData:[],
      contentRoleSelectedItems:[],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      placeholderSelectedItems: [],
      adminCatagoryList: [],
      adminContentRolesList: [],
      searchList:false,
      placeholderSearchBranchText:"",
      expertAdminsContent:[],
      isCalendarshow:false,
      placeholderSearchsTexts:"",
      placeholdersParams: "",
      placeholderSelectsTeamsItems:[],
      creationIconsDate:"",
      creationDateParamsdatas: {
        start_date: "",
        end_date: ""
      },
      filterItemsDatas:false,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
   
    if (apiRequestCallId === this.getAdvanceSearchApiCallIds) {
      this.setState({ filteredContentData: responseJson.data })
    }
    if(apiRequestCallId === this.getBranchApiCallid){
      this.getBranchSuccessCallBack(responseJson.data)
    }else if (apiRequestCallId === this.expertDataApiCallIds) {
      this.setState({ expertAdminsContent: responseJson.data })
    }
    
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getBranchApiCall();
    this.getExpertSearchListNews();
  }

  getAdvancedSearchLists=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdvanceSearchApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.advanceSearchApiEndPoint}?type=branch&search=${this.state.AdvanceSearchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
  }
  handleCreateBranch=()=>{
    this.props.navigation.navigate("CreateBranch", {
      type: "Create"
    });
  }
  handleThumbnailListClick=(item:string)=>{
    this.setState({
      searchList:true,
      AdvanceSearchValue: item,
      showContentLists: false
    },()=>{
      this.getBranchApiCall()
    })
   }
  handleTeamInputChangeSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({AdvanceSearchValue:event.target.value},() => {
      this.getAdvancedSearchLists()
      if (this.state.AdvanceSearchValue == "") {
        this.getBranchApiCall()
      }
    })
      this.setState({searchList:true,showContentLists:event.target.value !== ""},()=>{}) 
     }

     handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
      this.setState({
         filterItemsDatas: !this.state. filterItemsDatas,
         anchorFilterContainerEl: event.currentTarget,
      })
    };

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": contentType,
      token,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getBranchApiCall = async () => {
    this.getBranchApiCallid = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getMethod,
      endPoint: `${configJSON.getBranchDetailsEndPoint}?${this.ConstructEndPoints()}`
    });
  };
   
  getBranchSuccessCallBack = (response: Array<SubBranch>) => {
    this.setState({branchData: response});
  };

  toggleExpandAll = () => {
    const { isExpanded, branchData } = this.state;
    const newExpandedState = !isExpanded;
    const expandedRows: { [key: string]: boolean } = {};
    const expandCollapseRows = (data: SubBranch[], state: boolean, parentIndex: string | number = '') => {
      data.forEach((branch, index) => {
        const currentIndex = parentIndex ? `${parentIndex}-${index}` : `${index}`;
        expandedRows[currentIndex] = state;
        if (branch.attributes?.sub_branches?.length > 0) {
          expandCollapseRows(branch.attributes.sub_branches, state, currentIndex);
        }
      });
    };
    expandCollapseRows(branchData, newExpandedState);
    this.setState({
      isExpanded: newExpandedState,
      expandedRows,
    });
  };

  toggleRow = (index: string) => {
    this.setState((prevState) => ({
      expandedRows: {
        ...prevState.expandedRows,
        [index]: !prevState.expandedRows[index],
      },
    }));
  };
 
  getColor = (status: string): string => {
    switch (status) {
      case 'Paid':
        return 'paid';
      case 'Pending':
        return 'pending';
      case 'Free':
        return 'free';
      default:
        return '';
    }
  };

  ConstructEndPoints = () => {
    let params = [];
    if (this.state.AdvanceSearchValue && this.state.searchList) {
      params.push(this.searchValueParams());
    }
    if(this.state.placeholderParams){
      params.push(this.addplaceholdersParams())
    }
    if(this.state.selectedStatus){
      params.push(this.addStatusParam())
    }
   

    params = params.filter(param => param !== '');
    return params.length > 0 ? params.join('&') : '';
   };

   private searchValueParams(): string{
    return `search=${this.state.AdvanceSearchValue ? encodeURIComponent(this.state.AdvanceSearchValue):""}`;
   }

   private addplaceholdersParams(): string {
    return this.state.placeholderParams ? `&${this.state.placeholderParams}` : '';
   }
   private addStatusParam(): string {
    return this.state.selectedStatus ? `payment_status=${this.state.selectedStatus}` : '';
  }

  handleClicksFilters = () => {
    this.setState({
      anchorFilterContainerEl: null,
      placeholderSearchsTexts:"",
      placeholderSearchBranchText:""
    }, () => {
      this.getBranchApiCall();
    })
  }

  placeholderCheckboxAssignedExpertChangeHandles = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectedItems: [...prevState.placeholderSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `manager_id=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectedItems: prevState.placeholderSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `manager_id=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    }
  }


  creationChangeHandlerDates = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationIconsDate: event.target.value
    })
  }


  dropdownHandlerOpens = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorsEl: event.currentTarget,
      dropdownType
    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorsEl: null,
      isCalendarshow: false,
      dropdownType: "",
    })
  }

  handleCatalogueChangeStatus = (status: string) => {
    this.setState({
      selectedStatus: status,
      anchorsEl: null,
      dropdownType: ""
    })
  }

  handleClearFilters=()=>{
    this.setState({
    anchorFilterContainerEl: null,
    selectedStatus: "",
    placeholdersParams: "",
    placeholderParams: "",
    placeholderSelectsTeamsItems:[],
    placeholderSelectedItems:[],
    creationIconsDate:"",
    creationDateParamsdatas: {
      start_date: "",
      end_date: ""
    },
    isCalendarshow:false,
    placeholderSearchsTexts:"",
    placeholderSearchBranchText:"",
    anchorsEl: null ,
    dropdownType: "",
      }, () => {
        this.getBranchApiCall()
      })
  }
  handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      filterItems: !this.state.filterItems,
      anchorFilterContainerEl: event.currentTarget,
    })
  }

  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContainerEl: null
    })
  }

  handlePlaceholderAssignedSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchsTexts: event.target.value
    })
  }

  getExpertSearchListNews = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertDataApiCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_library2/experts?search=${this.state.placeholderSearchsTexts}`
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToBranchDetails = (branchId: number) => {
    this.props.navigation.navigate("BranchDetails", {id: branchId})
  }
}

// Customizable Area End
