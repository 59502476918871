import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CalendarIcon } from "./assets";

interface IDateEst {
  dateStr?: string;
}

export default function DateEST({ dateStr }: IDateEst) {
  const classes = useStyles();

  if (!dateStr) return null;

  const date = new Date(dateStr);

  // Convert to EST time zone
  const estOffset = -5 * 60; // EST offset is UTC-5
  const estTime = new Date(date.getTime() + estOffset * 60 * 1000);

  // Format the date string
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = estTime.getDate();
  const monthIndex = estTime.getMonth();
  const year = estTime.getFullYear();
  const hours = estTime.getHours();
  const minutes = estTime.getMinutes();

  return (
    <div className={classes.dateContainer}>
      <img src={CalendarIcon} alt="Reward earned date" />
      <div className={classes.dateTexts}>
        <p className={classes.dateText}>{`${day} ${
          monthNames[monthIndex]
        } ${year}`}</p>
        <p className={classes.dateText}>{`${hours}:${minutes} h EST`}</p>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dateTexts: {
      marginLeft: 10,
    },
    dateText: {
      margin: "5px 0",
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "129%",
      textAlign: "center",
      color: "#5C5F62",
    },
  })
);