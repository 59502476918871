import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {icon_upload ,upload_error } from "./assets";
import { createTheme ,styled} from "@material-ui/core/styles";
import { Formik } from "formik";
import DragAndDropFileUploadWithProgress from "../../../components/src/DragAndDropFileUploadWithProgress"
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ThumbnailmanagementController, {
  Props,
  validationSchema
} from "./ThumbnailmanagementController.web"

export default class Addthumbnail extends ThumbnailmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  saveThumbnailButton = (isDisable: string | false | null ) => {
    return (
      <>
        <Button data-test-id="thumbnailButton" style={isDisable?{...webStyle.btn,...webStyle.enableBTn}:{...webStyle.btn}} disabled={isDisable?false:true} onClick={this.openThumbnailPrompt}>
          Save Thumbnail
        </Button>
      </>
    )
  }

  // Customizable Area End

  render() {
    const initialFormikState = {
      thumbnail: this.state.editId ? this.state.getThumbnailDetails?.attributes?.title  : ""
    }
    return (
      // Customizable Area Start
      <>

          <Box>
            <Formik
              data-test-id="createContentForm"
              initialValues={initialFormikState}
              enableReinitialize={true}
              onSubmit={(values) => { this.handleUploadClick(values) }}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleSubmit, touched, errors }) => {
                const isDisable=this.state.attachedFile && this.state.attachedFile !== null  && values.thumbnail
                return (
                  <>
                  <InputWrapper>
                    <div style={{ width: '100%' }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        padding: "24px",
                        boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.17)",
                        borderRadius: "18px"
                      }}>
                        <Box>
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                              Thumbnail Name*
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="thumbnail"
                              placeholder="Enter title"
                              value={values.thumbnail}
                              onChange={handleChange}
                              className="Input_wrap"
                            />
                          </Box>
                          {touched.thumbnail && errors.thumbnail && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.thumbnail}</Typography>
                          )}
                          <Grid>
                            <DragAndDropFileUploadWithProgress
                              isThumbnail 
                              selectedThumbnailFile={this.SelectedFileDeleted}
                              data-test-id="file_upload_id"
                              type={this.state.FileType}
                              image={icon_upload}
                              errorImage={upload_error}
                              onSelectFile={this.onSelectFile}
                              selectedFile={this.state.attachedFile}
                              progress={this.state.uploadFileProgress}
                              uploadStatus={this.state.uploadFileStatus}
                              uploadingMessage={"This can take a few minutes depending on the file size and connection speed"}
                            />
                          </Grid>

                        </Box>

                      </div>
                    </div>
                  </InputWrapper>
                  <Box style={webStyle.upload_btn}>
                  { this.state.editId ?
                    this.saveThumbnailButton(isDisable)
                  :
                  <Button data-test-id="thumbnailButton" style={isDisable?{...webStyle.btn,...webStyle.enableBTn}:{...webStyle.btn}} disabled={isDisable?false:true} onClick={()=>handleSubmit()}>
                    Upload Thumbnail
                  </Button>
                  }
                </Box>

                <DeleteModal
                  data-test-id="save-add-content-modal"
                  openDialog={this.state.saveThumbnailPrompt}
                  headingText={"Are you sure you want to save your edits?"}
                  subHeading={"This will affect any objects that use this thumbnail"}
                  btnCancelText={"Cancel"}
                  btnOkText={"Save"}
                  handleCancel={this.handleCancelThumbnail}
                  handleOk={() => handleSubmit()} 
                />
                </>
                  )
              }}
   
            </Formik>
         
          </Box>

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const InputWrapper=styled(Box)({
  "& .Input_wrap":{
    margin: "8px 0px",
    border: "1px solid #E5E2E1",
    width: "100%",
    borderRadius: "8px",
    padding: "16px 20px 16px 24px",
    backgroundColor: "rgb(255, 247, 255)",
    color: "#000",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    "&::placeholder":{
      color: "#ADAAAA !important", 
  },"&:focus":{
    border: "1px solid #E5E2E1",
    outline: "none",
    backgroundColor:"white"
  }
  }
})

const webStyle = {

 

  labelStyle: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1C1B1B"
  },

  upload_btn: {
    display: "flex",
    justifyContent: "flex-end"
  },
  btn: {
    marginTop: "20px",
    width: "300px",
    height: "53px",
    backgroundColor: "#E5E2E1",
    border: "0px",
    borderRadius: "16px",
    color: "#787776",
    fontFamily: "'Poppins', sans-serif" as const,
    fontSize: "18px",
    fontWeight: 600 as const,
    textTransform: "capitalize" as const
  },
  enableBTn:{
    backgroundColor:"#6C328B",
    color:"#FFFFFF"
  }


};
// Customizable Area End
