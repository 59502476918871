import React, { useEffect, useRef } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ReferBanner, CopyIcon } from "./assets";

export default function ReferScreen() {
  const classes = useStyles();
  const imgElementRef = useRef<HTMLImageElement>(null);

  // Focus the banner every time this screen renders
  useEffect(() => {
    const { current: imgElement } = imgElementRef;
    if (imgElement !== null) {
      imgElement.focus();
    }
  }, []);

  return (
    <Box className={classes.referContainer}>
      <img
        src={ReferBanner}
        className={classes.banner}
        alt="banner for refer a friend"
        ref={imgElementRef}
        tabIndex={-1}
      />
      <Typography className={classes.title}>
        Xpand the life of Others
      </Typography>
      <Typography className={classes.description}>
        Invite your friends and family to Xpand and Let them Xpand their life
      </Typography>
      <Box className={classes.referCodeContainer}>
        <Typography className={classes.referText}>
          Your Referal Code: DFFDRAHHWV
        </Typography>
        <IconButton className={classes.copyBtn}>
          <img src={CopyIcon} className={classes.copyIcon} alt="copy icon" />
        </IconButton>
      </Box>
      <Typography className={classes.tapText}>
        “Tap your code to copy”
      </Typography>
      <form>
        <Paper className={classes.paper}>
          <TextField
            className={classes.textField}
            id="name"
            label="Name"
            fullWidth
          />
          <TextField
            className={classes.textField}
            id="contact"
            label="Contact Number"
            fullWidth
          />
        </Paper>
        <Button className={classes.addContactBtn}>
          <ControlPointIcon />
          <span className={classes.addContactText}>Add one more contact</span>
        </Button>
        <br />
        <Button variant="outlined" fullWidth className={classes.inviteBtn}>
          Invite
        </Button>
      </form>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
        [theme.breakpoints.up("sm")]: {
          borderRadius: "24px",
        },
      },
    },
    referContainer: {
      textAlign: "center",
    },
    banner: {
      margin: "40px 0 50px 10px",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    title: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "29px",
      color: "#1A1A1A",
      margin: "20px 0",
    },
    description: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#1A1A1A",
    },
    referCodeContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0 0 0",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
    },
    referText: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "18px",
      color: "#6C328B",
    },
    copyBtn: {
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: "10px",
      },
    },
    copyIcon: {
      width: "auto",
    },
    tapText: {
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "18px",
      textAlign: "center",
      color: "#1A1A1A",
      mixBlendMode: "normal",
      opacity: "0.81",
    },
    paper: {
      background: "#FAFAFA",
      boxShadow: "0px 18px 18px -13px rgba(0, 0, 0, 0.137702)",
      borderRadius: "10px",
      padding: "30px",
      marginTop: 30,
    },
    textField: {
      "& .MuiInput-underline:before": {
        borderBottom: "1px solid #979797",
        mixBlendMode: "normal",
        opacity: "0.53",
      },
      "& .MuiInput-underline": {
        border: "none",
      },
    },
    addContactBtn: {
      color: "#6C328B",
      margin: "30px 0",
    },
    addContactText: {
      marginLeft: 10,
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "18px",
      textTransform: "none",
    },
    inviteBtn: {
      maxWidth: "300px",
      background: "#FF8B3F",
      boxShadow: "0px 6px 12px rgba(255, 139, 63, 0.733159)",
      borderRadius: "24px",
      color: "white",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "16px",
      padding: "20px",
      border: "1px solid #FF8B3F",
      "&:hover": {
        background: "#FF8B3F",
      },
    },
  })
);
