import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MaterialUIBox from '@material-ui/core/Box';
import {ExpandMore,ExpandLess } from "@material-ui/icons";
import { createTheme ,styled,withStyles} from "@material-ui/core/styles";
import { Formik } from "formik";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {FindUser,rightArrows,SearchIc} from "./assets"
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CreateTeamController, {
  Props,
  validationSchema
} from "./CreateTeamController.web"

export default class CreateTeam extends CreateTeamController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  initialFormikState = {
    TeamName: "",
    expert_id:""
  }
  renderPlaceholderDropdownContents(allExperts:any,values:any) {
    return <DropdownContent >
      <MaterialUIBox className="checkboxList">
        {allExperts && allExperts?.map((option:any) => {
          return (
            <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={values.expert_id.toString()}
            onChange={this.handleRadioChange}
            data-test-id={`radio_select${option.id}`}
            >
           <FormControlLabel className={values.expert_id.toString()===option.id.toString()? "checkboxContent selectedStyle" : "checkboxContent"}  value={option.id} control={<Radio/>} label={option.attributes.full_name} />
           </RadioGroup>
          )
        })}
        <Box className="apply_btn">
           <span onClick={this.handleApplyBtn}>
           Apply
            </span>
        </Box>
      </MaterialUIBox>
    </DropdownContent>
  }
  renderAssignedExpert(
    values:any,
    touched:any,
    error: any,
    handleChange: (event: React.ChangeEvent<{}>) => void,
  ) {
    return (
      <FormControl variant="standard" className="customInput" style={Boxwidth}>
   
        <Typography className="labelStyle" style={labelText}>{ console.log(values.expert_id,"fff")}Manager</Typography>
          <StyledDropdownClickBoxs 
           onClick={(event) => this.dropdownHandlerOpen(event)} 
            data-test-id="select_ID"
           style={{color:this.TextColor(values?.expert_id ),background: this.backColor(values?.expert_id ) }}
      >
      <MaterialUIBox className="contentContainer contentRole">
      <MaterialUIBox className="innerContentBox" 
           style={{color:this.TextColor(values?.expert_id ),background: this.backColor(values?.expert_id ) }}
      >
        {
          values?.expert_id ? this?.state?.allExperts?.filter((i:any)=>i.id==values.expert_id).map((i:any)=>i.attributes.full_name): "Select User"
        }
        </MaterialUIBox>
        </MaterialUIBox>
       { this.state.anchorEl ?<ExpandLess/>: <ExpandMore />}
      </StyledDropdownClickBoxs>
      {this.state.anchorEl && this.renderPlaceholderDropdownContents(this.state.allExperts,values)}
        {touched && error && (
          <Typography data-test-id="errorTypographyID" style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{error}</Typography>
        )}
      </FormControl>
    );
  }
  libraryDataChecks = (data:any, value:any)=>{
    if(data.length>0){
        return  this.renderTableDatas(value,this.handleCheckboxChange,
            "data",this.state.selectedContent)
    }
    else{
        return <Box style={{display:"flex",
        justifyContent:"center",
        flexDirection:"column",
         alignItems:"center",
         gap:"10px",
         height:"695px"}}>
        <NoDataImg src={FindUser} data-test-id="filterOpenTestID"/>
            <Typography style={webStyle.staticDataStyle}
        >No results founds</Typography> 
    </Box>
}

}
  renderDataBoxs() {
    const UserData = this.state?.UserData?.filter((library:any)=> !this.state.removedAllDatas.includes(library))
    return (
    <LibraryBox >
        <Grid container spacing={2}>

            <Grid item xs={12} md={6} lg={6}  >
            <Box style={{ display:"flex" ,flexDirection:"column" ,gap:"24px"  ,
                    boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.07)",
                    height:"695px",padding:"20px",borderRadius:"16px"
                   }}>
                    <Box>
                        <Typography style={headStyle}>Users</Typography> 
                      
                        <Box style={{position:"relative"}}>
                        <SearchIconBoxs className="span_tag">
    <img src={SearchIc}/>
    </SearchIconBoxs>
     <TextFieldSearch
      variant="outlined"
      placeholder="Search"
      data-test-id="search_value"
      value={this.state.placeholderSearchText}
      onChange={(event)=>this.handlePlaceholderSearchChanges(event)}
     
    />
  
                          </Box>         
                    </Box>
                   
                        {this.libraryDataChecks(this.state.UserData, UserData)}
                       
                    <Box style={webStyle.addButtonBoxstyle}>
                    <Button 
                    className={!this.state.selectedContent.length?"add_btn":"add_btn enable_btn"}
                    disabled={!this.state.selectedContent.length}
                    onClick={this.handleAddButtonClick}
                    data-testid ="testing">
                      {!this.state.selectedContent.length?<ArrowForwardIcon style={{marginRight:"10px"}} />:
                       <img src={rightArrows} style={{marginRight:"10px"}}
                    />}
                    <span style={typo1}>
                        Add
                    </span>
                    </Button>
                </Box>
            </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
                <Box style={{ display: "flex", 
                    alignItems: "center",
                    justifyContent:"space-between",
                    flexDirection:"column",
                    boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.07)",
                    height:"695px",padding:"20px",gap:"16px"
                    ,top:"616px",left:"134px",borderRadius:"16px"
                    }} >
                         <Box style={{ width:"100%"}}>
                            <Typography style={headStyle}>Users in the team</Typography>          
                        </Box>
                        {this.renderProgramCLickedData()}
                </Box>
            </Grid>
     </Grid>
    </LibraryBox>
)

}
statusOptionData = (data:any) => {
  if(data.attributes?.status ==="active"){
    return webStyle.statusNew
  }else if(data.attributes?.status === "draft"){
    return webStyle.statusDraft
  }else {
    return webStyle.statusArchived
  }
}
  renderTableDatas=(libraryStaticData:any,handleChange:(selectedData:any)=>void,
  tableName:string,checked:any)=>{
return(

<Box  style={{overflowY:"scroll", width:"100%",height:"588px" , padding:"0px 5px"}} >
{libraryStaticData.map((data:any, index:any) => (

  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center"  ,
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", padding:"16px 24px",borderRadius:"12px",
   height:"72px",
  backgroundColor:this.isCheckedValue(checked,data) ? "rgba(238, 232, 242, 1)": "#fff",marginBottom:"10px"
}}>
  <div style={{display: "flex" ,  alignItems: "center"}}>
      <Checkbox
      className="checkBox"
          style={{ color: this.isCheckedValue(checked,data)?"#652786":"grey", textTransform: "capitalize" }}
          checked={this.isCheckedValue(checked,data)}
          data-testid ={`selectedCheckBox${index}`}
          onChange={() => handleChange(data)}
      />
      <div style={{display: "flex" ,  alignItems: "center"  , gap:"14px", }}>
          <TitleData>{data.attributes?.full_name}</TitleData>
      </div>
  </div >

  <Statuses style={this.statusOptionData(data)} >{data.attributes?.status}</Statuses>
  </Box>
  ))}
</Box>
)
}

  renderProgramCLickedData=()=>{
    return(
        <>
         {this.state.removedAllDatas.length > 0 ?
        (this.renderTableDatas(this.state.removedAllDatas,this.handleRemoveCheckboxChangeProgram,"",this.state.removedUserData)):
         <Box style={{display:"flex",
        justifyContent:"center",
        flexDirection:"column",
         alignItems:"center",
         gap:"10px"}}>
        <Addedimg src={FindUser} data-test-id="filterOpenTestID"/>
            <Typography style={webStyle.staticDataStyle}
        >Select the users you want to add</Typography> 
    </Box>}
    <Box style={webStyle.removeButtonBoxstyle}>
     <Button style={(this.state.removedUserData.length )? webStyle.removeButtonStyleAfters:webStyle.removeButtonStyleBefor}
     data-test-id="removeButtonId"
        disabled={this.state.removedUserData.length?false:true}
        onClick={this.handleRemoveButtonClickProgram}
        >
        <KeyboardBackspaceIcon/>
        &nbsp;&nbsp;&nbsp;
        <span style={typo1}>

                      Remove
                    </span>
         </Button>
         </Box>
    </>
)
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>

          <Box>
            <Formik
              data-test-id="createContentForm"
              initialValues={this.initialFormikState}
              innerRef={formik=> {this.formikRef = formik}}
              onSubmit={(values) => { this.CreateTeamApi(values) }}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleSubmit,handleBlur, touched, errors,setFieldValue }) => {
                const isDisable=this.handleCreateTeamDisable(values);
                return (
                  <>
                  <MainWrapper>
                    <div style={{ width: '100%',paddingBottom:"30px" }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        padding: "24px",
                        boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.07)",
                        borderRadius: "18px",
                        marginBottom:"30px"
                      }}>
                        <Box className="sub_heading">
                        General Information
                        </Box>
                        <Box className="create_inner">
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                            Team Name
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="TeamName"
                              placeholder="Write team name..."
                              value={values.TeamName}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.TeamName?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.TeamName && errors.TeamName && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px",color:"red" }} color="error">{errors.TeamName}</Typography>
                          )}
                        </Box>
                        <Box className="createwrap">
                          <Box>
                            {this.renderAssignedExpert(values,touched.expert_id,errors.expert_id,handleChange)}

                          </Box>
                          {touched.expert_id && errors.expert_id && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.expert_id}</Typography>
                          )}
                        

                        </Box>
                        </Box>
                      
                      </div>
                      <div>
                      {this.renderDataBoxs()}
                      </div>
                      {this.state.editTeamId ? 
                      <div style={webStyle.btnWrapper}>
                          <Button className="saveDraftButton" data-test-Id="Save_btn_id" onClick={()=>this.updateTeamData(values)} >
                        <div>

                            Save Changes
                        </div>
                          
                        </Button>
                        </div>
                        :
                      <div style={webStyle.btnWrapper}>
                        <button style={isDisable?{...webStyle.save_btn,...webStyle.enableBTn}:{...webStyle.save_btn}} disabled={isDisable?false:true} onClick={()=>handleSubmit()}>
                       Create Team
                        </button>
                      </div>
              }
                    </div>
                  </MainWrapper>
                </>
                  )
              }}
   
            </Formik>
         
          </Box>

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CheckboxMain = styled(Checkbox)({
    color: '#652786 !important',
  });
const labelText = { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" }
  const Boxwidth={width:"100%"}
const StyledDropdownClickBoxs = styled('div')({
    marginTop:"8px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "16px 20px !important",
    border: "1px solid #E5E2E1",
    borderRadius: "8px",
    backgroundColor: "#fcf6f6",
    cursor: "pointer",
    position:"relative",
    "&.selectCommonStyle .contentContainer":{
      display: "flex",
      gap: "3px",
      width: "100%"
    },
    "&.selectActive":{
      backgroundColor: "#EEE8F2",
      borderColor: "#652786"
    },
    "&.selectActive .title":{
      color: "#652786"
    },
   "&.creationDate":{
    display: "flex",
    justifyContent: "space-between"
   },
   "& .contentRole ":{
    width: "100% !important",
   display: "flex !important",
   justifyContent: "space-between !important"
   },
    "& .innerContentBox":{
      display: "flex",
      gap: "3px",
      fontSize:"16px",
      fontWeight:500,
      color:'#ADAAAA'
    },
    "& .title":{
      whiteSpace: "nowrap",
      fontSize: "16px",
      fontWeight: 400,
      color: "#484646",
      fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle":{
      color: "#652786"
    }
  });
const DropdownContent = styled(MaterialUIBox)({
    position:"absolute",
    top:"100px",
    zIndex:9999,
    background:"#fff",
    width:"100%",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    padding: "8px 16px",
    borderRadius:"12px",
    "& .checkboxList": {
      marginTop: "5px",
      flexDirection: "column",
      display: "flex",
      marginBottom: "16px"
    },
    "& .checkboxContent": {
      display: "flex",
      alignItems: "center",
      fontFamily: "Poppins , sans-serif",
      borderBottom: "1px solid lightgray",
      textTransform: "capitalize"
    },  "& .MuiTypography-body1":{
      fontFamily:"Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      color: "#1C1B1B"
    },
    "& .checkboxContent:hover": {
      borderBottom: "1px solid transparent",
      backgroundColor: "#EEE8F2",
      borderRadius: "8px",
    },
    "& .selectedStyle": {
      borderBottom: "1px solid transparent !important",
      backgroundColor: "#EEE8F2",
      borderRadius: "8px",
    },
    "& .selectedStyle .MuiTypography-body1":{
      color: "#542070 !important"
    },
    "& .applyBtn": {
      display: "block",
      margin: "auto",
      textTransform: "capitalize",
      color: "#542070",
      fontSize: "16px"
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
      borderBottom: "1px solid lightgray",
      fontFamily:"Poppins, sans-serif"
    },
  });
  
  const headStyle ={
    marginBottom:"10px",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
};

 const typo = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform:"none" as 'none'
 }
 const typo1 = {
  cursor:"pointer",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform:"capitalize" as 'capitalize'

 }
const MainWrapper=styled(Box)({
    "& .sub_heading":{
      color:"#1C1B1B",
      fontSize:"22px",
      fontWeight:600
    },
    "& .createwrap":{
     width:"100%"
    },
   "& .rowCss":{
color:"#652786"
   },
  "& .Input_wrap":{
    margin: "8px 0px",
    border: "1px solid #E5E2E1",
    width: "100%",
    borderRadius: "8px",
    padding: "16px 20px 16px 24px",
    backgroundColor: "#FCF6F6",
    color: "#000",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    "&::placeholder":{
      color: "#ADAAAA !important", 
  },"&:focus":{
    border: "1px solid #E5E2E1",
    outline: "none",
    backgroundColor:"white"
  }
  },
  "& .input_value":{
    color:"#000",
    background:"#fff"
  },
  "& .create_inner":{
     display:"flex",
     gap:"20px",
     "@media(max-width:600px)": {
     flexDirection:"column"
    },
  },
  "& .user_inner":{
    display: "flex",
    gap: "24px",
    padding: "24px",
    marginBottom:"10px",
    boxShadow: "1px 2px 10px 0px #00000011",
    borderRadius: "12px"
  },
  "& .MuiRadio-colorSecondary.Mui-checked":{
    color:"#652786"
  },"& .apply_btn":{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#652786",
    fontWeight:600,
    fontSize:"16px",
    cursor:"pointer",
    marginTop:"15px"
  },
 "& .saveDraftButton": {
  cursor:"pointer",
  marginTop:"40px",
    maxWidth: "411px",
    width:"100%",
    height: "56px", border: "1px solid #E47004",
    borderRadius: "16px",
    padding: "20px 32px 20px 32px",
    fontSize:"16px",
    fontWeight:600,
    gap: "10px",
    color: "#E47004",
    textTransform:"capitalize" as const
  },
  "& .checkboxContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
    fontFamily: "Poppins , sans-serif",
    textTransform: "capitalize"
  },  "& .MuiTypography-body1":{
    fontFamily:"Poppins, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1C1B1B"
  },
  "& .selectedStyle": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent !important",
    borderRadius: "8px",
  },
  "& .selectedStyle .MuiTypography-body1":{
    color: "#542070 !important"
  },
  "& .checkboxContent:hover": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent",
    borderRadius: "8px",
  },
})
const SearchIconBox = styled("span")({
    position:"absolute",
    color: "#ADAAAA",
      paddingTop:"18px",
      paddingLeft:"18px",
   
  });
  const SearchIconBoxs = styled("span")({
    position:"absolute",
    color: "#ADAAAA",
    left:"20px",
    top:"17px",
    zIndex:9999,
    '@media (max-width: 600px)': {
      left:"20px",
    },
  });
  
  const TextFieldSearch = styled(TextField)({
    position:"relative",
    width:"100%",
    "& .MuiOutlinedInput-notchedOutline":{
      background: "#FCF6F6"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray"
    },
    "& .MuiSvgIcon-root":{
      position: "relative",
      zIndex: 1,
      color: "gray"
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "8px",
      height: "56px"
    },
    "& .MuiOutlinedInput-input":{
      paddingLeft:"50px",
      position: "relative",
      zIndex: 1,
    },
    "& .MuiOutlinedInput-inputAdornedStart":{
      paddingLeft: "6px"
    }
  });
  const TextFieldComponent = styled('input')({
    poistion:"relative",
    width:"100%",
      borderRadius:"8px",   
      height: '56px',
      borderRight: "none",
      transition: 'border-color 0.3s ease',
      backgroundColor: "#FCF6F6",
      padding:"16px 30px 16px 50px",
      fontSize: "16px",
      marginBottom:"20px",
       fontWeight: 400,
       fontFamily: "Poppins, sans-serif !important",
        color: "#000",
      "&::placeholder":{
        color: "#ADAAAA !important", 
      },  
      "&:focus":{
        outline: "none",
        backgroundColor:"white"
      },
       "&.grey_border":{
        border: '1px solid #E5E2E1',
       }
  });
  const TitleData =styled("div")({
    color:"#000",
    fontSize:"16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform:"capitalize" as 'capitalize',

    "@media (max-width:960px)": {
        width:"56px",
          },
      "@media(max-width:700px)": {
            width:"56px",
            wordBreak:"break-word",
            textOverflow:"ellipsis",
            whiteSpace:"nowrap",
            overflow:"hidden",
       },

 });
 const Addedimg=styled("img")({
        
  "@media (max-width:960px)": {
     width:"250px"
     
    },
    "@media (min-width:720px)": {
    
    },
})

const NoDataImg=styled("img")({
      height:"80%",
      width:"80%",

  "@media (max-width:960px)": {
     width:"250px"
     
    },
    "@media (min-width:720px)": {
    
    },
})
const TabGrid = styled(Box)({
  display:"flex",
  flexWrap:"wrap"
})

const LibraryBox = styled(Box)({
  
  gap: "10px",
  width: "100%",  
"& .add_btn":{
  display: "flex",
  height: "42px",
  width: "165px",
  padding: "12px 24px 12px 16px",
  justifyContent:"center",
  alignItems:"center",
  gap: "10px",
  backgroundColor:"#E5E2E1",
  flexShrink:0 ,
  borderRadius:"12px",
  color:"#929090",
  cursor:"pointer"
},
"& .enable_btn":{
 backgroundColor:"#652786 !important",
color:"#FFFFFF !important"
}
})

 const Statuses =styled("div")({
    backgroundColor:"#E0D4E7",
    textAlign:"center",
    padding:"6px 9px" , 
    color: "#542070", 
    fontSize:"14px", 
    borderRadius:"88px",
    gap:"10px",
    textTransform:"capitalize",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    width:"125px",
    height:"40px",

    "@media (max-width:960px)": {
    fontSize:"10px", 
    padding:"6px 9px" , 
      },
      "@media (min-width:720px)": {
        
      },
});


  const CheckboxOne = withStyles({
    root: {
      color: '#652786 !important',
    height: "24px !important",
      '&$checked': {
        color: '#652786',
      },
    },
    checked: {}, 
  })(Checkbox);
  const webStyle={

    addButtonBoxstyle:{
        display: "flex",
        justifyContent:"flex-end",
        padding:"16px 16px 20px",
        alignItems: "end",
        gap: "24px"
    },
    
    removeButtonBoxstyle:{
        display: "flex",
        width: "100%",
        height: "74px",
        justifyContent:"flex-start",
        padding:"16px 16px 20px",
        alignItems: "center",
        gap: "24px",
    },
      
    
    addButtonStyle:{
        display: "flex",
        height: "42px",
        width: "165px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        gap: "10px",
        backgroundColor:"#E5E2E1",
        flexShrink:0 ,
        borderRadius:"12px",
        color:"#929090",
        cursor:"pointer"
    },
    removeButtonStyleBefor:{
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        flexShrink:0 ,
        gap: "10px",
        border:"1px solid #E5E2E1",
        borderRadius:"12px",
        color:"#929090",
    },
    btnWrapper:{
        display: "flex",
      justifyContent:"center",
      alignItems:"center",
    },
    save_btn: {
      cursor:"pointer",
      marginTop: "30px",
      width: "411px",
      backgroundColor: "#E5E2E1",
      height: "56px",
      border: "0px",
      borderRadius: "16px",
      color: "#787776",
      fontFamily: "'Poppins', sans-serif" as const,
      fontSize: "16px",
      fontWeight: 600 as const,
      textTransform: "capitalize" as const
    },
    enableBTn:{
      backgroundColor:"#6C328B",
      color:"#FFFFFF"
    },
    removeButtonStyleAfters:{
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        flexShrink:0 ,
        gap: "10px",
        border:"1px solid #E47004",
        borderRadius:"12px",
        color:"#E47004",
    },
    
    staticDataStyle :{
        fontFamily:"Poppins",
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "24px", 
        fontWeight: 600,
        color:"#1C1B1B",  
    } ,
    draftButtonStyle1:{
      padding: "20px 32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border:"1px solid #E5E2E1" ,
        gap: "10px", 
        borderRadius:"16px",
        color:"#929090"
    },
    draftButtonStyle2:{
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        border:"1px solid #E47004" ,
        borderRadius:"16px",
        color:"#E47004"
    },
    roleButtonStyle1:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        backgroundColor:"#E5E2E1",
        borderRadius:"16px",
        color:"#929090"
    },
    roleButtonStyle2:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        backgroundColor:"#652786",
        borderRadius:"16px",
        color:"#fff"
    },
    
    statusNew: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "88px",
        background: "#E0D4E7",
        color: "#652786",
        textTransform:"capitalize" as 'capitalize'
        
      },
      statusArchived: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#FFD9DF",
        borderRadius: "88px",
        color: "#E70E6B",
        textTransform:"capitalize" as 'capitalize'
      },
      statusDraft: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
        background: "#FFEDE4",
        borderRadius: "88px",
        color: "#E47004",
        textTransform:"capitalize" as 'capitalize'
    
      },
      labelStyle: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#1C1B1B"
      },
    
      upload_btn: {
        display: "flex",
        justifyContent: "flex-end"
      }
       
}

// Customizable Area End
