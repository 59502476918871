export const imgPasswordVisible = require("../assets/images/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/images/ic_password_invisible.png");
export const card = require("../assets/images/card_bg.png");
export const visa = require("../assets/images/visa_bg.png");
export const video = require("../assets/images/videothumbnail.jpeg");
export const orderSuccess = require("../assets/images/order_success.png");
export const orderFail = require("../assets/images/order_fail.png");
export const info = require("../assets/images/information.png");
export const CardImg = require("../assets/images/card.png");
export const NoCardsImg = require("../assets/images/no_cards.png");
export const DebitCreditCardsImg = require("../assets/images/debit_credit_cards.png");
export const TrashIcon = require("../assets/images/trash_icon.png");