import React, { useState } from 'react'
import { Box, Button, styled, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as yup from "yup";
export const UserImage = require("./user.png");
export const StarIcon = require("./star.png");
export const StarIconActive = require("./starActive.png");
export const NoSmileIcon = require("./noSmile.png");
export const NoSmileIconActive = require("./noSmileActive.png");
export const SadIcon = require("./sad.png");
export const SadIconActive = require("./sadActive.png");
export const LoveIcon = require("./love.png");
export const LoveIconActive = require("./loveActive.png");
export const SmileIcon = require("./smile.png");
export const SmileIconActive = require("./smileActive.png");


interface ReviewCourseProps {
  courseName: string;
  submitReviewCourse: (data: unknown) => void;
  courseId?: number;
  programId: number;

}
type EmontionType = Array<{
  icon: string;
  type: string;
  iconActive: string
}>

type FormType = {
  feedback: string;
  learned: string
}

type Payload = {
  rating: number;
  comment: string;
  emoji: string;
  reviewable_id: number;
  reviewable_type: string;
}

const EMOTIONS: EmontionType = [
  {
    icon: SadIcon,
    iconActive: SadIconActive,
    type: "sad"
  },
  {
    icon: NoSmileIcon,
    type: "no_mood",
    iconActive: NoSmileIconActive,

  },
  {
    icon: SmileIcon,
    type: "happy",
    iconActive: SmileIconActive,

  },
  {
    icon: LoveIcon,
    type: "love",
    iconActive: LoveIconActive,

  }
]


const ReviewCourse = (props: ReviewCourseProps) => {
  const { courseName, submitReviewCourse, courseId, programId } = props
  const [comprehension, setComprehension] = useState("")
  const [rate, setRate] = useState(0)

  const handleClickEmotion = (type: string) => {
    if (type === comprehension) {
      setComprehension("")
    } else {
      setComprehension(type)
    }
  }

  const handleClickStar = (index: number) => {
    if (index === rate) {
      setRate(0)
    } else {
      setRate(index)
    }
  }

  const handleSubmitForm = (formValue: FormType) => {
    if (!comprehension || !rate || !formValue.feedback) {
      return
    }
    const data: Payload = {
      rating: rate,
      emoji: comprehension,
      comment: formValue.feedback,
      reviewable_id: courseId ?? programId,
      reviewable_type: courseId ? "BxBlockCoursecreation::Course" : "BxBlockCoursecreation::Program"
    }
    let formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value.toString());
    }
    submitReviewCourse(formData)
  }

  const schema = yup.object().shape({
    feedback: yup.string().required("Required Field").max(500, "Description is too long (Max 500 character allowed)"),
    learned: yup.string().required("Required Field").max(500, "Description is too long (Max 500 character allowed)"),
  })

  const initialValue: FormType = {
    feedback: "",
    learned: ""
  }

  return (
    <Wrapper>
      <Typography variant="h3" className="page-title">{courseId ? `Course` : `Program`} Completion</Typography>
      <Box className='header'>
        <Box className='text-part'>
          <Typography variant="h3" className="title">Congratulation!</Typography>
          <Box className='sub-title'>
            You have successfully <br /> completed the program
          </Box>
        </Box>
        <img src={UserImage} alt="user" />
      </Box>
      <Box className='form-box'>
        <Typography className="course-name">Review {courseName}</Typography>
        <Box className='comprehension'>
          <Box className="label">
            <Typography className='main-label'>Comprehension*</Typography>
            <Typography className='sub-label'>How well did you understand the concepts?</Typography>
          </Box>
          <Box className='emotion'>
            {
              EMOTIONS.map((emotion) => {
                return <div key={emotion.type} onClick={() => handleClickEmotion(emotion.type)}>
                  <img src={comprehension === emotion.type ? emotion.iconActive : emotion.icon} alt="emotion" />
                </div>
              })
            }
          </Box>
        </Box>
        <Box className='comprehension'>
          <Box className="label">
            <Typography className='main-label'>Rate your experience*</Typography>
            <Typography className='sub-label'>Hope you like the course</Typography>
          </Box>
          <Box className='emotion'>
            {
              Array(5).fill("").map((item, index) => {
                return <div key={index} onClick={() => handleClickStar(index + 1)}>
                  <img src={index < rate ? StarIconActive : StarIcon} alt="star" />
                </div>
              })
            }
          </Box>
        </Box>

        <Formik
          initialValues={initialValue}
          validationSchema={schema}
          validateOnChange={false}
          validateOnMount={false}
          validateOnBlur={false}
          onSubmit={(values) => {
          }}
        >
          {({ handleBlur, handleChange, values, errors, validateForm }) => {
            return (
              <>
                <Box className='description-box'>

                  <Typography className='main-label'>Any feedback?*</Typography>
                  <TextField
                    name="feedback"
                    onBlur={handleBlur}
                    placeholder="Please write your description"
                    value={values.feedback}
                    helperText={errors.feedback}
                    variant="outlined"
                    style={{ background: "#FCF8F8" }}
                    onChange={handleChange}
                    InputProps={{
                      style: descInput,
                    }}
                    multiline
                    maxRows={10}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                <Box className='description-box'>
                  <Typography className='main-label'>What did you learn?*</Typography>
                  <TextField
                    name="learned"
                    onBlur={handleBlur}
                    placeholder="Please write your description"
                    value={values.learned}
                    helperText={errors.learned}
                    variant="outlined"
                    style={{ background: "#FCF8F8" }}
                    onChange={handleChange}
                    InputProps={{
                      style: descInput,
                    }}
                    multiline
                    maxRows={10}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box className='submit-box'>
                  <Button className='btn-submit' type='submit' onClick={() => {
                    validateForm()
                    handleSubmitForm(values)
                  }}>Submit</Button>
                </Box>
              </>
            )
          }}
        </Formik>

      </Box>
    </Wrapper>
  )
}

export default ReviewCourse

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& .page-title": {
    fontWeight: 700,
  },
  "& .header": {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 100px"
  },
  "& .text-part": {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "& .title": {
      fontSize: "40px",
      fontWeight: 700,
      background: "-webkit-linear-gradient(#592A93, #BB1AB9)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent"
    },
    "& .sub-title": {
      backgroundImage: "-webkit-linear-gradient(#592A93, #BB1AB9)",
      color: "#FFFFFF",
      width: "356px",
      height: "100px",
      borderRadius: "14px",
      textAlign: "center",
      alignContent: "center",
      fontWeight: 500,
      fontSize: "20px"
    }
  },
  "& .form-box": {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    margin: "30px 100px",
  },
  "& .course-name": {
    fontWeight: 700,
    fontSize: 32
  },
  "& .comprehension": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .emotion": {
    display: "flex",
    gap: "1rem"
  },
  "& .label": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
  },
  "& .main-label": {
    fontWeight: 600,
    fontSize: 24,
    color: "#1C1B1B"
  },
  "& .sub-label": {
    fontWeight: 400,
    fontSize: 16,
    color: "##484646"
  },
  "& .description-box": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
  },
  "& .MuiInputBase-input": {
    padding: "16px 20px 16px 24px",
    gap: "0px",
    borderRadius: "8px",
  },
  "& .submit-box": {
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .btn-submit": {
    textTransform: "none",
    background: "#E47004",
    width: "271px",
    height: "36px",
    padding: "8px 40px 8px 40px",
    gap: "4px",
    borderRadius: "32px",
    color: "white"
  },
  "& .MuiFormHelperText-root": {
    color: "red"
  }
})


const descInput = {
  borderRadius: "8px",
  background: "#FCF8F8",
  outline: "none"
}