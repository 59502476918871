import React from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CartItem from "./CartItem.web";
import { error } from "../assets";

interface IOrderSuccess {
  orderItems: any;
  message: string;
  onGoToHomeClick: () => void;
  onTryAgainClick: () => void;
}

export default function OrderFailure({ 
  message,
  orderItems, 
  onGoToHomeClick, 
  onTryAgainClick,
}: IOrderSuccess) {
  const classes = useStyles();

  let orderItemsContent;
  if (orderItems && orderItems?.length > 0) {
    orderItemsContent = orderItems.map((data: any) => {
      const isCourse =
        data?.attributes?.purchasable_type === "BxBlockCoursecreation::Course"
          ? true
          : false;
      const thumbnail = isCourse
        ? data?.attributes?.course_detail?.thumbnail_image?.url
        : data?.attributes?.program_detail?.thumbnail_image?.url;
      const title = isCourse
        ? data?.attributes?.course_detail?.title
        : data?.attributes?.program_detail?.title;
      const author = isCourse
        ? data?.attributes?.course_detail?.created_by
        : data?.attributes?.program_detail?.created_by;
      return (
        <CartItem
          key={data.id}
          itemType={isCourse ? "Course" : "Program"}
          thumbnail={thumbnail}
          title={title}
          author={author}
          danger={true}
        />
      );
    });
  } else {
    orderItemsContent = null;
  }

  return (
    <Container maxWidth="sm" className={classes.mainContainer}>
      <Grid container className={classes.topGridContainer}>
        <Grid item md={4} xs={12} className={classes.centeredContainer}>
          <img 
            src={error} 
            alt="order success" 
            className={classes.img}
          />
        </Grid>
        <Grid item md={8} xs={12} className={classes.centeredContainer}>
          <h1 className={classes.headingText}>Oops!!</h1>
          <p className={classes.descriptionText}>
            Your order has failed.
          </p>
          <p className={classes.descriptionText}>{message}</p>
        </Grid>
      </Grid>
      <p className={classes.secondaryText}>Order Summary</p>
      {orderItemsContent}
      <Grid container className={classes.btnContainer}>
        <Grid item>
          <Button 
            fullWidth 
            className={classes.btn}
            onClick={onGoToHomeClick}
          >
            Go to Home Page
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className={`${classes.btn} ${classes.containedBtn}`}
            onClick={onTryAgainClick}
          >
            Try Again
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      background: "#FFEDE1",
      borderRadius: 16,
      marginTop: 50,
    },
    topGridContainer: {
      paddingTop: 25,
    },
    centeredContainer: {
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    infoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headingText: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "39px",
      color: "#1E1F20",
    },
    descriptionText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#1A1A1A",
    },
    img: {
      width: 140,
      height: 140,
    },
    secondaryText: {
      fontWeight: 800,
      fontSize: "24px",
      lineHeight: "24px",
      color: "#545650",
    },
    emphasis: {
      fontWeight: 700,
    },
    btnContainer: {
      justifyContent: "space-around",
      padding: "25px 0",
    },
    btn: {
      width: "100%",
      height: "64px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      color: "#EF2B4B",
      padding: "0 40px",
    },
    containedBtn: {
      borderRadius: "16px",
      backgroundColor: "#EF2B4B",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#EF2B4B",
      },
    },
  })
);