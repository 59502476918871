import React from "react";
import { Button } from "@material-ui/core";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface IStripeCheckout {
  handleStripeSuccess: (msg: string, orderItems: any) => void;
  handleStripeFailure: (response: any) => void;
}

interface IStripePayment extends IStripeCheckout {
  clientSecret: string;
  publishableKey: string;
}

export default function StripePayment({
  clientSecret,
  publishableKey,
  handleStripeSuccess,
  handleStripeFailure,
}: IStripePayment) {
  const stripePromise = loadStripe(publishableKey);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: "stripe",
          labels: "floating",
          variables: {
            borderRadius: "8px",
          },
        },
      }}
    >
      <StripeCheckout
        handleStripeSuccess={handleStripeSuccess}
        handleStripeFailure={handleStripeFailure}
      />
    </Elements>
  );
}

function StripeCheckout({
  handleStripeSuccess,
  handleStripeFailure,
}: IStripeCheckout) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    
    stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
      })
      .then((response: any) => {
        if (response.error) {
          handleStripeFailure(response.error.message);
        } else if (response.paymentIntent.status === "succeeded") {
          handleStripeSuccess("Congrats!", []);
        }
      })
      .catch((errResponse: any) => {
        handleStripeFailure(errResponse.error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        className={classes.payNowBtn}
        type="submit"
        disabled={!stripe || !elements}
      >
        Pay Now
      </Button>
    </form>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payNowBtn: {
      width: "100%",
      height: "64px",
      borderRadius: "16px",
      backgroundColor: "#6c328b",
      color: "#fff",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      marginTop: "15px",
      "&:hover": {
        backgroundColor: "#6c328b",
      }
    },
  })
);