import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  TextField,Select,
  MenuItem,
  InputAdornment,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MaterialUIBox from '@material-ui/core/Box';
import {ExpandMore,ExpandLess } from "@material-ui/icons";
import { SeacrhIcon } from "../../../blocks/teambuilder/src/assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createTheme ,styled} from "@material-ui/core/styles";
import { Formik } from "formik";
import {FolderIcon} from "./assets"
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
const PurpleRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#652786', 
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CreateBranchController, {
  Props,
  validationSchema
} from "./CreateBranchController.web"

export default class CreateTeam extends CreateBranchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  initialFormikState = {
    ParentBranch:"",
    BranchName:"",
    expert_id:"",
    ContactName:"",
    Address:"",
    City:"",
    StateSelect:"",
    PhoneNumber:"",
    TimeZoneSelect:"",
    WebAddress:"",
    EmailAddress:""
  }
  renderPlaceholderDropdownContents(allExperts:any,values:any) {
    return <DropdownContents >
      <MaterialUIBox className="checkboxList">
        {allExperts && allExperts?.map((option:any) => {
          return (
            <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={values.expert_id}
            onChange={this.handleRadioChange}
            data-test-id={`radio_select${option.id}`}
            >
           <FormControlLabel  className={values.expert_id===option.id?"rowCss":""} value={option.id} control={<Radio  />} label={option.attributes.full_name} />
           </RadioGroup>
          )
        })}
        <Box className="apply_btn">
           <span onClick={this.handleApplyBtn}>
           Apply
            </span>
        </Box>
      </MaterialUIBox>
    </DropdownContents>
  }
  renderAssignedExperts(
    values:any,
    touched:any,
    error: any,
    handleChange: (event: React.ChangeEvent<{}>) => void,
  ) {
    return (
      <FormControl variant="standard" className="customInput" style={Boxwidth}>
        <Typography className="labelStyle" style={labelText}>Branch Manager*</Typography>
          <StyledDropdownClick 
           onClick={(event) => this.dropdownHandlerOpen(event)} 
           style={{color:this.TextColor(values?.expert_id ),background: this.backColor(values?.expert_id ) }}
           data-test-id="select_ID"
      >
      <MaterialUIBox className="contentContainers contentRole">
      <MaterialUIBox className="innerContentBoxs" 
           style={{color:this.TextColor(values?.expert_id ),background: this.backColor(values?.expert_id ) }}
      >
        {
          values?.expert_id ? this?.state?.allExperts?.filter((i:any)=>i.id==values.expert_id).map((i:any)=>i.attributes.full_name): "Select User"
        }
        </MaterialUIBox>
        </MaterialUIBox>
       { this.state.anchorE ?<ExpandLess/>: <ExpandMore />}
      </StyledDropdownClick>
      {this.state.anchorE && this.renderPlaceholderDropdownContents(this.state.allExperts,values)}
        {touched && error && (
          <Typography data-test-id="errorTypographyID" style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{error}</Typography>
        )}
      </FormControl>
    );
  }
  renderBtn=(isDisable:boolean,handleSubmit:any)=>{
    return(
      <>
        <div style={webStyle.btnWrapper}>
                        <button  className="saveDraftButton" data-test-id="cancel_btn" onClick={()=>this.handleCancel()}>
                       Cancel
                        </button>
                      </div>
                      <div style={webStyle.btnWrapper}>
                        <button style={isDisable===true?{...webStyle.save_btn,...webStyle.enableBTn}:{...webStyle.save_btn}} disabled={isDisable===true?false:true} onClick={()=>handleSubmit()}>
                       Continue
                        </button>
                      </div>
                      <DeleteModal
                      data-test-id='delete-content'
                      openDialog={this.state.showCancelModal}
                      headingText={`Are you sure you want to leave?`}
                      btnCancelText="Cancel"
                      btnOkText="Leave"
                      subHeading="All your changes will be lost"
                      handleCancel={this.CloseModal}
                      handleOk={()=>this.handleCancelOk()}
                  />
      </>
    )
  }
  renderCustomSelect=(values:any,errors:any,touched:any,handleChange:any,handleBlur:any)=>{
    return(
      <>
         <Box className="create_inner">
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           City
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="City"
                              placeholder="Type here..."
                              value={values.City}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.City?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.City && errors.City && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.City}</Typography>
                          )}
                        </Box>
                        <SelectWrapper className="createwrap">
                        <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           State
                            </label>
                <CustomSelect1
                  labelId="subscription-select-label"
                  data-test-id="state-select"
                  name="StateSelect"
                  value={values.StateSelect}
                  IconComponent={ExpandMore} 
                  displayEmpty
                  onChange={handleChange}
                  onClick={this.handleStateDropdownOpen}
                  open={this.state.stateDropdownOpen} 
                  style={{color:this.TextColor(values?.StateSelect),background: this.backColor(values?.StateSelect ) }}
                  className="select_css"
                  MenuProps={{ ...CustomMenuProps, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }}
                >
                  <MenuItem value="" disabled>Select State</MenuItem>
                  {this.state.stateData.map((state:any,index:any)=>{
                    return(
                       <MenuItem value={state} key={index}>{state}</MenuItem>
                    )
                  })}                  
                </CustomSelect1>
                {touched.StateSelect && errors.StateSelect && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.StateSelect}</Typography>
                          )}

                        </SelectWrapper>
                        </Box>
                        <Box className="create_inner">
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           Phone Number
                            </label>
                            <input
                              type="text"
                              data-test-id="phoneNumberTest"
                              name="PhoneNumber"
                              placeholder="Type here..."
                              value={values.PhoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.PhoneNumber?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.PhoneNumber && errors.PhoneNumber && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.PhoneNumber}</Typography>
                          )}
                        </Box>
                        <Box className="createwrap">
                        <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           Timezone
                            </label>
                <CustomSelect1
                  labelId="subscription-select-label"
                  data-test-id="timeZone-select"
                  name="TimeZoneSelect"
                  value={values.TimeZoneSelect}
                  IconComponent={ExpandMore} 
                  displayEmpty
                  onChange={handleChange}
                  onClick={this.handleTimeZoneDropdownOpen}
                  open={this.state.timeZoneDropdownOpen}
                  style={{color:this.TextColor(values?.TimeZoneSelect ),background: this.backColor(values?.TimeZoneSelect ) }}
                  className="select_css"
                  MenuProps={{ ...CustomMenuProps, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }}
                >
                  <MenuItem value= "" disabled>Select Timezone</MenuItem>
                  {this.state.TimeZone.map((option:any,index:any)=>{
                    return(
                       <MenuItem key={index} value={option}>{option}</MenuItem>
                    )
                  })}  
                  
                </CustomSelect1>
                {touched.TimeZoneSelect && errors.TimeZoneSelect && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.TimeZoneSelect}</Typography>
                          )}
                        </Box>
                        </Box>
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>

          <Box>
            <Formik
              data-test-id="createContentForm"
              initialValues={this.initialFormikState}
              innerRef={formik=> {this.formikRef = formik}}
              onSubmit={(values) => this.CreateBranchApi(values)}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleSubmit,handleBlur, touched, errors,setFieldValue }) => {
                const isDisable=this.handleDisable(values)
                return (
                  <>
                  <MainWrapper>
                  <div style={{ width: '100%',paddingBottom:"30px" }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        padding: "24px",
                        boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.07)",
                        borderRadius: "18px",
                        marginBottom:"30px"
                      }}>
                      
                        <Box className="sub_heading">
                        Branch Hierarchy
                        </Box>
                        <Box className="create_inner">
                        <Box className="createwrap">
                          <AutoSelect>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                            Select parent branch:
                            </label>
                            <Autocomplete
                             id="custom-autocomplete"
                             style={{color:this.TextColor(this.state.selectedValue),background: this.backColor(this.state.selectedValue) }}
                             options={this.state.BranchData} 
                             popupIcon={<ExpandMore />}
                             getOptionLabel={(option:any) =>  option.name}
                             onChange={this.handleAutocompleteChange}
                             renderOption={(props:any) => {
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps} id="test_click"  onClick={() => this.handleBranchChange(null,props)}>
                                  <FormControlLabel
                                    control={
                                      <span style={{display:"flex", marginRight:"10px"}}>
                                    <PurpleRadio
                                        checked={this.state.selectedValue?.name === props.name}
                                      />
                                      <img src={FolderIcon}/>
                                      </span> 
                                    }
                                    label={props.name}
                                  />
                                 
                                </li>
                              );
                            }}
                           renderInput={(params) => (
                           <TextField
                          {...params}
                             placeholder="Type to find branch"
                          InputProps={{
                           ...params.InputProps,
                          startAdornment: (
                           <InputAdornment position="start">
                               <img src={SeacrhIcon} alt="Search" style={{ width: 20, height: 20 }} />
                             </InputAdornment>
                               ),
                             }}
                         variant="outlined"
                         />
                         )}
                         disableClearable 
                         />

                            
                          </AutoSelect>
                        
                        </Box>
                        {(touched.ParentBranch && errors.ParentBranch) && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.ParentBranch}</Typography>
                          )}
                        </Box>
                      
                      </div>
                    </div>
                    <div style={{ width: '100%',paddingBottom:"30px" }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        padding: "24px",
                        boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.07)",
                        borderRadius: "18px",
                        marginBottom:"30px"
                      }}>
                        <Box className="sub_heading">
                        General Information
                        </Box>
                        <Box className="create_inner">
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                            Branch Name*
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="BranchName"
                              placeholder="Write team name..."
                              value={values.BranchName}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.BranchName?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.BranchName && errors.BranchName && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.BranchName}</Typography>
                          )}
                        </Box>
                        <Box className="createwrap">
                          <Box>
                            {this.renderAssignedExperts(values,touched.expert_id,errors.expert_id,handleChange)}

                          </Box>
                        </Box>
                        </Box>
                      
                      </div>
                    </div>
                    <div style={{ width: '100%',paddingBottom:"30px" }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        padding: "24px",
                        boxShadow: "1px 2px 10px 0 rgba(0,0,0,0.07)",
                        borderRadius: "18px",
                        marginBottom:"30px"
                      }}>
                        <Box className="sub_heading">
                       Branch Details
                        </Box>
                        <Box className="create_inner">
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           Contact Name
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="ContactName"
                              placeholder="Type here..."
                              value={values.ContactName}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.ContactName?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.ContactName && errors.ContactName && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.ContactName}</Typography>
                          )}
                        </Box>
                        <Box className="createwrap">
                        <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                              Address
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="Address"
                              placeholder="Type here..."
                              value={values.Address}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.Address?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.Address && errors.Address && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.Address}</Typography>
                          )}
                        </Box>
                        </Box>
                     {this.renderCustomSelect(values,errors,touched,handleChange,handleBlur)}
                        <Box className="create_inner">
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           Web Address
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="WebAddress"
                              placeholder="Type here..."
                              value={values.WebAddress}
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "WebAddress",
                                    value: event.target.value.toLowerCase(), 
                                  },
                                });
                              }}
                              onBlur={handleBlur} 
                              className={values.WebAddress?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.WebAddress && errors.WebAddress && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.WebAddress}</Typography>
                          )}
                        </Box>
                        
                        <Box className="createwrap">
                          <Box>
                            <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle} data-test-id ='content-label'>
                           Email Address
                            </label>
                            <input
                              type="text"
                              data-test-id="Thumbnail_input"
                              name="EmailAddress"
                              placeholder="Type here..."
                              value={values.EmailAddress}
                              onChange={handleChange}
                              onBlur={handleBlur} 
                              className={values.EmailAddress?"Input_wrap input_value":"Input_wrap"}
                            />
                          </Box>
                          {touched.EmailAddress && errors.EmailAddress && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.EmailAddress}</Typography>
                          )}
                        </Box>
                        </Box>
                      </div>
                      </div>
                    {this.renderBtn(isDisable,handleSubmit)}
                  </MainWrapper>
                 
                </>
                  )
              }}
   
            </Formik>
         
          </Box>

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CheckboxMain = styled(Checkbox)({
    color: '#652786 !important',
  });
  const AutoSelect=styled('div')({
  "& .MuiAutocomplete-root":{
    width:"48%",
    marginTop:"10px"
  },
"& .MuiInputBase-root":{
  width:"100%",
  height:"58px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding:"0px 20px",
    borderRadius: "8px",
    cursor: "pointer",
    position:"relative",
},

"@media (max-width: 800px)": {
  "& .MuiAutocomplete-root":{
    width:"100%",
  },
},
"& .MuiAutocomplete-input":{
  padding:"10px 0px"
}
  })
  const CustomSelect1 = styled(Select)({
    '& .MuiSelect-root': {
      position: 'relative',
      display: "flex",
      alignItems: "center",
      gap: "25px",
      padding:"0 15px 0 0",
      "@media (max-width: 960px)": {
        "& .MuiGrid-item .MuiFormControl-root": {
          width: "50% !important",
        }
      },
    },
    '& .MuiPopover-paper': {
      position: 'relative',
      zIndex: 999,
    },
    "& .MuiList-padding": {
      paddingTop: "0px"
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "2px !important",
      paddingRight: "8px !important",
      gap: "10px !important",
      color: "#6C328B !important",
      fontSize: "14px !important",
      fontWeight: 500
    },
    "& .MuiSelect-icon":{
      marginRight:"10px"
    },
    "& .MuiSelect-select:focus": {
      background: "#fff" 
    },
  })
  
  const CustomMenuProps = {
    PaperProps: {
      style: {
        height: "150px" as const,
        marginTop: '22px' as const,
        marginLeft: "25px" as const,
        width: "42%" as const,
        minWidth: "100px",
        left: "10px",
        color: "black"
      },
    },
    getContentanchorE: null
  };
  const SelectWrapper=styled('div')({
    "& .MuiMenu-paper":{
      marginTop:"80px !important"
    }
  })
const labelText = { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" }
  const Boxwidth={width:"100%"}
const StyledDropdownClick = styled('div')({
    marginTop:"8px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "16px 20px !important",
    border: "1px solid #E5E2E1",
    backgroundColor: "#fcf6f6",
    borderRadius: "8px",
    cursor: "pointer",
    position:"relative",
    "&.selectCommonStyle .contentContainers":{
      gap: "3px",
      display: "flex",
      width: "100%"
    },
    "&.selectActive":{
      backgroundColor: "#EEE8F2",
      borderColor: "#652786"
    },
    "&.selectActive .title":{
      color: "#652786"
    },
   "&.creationDate":{
    display: "flex",
    justifyContent: "space-between"
   },
   "& .contentRole ":{
     display: "flex !important",
    width: "100% !important",
   justifyContent: "space-between !important"
   },
    "& .innerContentBoxs":{
      display: "flex",
      gap: "3px",
      fontSize:"16px",
      fontWeight:500,
      color:'#ADAAAA'
    },
    "& .title":{
      whiteSpace: "nowrap",
      fontWeight: 400,
      fontSize: "16px",
      color: "#484646",
      fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle":{
      color: "#652786"
    }
  });
const DropdownContents = styled(MaterialUIBox)({
    position:"absolute",
    top:"100px",
    zIndex:9999,
    background:"#fff",
    width:"100%",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius:"12px",
    padding: "8px 16px",
    "& .checkboxList": {
      marginTop: "5px",
      display: "flex",
      flexDirection: "column",
      marginBottom: "16px"
    },
    "& .checkboxContent": {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid lightgray",
      fontFamily: "Poppins , sans-serif",
      textTransform: "capitalize"
    },  "& .MuiTypography-body1":{
      fontFamily:"Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      color: "#1C1B1B"
    },
    "& .checkboxContent:hover": {
      borderBottom: "1px solid transparent",
      backgroundColor: "#EEE8F2",
      borderRadius: "8px",
    },
    "& .selectedStyle": {
      borderBottom: "1px solid transparent !important",
      backgroundColor: "#EEE8F2",
      borderRadius: "8px",
    },
    "& .selectedStyle .MuiTypography-body1":{
      color: "#542070 !important"
    },
    "& .applyBtn": {
      display: "block",
      textTransform: "capitalize",
      margin: "auto",
      color: "#542070",
      fontSize: "16px"
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
      borderBottom: "1px solid lightgray",
      fontFamily:"Poppins, sans-serif"
    },
  });

const MainWrapper=styled(Box)({

    "& .sub_heading":{
      color:"#1C1B1B",
      fontSize:"22px",
      fontWeight:600
    },
    "& .createwrap":{
     width:"100%"
    },
   "& .rowCss":{
backgroundColor:"#EEE8F2",
color:"#652786"
   },
  "& .Input_wrap":{
    margin: "8px 0px",
    border: "1px solid #E5E2E1",
    width: "100%",
    borderRadius: "8px",
    padding: "16px 20px 16px 24px",
    color: "#000",
    backgroundColor: "#FCF6F6",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    "&::placeholder":{
      color: "#ADAAAA !important", 
  },"&:focus":{
    border: "1px solid #E5E2E1",
    outline: "none",
    backgroundColor:"white"
  }
  },
  "& .input_value":{
    color:"#000",
    background:"#fff"
  },
  "& .create_inner":{
     display:"flex",
     gap:"20px",
     "@media(max-width:600px)": {
     flexDirection:"column"
    },
  },
  "& .user_inner":{
    display: "flex",
    gap: "24px",
    marginBottom:"10px",
    padding: "24px",
    boxShadow: "1px 2px 10px 0px #00000011",
    borderRadius: "12px"
  },
  "& .MuiRadio-colorSecondary.Mui-checked":{
    color:"#652786"
  },
  "& .apply_btn":{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#652786",
    fontSize:"16px",
    fontWeight:600,
    cursor:"pointer",
    marginTop:"15px"
  },
 "& .saveDraftButton": {
  cursor:"pointer",
  maxWidth: "411px",
  marginTop:"40px",
    width:"100%",
    height: "56px", border: "1px solid #E47004",
    fontSize:"16px",
    borderRadius: "16px",
    fontWeight:600,
    gap: "10px",
    color: "#E47004",
    background:"#fff",
    textTransform:"capitalize" as const
  },
  "& .select_css":{
    height:"58px",
    marginTop:"8px",
    alignItems: "center",
    display: "flex",
    gap: "12px",
    padding: "16px 20px !important",
    border: "1px solid #E5E2E1 !important",
    borderRadius: "8px",
    backgroundColor: "#fcf6f6",
    cursor: "pointer",
    position:"relative",
  }
})
  const webStyle={
    btnWrapper:{
        display: "flex",
      justifyContent:"center",
      alignItems:"center",
    },
    save_btn: {
      cursor:"pointer",
      marginTop: "30px",
      width: "411px",
      backgroundColor: "#E5E2E1",
      height: "56px",
      border: "0px",
      borderRadius: "16px",
      color: "#787776",
      fontFamily: "'Poppins', sans-serif" as const,
      fontSize: "16px",
      fontWeight: 600 as const,
      textTransform: "capitalize" as const
    },
    enableBTn:{
      backgroundColor:"#6C328B",
      color:"#FFFFFF"
    },
      labelStyle: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#1C1B1B"
      },
    
      upload_btn: {
        display: "flex",
        justifyContent: "flex-end"
      },
    
       
}

// Customizable Area End
