Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.btnExampleTitle = "CLICK ME";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Library2";
exports.labelBodyText = "Library2 Body";
exports.addCartItemApiEndPoint = "/bx_block_shopping_cart/cart";
exports.apiContentTypeAppJson = "application/json";
exports.getApiMethod = "GET";
exports.getObjectDetailsApiEndPoint = "bx_block_library2/user_objects";
exports.newlyAddedCourseFetchAPiEndPoint= "bx_block_catalogue/newly_added_courses"
exports.ratings = "Ratings";
exports.Completed = "Completed";
exports.programCompleted = "Program Completion";
exports.phaseRelease = "Phase releases";
exports.courses = "courses";
exports.reviewCourseProgramAPi = "bx_block_reviews/reviews"

exports.test = "test"
exports.noteCourseApiEndpoint = "bx_block_notes/notes/course_notes?id="
// Customizable Area End