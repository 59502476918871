// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PageTitle from "../../../components/src/DynamicTitle";
import Loader from "../../../components/src/Loader.web";
import ShoppingController, { Props } from "./ShoopingController.web";
import "./ShoppingCart.css";
import Cart from "./components/Cart.web";
import OrderSuccess from "./components/OrderSuccess.web";
import OrderFailure from "./components/OrderFailure.web";
import CartTypeSelectionButtons from "./components/CartTypeSelectionButtons.web";
import RecommendedCourses from "../../../components/src/RecommendedCourses";

export default class ShoppingCartOrders extends ShoppingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let pageTitle;
    let mainContent;
    if (this.state.orderSuccessMsg) {
      pageTitle = "Order Completed";
      mainContent = (
        <OrderSuccess
          isLoading={this.state.cartDataLoader}
          orderItems={this.state.orderItems}
          onGoToHomeClick={this.handleBackHome}
          onGoToPersoalLibClick={this.handleBackPersonal}
          getOrders={this.getLastOrderData}
          cartType={this.state.cartType}
          onPayNextSubsClick={this.handleBackToCart}
          lastSubscriptionOrder={this.state.lastSubscriptionOrder}
        />
      );
    } else if (this.state.orderFailureMsg) {
      pageTitle = "Order Failed";
      mainContent = (
        <OrderFailure
          orderItems={
            this.state.cartType === "otp"
              ? this.state.cartData
              : this.state.subscriptionCartData
          }
          onGoToHomeClick={this.handleBackHome}
          onTryAgainClick={this.handleBackToCart}
          message={this.state.orderFailureMsg}
        />
      );
    } else {
      pageTitle = this.state.orderConfirmed ? "Order Confirmation" : "cart";
      mainContent = (
        <Cart
          cartType={this.state.cartType}
          cartItems={this.state.cartData}
          subscriptionCartItems={this.state.subscriptionCartData}
          onRemoveItemFromCart={this.handleRemoveItemFromCart}
          payableAmount={this.state.payableAmount}
          onPaymentSuccess={this.handleOrderSuccess}
          onPaymentFailure={this.handleOrderFaillure}
          selectedSubsCartItem={this.state.selectedSubsCartItem}
          handleSubsCartItemsSelection={this.handleSubsCartItemsSelection}
          orderConfirmed={this.state.orderConfirmed}
          handleOrderConfirmation={this.handleOrderConfirmation}
        />
      );
    }

    // Empty cart UI
    const {
      cartDataLoader,
      cartData,
      subscriptionCartData,
      orderSuccessMsg,
      orderFailureMsg,
    } = this.state;
    const isCartEmpty =
      !cartDataLoader &&
      cartData.length === 0 &&
      subscriptionCartData.length === 0 &&
      !orderSuccessMsg &&
      !orderFailureMsg;
    if (isCartEmpty) {
      return (
        <>
          <PageTitle
            title={pageTitle}
            showBackButton={pageTitle === "cart"}
            onBackClick={this.handleGoBack}
          />
          <Box style={styles.emptyMsg}>Your cart is empty!</Box>
        </>
      );
    }

    return (
      <>
        <Loader loading={this.state.cartDataLoader} />
        <PageTitle
          title={pageTitle}
          showBackButton={pageTitle === "cart"}
          onBackClick={this.handleGoBack}
        />
        {
          !this.state.orderSuccessMsg && !this.state.orderFailureMsg && (
            <CartTypeSelectionButtons
              cartType={this.state.cartType}
              handleCartTypeChange={this.handleCartTypeChange}
            />
          )
        }
        {mainContent}
        <div style={styles.recommendedContainer}>
          <RecommendedCourses 
            data={this.state.recommendedCourseData}
            style={{}}
            addCartItem={this.addToCart}
            postXpandLibraryData={this.enrollToCourses}
            playButton={() => null}
          />
        </div>
      </>
    );
  }
}

const styles = {
  emptyMsg: {
    display: "flex",
    lineHeight: "330px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    fontWeight: 700,
    color: "#bdbdbd",
  },
  progressContainer: {
    display: "flex",
    height: "35%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  circularProgress: {
    color: "#6C328B",
  },
  recommendedContainer: {
    marginTop: 100,
  }
};

// Customizable Area End