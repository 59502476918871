// Customizable Area Start
import React from "react";
import { Box, Typography, TextField, Button, Modal, InputAdornment } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ProfileModalController, {
  Props
} from "./ProfileModalController.web";
import Backdrop from "@material-ui/core/Backdrop";
const Avatar = require("../assets/avatar.jpg");
const Edit = require("../assets/edit.png");
const editImage = require("../assets/EditEmail.png")
import { Formik } from "formik";
import * as yup from "yup";
import './style.css'

export default class ExampleBlockUI extends ProfileModalController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getUserProfileData();
    this.getUserProfileDataModal();
  }

  render() {

    const { imageDemo, userProfileDataModal } = this.state
    /* istanbul ignore next */
    const onImageChange = (event: any) => {
      this.setState({
        imageDemo: URL.createObjectURL(event.target.files[0]),
        image: event.target.files[0]
      })

    }
    /* istanbul ignore next */
    return (
      <Box className="scrollbar" style={{ width: "40vw", height: '95vh', overflowX: 'hidden', marginTop: '15px', backgroundColor: "white", padding: '30px', borderRadius: "20px" }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>Edit Profile</Typography>
          <Box style={styles.closeButton} onClick={this.props.handelClose}><CloseIcon /></Box>
        </Box>
        <Box>
          <Formik
            initialValues={{
              first_name: userProfileDataModal?.first_name,
              last_name: userProfileDataModal?.last_name,
              image: userProfileDataModal?.avatar || null,
              user_name: userProfileDataModal?.full_name,
              email_id: userProfileDataModal?.email,
              Phone_Number: userProfileDataModal?.phone_number,
              Country_Code: userProfileDataModal?.Country_Code
            }}
            onSubmit={(values) => {

              console.log(values, 'form data test');
              this.updateProfileData(values);
            }}
            validationSchema={yup.object().shape({
              first_name: yup.string().required('FirstName is required'),
              last_name: yup.string().required('LastName is required'),
              user_name: yup.string().required('UserName is required'),
              // Country_Code: yup.string().required('Country is required')
            })}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit
              } = props;

              return (
                <form className="regform" onSubmit={handleSubmit}>
                  <Box>
                    {
                      !this.state.isPasswordUpdate &&
                      <>
                        <Box style={{ display: "flex", alignItems: 'center', height: "150px", justifyContent: "center" }}>
                          <Box style={{ width: "100px", height: "100px", position: "relative" }}>
                            {imageDemo ?
                              <img src={imageDemo} className="shadowStyle" height="100px" width="100px" style={{ borderRadius: "50px" }} />
                              :
                              <img src={values.image ? values.image : Avatar} className="shadowStyle" height="100px" width="100px" style={{ borderRadius: "50px" }} />}

                            <label htmlFor="file">
                              <Box className="shadowStyle" style={{ backgroundColor: "white", borderRadius: "30px", width: "30px", height: "35px", position: "absolute", top: "0px", right: "-5px", display: 'flex', alignItems: "center", justifyContent: "center", cursor: 'pointer' }}>
                                <img src={Edit} height="20px" width="20px" />
                              </Box>
                            </label>
                            <input test-id="change1" id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={onImageChange} />
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "10px" }}>Personal Details</Typography>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Name</Typography>
                            <TextField fullWidth placeholder="First Name" variant="outlined" size="small"
                              name="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                              error={touched.first_name && Boolean(errors.first_name)}
                              helperText={touched.first_name && errors.first_name} />
                          </Box>

                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Last Name</Typography>
                            <TextField fullWidth placeholder="Last Name" variant="outlined" size="small"
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              error={touched.last_name && Boolean(errors.last_name)}
                              helperText={touched.last_name && errors.last_name} />
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>User Name</Typography>
                            <TextField fullWidth disabled placeholder="User Name" variant="outlined" size="small"
                              name="user_name"
                              value={values.user_name}
                              error={touched.user_name && Boolean(errors.user_name)}
                              helperText={touched.user_name && errors.user_name} />
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Email Id</Typography>
                            <TextField fullWidth placeholder="Email Id" variant="outlined" size="small"
                              name="email_id"
                              value={values.email_id}
                              onChange={handleChange}
                              inputProps={{ type: 'email' }}
                              error={touched.email_id && Boolean(errors.email_id)}
                              helperText={touched.email_id && errors.email_id}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img onClick={this.props.handelEditEmailModel} style={{ cursor: 'pointer' }} src={editImage} alt="Edit" />
                                  </InputAdornment>
                                ),
                              }} />
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Phone Number</Typography>
                            <Box style={{ display: 'flex' }}>
                              <TextField
                                placeholder="Country Code"
                                variant="outlined"
                                size="small"
                                name="Country_Code"
                                value={values.Country_Code}
                                onChange={handleChange}
                                inputProps={{ type: 'number' }}
                                style={{ marginRight: "10px" }}
                              />
                              <TextField fullWidth placeholder="Phone Number" variant="outlined" size="small"
                                name="Phone_Number"
                                value={values.Phone_Number}
                                onChange={handleChange}
                                inputProps={{ type: 'number' }}
                                error={touched.Phone_Number && Boolean(errors.Phone_Number)}
                                helperText={touched.Phone_Number && errors.Phone_Number} />
                            </Box>
                          </Box>

                          <Box onClick={this.enablePasswordUI} style={{ marginTop: "25px", backgroundColor: "#f3f3f3", height: "50px", cursor: "pointer" }}>
                            <Box style={{ padding: "10px 15px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                              <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "14px" }}>Passwords</Typography>
                              <ArrowForwardIosIcon />
                            </Box>
                          </Box>
                          <Box onClick={() => this.handleOpen()} style={{ marginTop: "25px", backgroundColor: "#f3f3f3", height: "50px", cursor: "pointer" }}>
                            <Box style={{ padding: "10px 15px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                              <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "14px" }}>My Interests</Typography>
                              <ArrowForwardIosIcon />
                            </Box>
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <ColorButton variant="contained" fullWidth style={{ fontSize: "14px", fontWeight: "bold" }} type="submit">
                              Save
                            </ColorButton>
                          </Box>
                        </Box></>
                    }
                  </Box>
                </form>
              )
            }}
          </Formik>
          <Formik
            initialValues={
              {
                password: '',
                old_password: '',
                password_confirmation: ''
              }
            }
            onSubmit={(values) => {
              console.log(values, 'form data test');
              this.updateProfilePassword(values)

            }}/*  */
            validationSchema={yup.object().shape({
              password: yup.string().required('password is required'),
              old_password: yup.string().required('old password is required'),
              password_confirmation: yup.string().required('confirm password is required')
            })}
            enableReinitialize={true}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,

              } = props;
              return (
                <form className="regform" onSubmit={handleSubmit}>
                  {
                    this.state.isPasswordUpdate &&
                    <Box>
                      <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "10px" }}>Update Password</Typography>
                      <Box style={{ marginTop: "25px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Current Password</Typography>
                        <TextField fullWidth placeholder="Current Password" variant="outlined" size="small"
                          name="old_password"
                          value={values.old_password}
                          onChange={handleChange}
                          error={touched.old_password && Boolean(errors.old_password)}
                          helperText={touched.old_password && errors.old_password}
                        />
                      </Box>
                      <Box style={{ marginTop: "25px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>New Password</Typography>
                        <TextField fullWidth placeholder="Password" variant="outlined" size="small"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                        />

                      </Box>

                      <Box style={{ marginTop: "25px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Confirm Password</Typography>
                        <TextField fullWidth placeholder="Confirmation Password" variant="outlined" size="small"
                          name="password_confirmation"
                          value={values.password_confirmation}
                          onChange={handleChange}
                          error={touched.password_confirmation && Boolean(errors.password_confirmation)}
                          helperText={touched.password_confirmation && errors.password_confirmation}
                        />
                      </Box>
                      <Box onClick={this.disablePasswordUI} style={{ marginTop: "25px", backgroundColor: "#f3f3f3", height: "50px", cursor: "pointer" }}>
                        <Box style={{ padding: "10px 15px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "14px" }}>Back to profile</Typography>
                          <ArrowForwardIosIcon />
                        </Box>
                      </Box>
                      <Box style={{ marginTop: "25px" }}>
                        <ColorButton variant="contained" fullWidth style={{ fontSize: "14px", fontWeight: "bold" }} type="submit">Update Password </ColorButton>
                      </Box>
                    </Box>
                  }
                </form>
              )
            }}
          </Formik>
        </Box>
      </Box>
    );
  }
}

const styles = {
  closeButton: {
    borderRadius: "50px",
    width: "50px",
    height: "50px",
    '&:hover': {
      backgroundColor: 'none'
    },
    '&:active': {
      backgroundColor: 'none',
    },
  },
  mainBox: {
    width: "40vw",
    height: '95vh',
    overflowX: 'hidden' as 'hidden',
    marginTop: '15px', backgroundColor: "white",
    padding: '30px',
    borderRadius: "20px"
  },
}


const ColorButton :any = withStyles((theme) => ({
  root: {
    backgroundColor: purple[800],
    color: "white",
    borderRadius: "10px",
    height: "50px",
    borderColor: purple[700],
    '&:hover': {
      borderColor: purple[500],
      color: "white",
      backgroundColor: purple[600],
    },
  },
}))(Button);
// Customizable Area End
