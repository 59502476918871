import React from "react";
import { Typography, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { WavingHand } from "./assets";

export default function Greetings() {
  const classes = useStyles();
  const firstName = localStorage.getItem("first_name");

  return (
    <Box className={classes.container}>
      <Box className={classes.flexCenter}>
        <Typography className={classes.heading2}>{`Welcome, ${firstName}`}</Typography>
        <img className={classes.img} src={WavingHand} alt="Waving hand" />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: "25px",
    },
    heading1: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "32px",
      color: "#11142D",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
    },
    heading2: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "50px",
      color: "#11142D",
      [theme.breakpoints.up("sm")]: {
        fontSize: "48px",
        lineHeight: "72px",
      },
    },
    flexCenter: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    img: {
      width: "45px",
    },
  })
);
