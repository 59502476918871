export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const course = require("../assets/Teacher.png");
export const program = require("../assets/system-uicons_document.png");
export const Filter = require("../assets/filter.svg");
export const NotFoundImage = require("../assets/NotFoundImage.jpeg")
export const newCourse = require("../assets/newCourse.svg");
export const newProgram = require("../assets/newProgram.svg")
export const NewFilter = require("../assets/NewFilter.svg")
export const newContentRole =require("../assets/content_role_creation.png")
export const VIDEO_ICON = require("../assets/play1.png");
export const coursePreview = require("../assets/Rectangle.png")
export const editIconPreview = require("../assets/icon_edit.svg")
export const lessonpic = require("../assets/lesson.png")
export const docIcon = require("../assets/icon_doc.svg")
export const crossIcon =require("../assets/crossIcon.png")
export const home =require("../assets/home.png")
export const audio =require("../assets/icon_audio.png")
export const document =require("../assets/doc.png")
export const video =require("../assets/playback.png")




