import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

interface IOrderSummary {
  payableAmount: number;
  onOrderConfirmation: () => void;
}

export default function OrderSummary({ 
  payableAmount, 
  onOrderConfirmation 
}: IOrderSummary) {
  const classes = useStyles();

  if (payableAmount === 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <p className={classes.textLarge}>total amount</p>
        <p className={classes.textLarge}>${payableAmount.toFixed(2)}</p>
      </div>
      <div className={clsx(classes.flex, classes.margin)}>
        <p className={classes.textSmall}>Price after discount</p>
        <p className={classes.textSmall}>${payableAmount.toFixed(2)}</p>
      </div>
      <div className={clsx(classes.flex, classes.margin)}>
        <p className={classes.textSmall}>Taxes</p>
        <p className={classes.textSmall}>$0.00</p>
      </div>
      <div
        className={clsx(classes.flex, classes.payableContainer)}
        style={{ margin: 0 }}
      >
        <p className={classes.textXL}>total payable amount</p>
        <p className={classes.textXL}>${payableAmount.toFixed(2)}</p>
      </div>
      <Button 
        variant="contained" 
        className={classes.proceedBtn}
        onClick={onOrderConfirmation}
      >
        Proceed
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      borderRadius: 16,
      background:
        "linear-gradient(184.52deg, rgba(255, 255, 255, 1) 0%, rgba(240, 243, 246, 1) 100%), linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px 20px",
    },
    textXL: {
      color: "#6C328B",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: 21,
      }
    },
    textLarge: {
      color: "#1A1A1A",
      fontSize: "20px",
      fontWeight: 700,
      textTransform: "capitalize",
    },
    textSmall: {
      color: "#1A1A1A",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24.5px",
    },
    margin: {
      marginLeft: 50,
      borderTop: "1px solid #E6E8EC",
    },
    payableContainer: {
      borderRadius: "16px",
      border: "1px solid #6C328B",
      padding: "0 30px",
    },
    proceedBtn: {
      width: "100%",
      height: "64px",
      borderRadius: "16px",
      backgroundColor: "#6c328b",
      textTransform: "none",
      marginTop: "15px",
      color: "#FCFCFD",
      textAlign: "center",
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "16px",
      "&:hover": {
        backgroundColor: "#6c328b",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      }
    },
  })
);
