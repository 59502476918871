// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Typography,
  Tooltip,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  withStyles,
  styled
} from "@material-ui/core/styles";
import "react-calendar/dist/Calendar.css";
import { EditIcon3, deleteIcon, plusIcon, iconInfo, searchIcon, closeIcon, Document, AddIcon } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import BranchDetailsController, { AddContractResponse } from "./BranchDetailsController.web";
import MaterialUIBox from '@material-ui/core/Box';
import Loader from "../../../components/src/Loader";
import { Download, Delete } from ".././../../blocks/coursecreation/src/assets";
import moment from "moment";

export interface Props {
  classes: any;
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    width: '204px',
    height: '42px',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '6px 6px 28px 0px #85858540, -6px -6px 28px 0px #85858540',
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#484646"
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#FFFFFF', 
    width: '32px', 
    height: '20px', 
    position: "absolute",
    top: "41px",
  },
});

class BranchDetails extends BranchDetailsController {

  downloadFile = async (fileName: string ,fileURL: string) => {
  
    // Create a URL for the file
    const responseUrl = await fetch(fileURL);
    const blobURL= await responseUrl.blob();
    const fileDownloadURL = URL.createObjectURL(blobURL);
  
    // Create an <a> element with a download attribute
    const a = document.createElement('a');
    a.href = fileDownloadURL;
    a.download = fileName; // Set the download attribute to the file's original name
    document.body.appendChild(a);
  
    // Programmatically click the anchor to trigger the download
    a.click();
  
    // Clean up by revoking the object URL and removing the anchor
    document.body.removeChild(a);
  }

  render() {
    return (
      <>
      {this.state.loading ? (
            <Loader loading={this.state.loading} />
          ) : (
        <div className={this.props.classes.container} >
          <div className={this.props.classes.root2}>
            <div className={this.props.classes.userInfoDiv}>
              <div className={this.props.classes.userInfoInnerDiv} >
                <div className={this.props.classes.userInfoInnerDiv2} >
                  <Typography className={this.props.classes.userInformationHeading2}>
                    Branch Information
                  </Typography>
                  <img src={EditIcon3}
                    className={this.props.classes.editIconImg}
                    data-test-id="userEditUser"
                  />
                </div>
                <div
                  className={
                    this.state.branchStatus === "active"
                      ? this.props.classes.statusStyle
                      : this.props.classes.statusInactiveStyle
                  } >
                  {this.state.branchStatus}
                </div>
              </div>
              <div className={this.props.classes.userInfoInnerDiv3} >
                <div className={this.props.classes.userInfoInnerDiv4}>
                  <div className={this.props.classes.userInfoInnerDiv5}>
                    <div>
                      <span className={this.props.classes.userInformationName}>Parent Branch</span>
                    </div>
                    <div>
                      <span className={this.props.classes.userInformationName}>Branch Manager</span>
                    </div>

                  </div>
                  <div className={this.props.classes.userInfoInnerDiv6} >
                    <span className={this.props.classes.userInformationsubName}>{this.state.parentBranch}</span>
                    <span className={this.props.classes.userInformationsubName}>{this.state.branchManager}</span>

                  </div>
                  <div className={this.props.classes.branchInfoInnerDiv5}>
                    <span className={this.props.classes.userInformationName}>Branch Name</span>
                    <div>
                    <span className={this.props.classes.userInformationActiveUser}>Total active users</span>
                    <CustomTooltip title="Logged in the last 30 days" arrow placement="top">
                      <img style={{ cursor: "pointer", height: "16px", width: "16px" }} src={iconInfo} />
                    </CustomTooltip>
                    </div>
                  </div>
                  <div className={this.props.classes.userInfoInnerDiv6} >
                    <span className={this.props.classes.userInformationsubName}>{this.state.branchName}</span>
                    <span className={this.props.classes.userInformationsubName2}> - </span>

                  </div>
                </div>
                <div className={this.props.classes.userInfoDeleteUserDiv} >
                  <div className={this.props.classes.userInfoDeleteUserInnerDiv} >
                    <div onClick={this.cancelLessonDeleteDialog} className={this.props.classes.userInformationDeleteDiv} >
                      <img src={deleteIcon} />
                      <span className={this.props.classes.userInformationDeleteText}>
                        Delete branch
                      </span>
                    </div>
                  </div>
                </div>
                {<ModalMainBody
                  open={this.state.openUserDeleteDialog}
                >
                  <ModalContent>
                    <IconAlign onClick={this.cancelLessonDeleteDialog}><CloseIcon /></IconAlign>
                    <ModalText>{`Delete branch  ?`}</ModalText>
                    <ModalText1>{"Users will no longer have access to content assigned to them via this branch, and billing will stop. Usage data will be kept in reporting."}</ModalText1>
                    <div style={{ width: "85%" }}>
                      <div className={this.props.classes.buttonContainer}>
                        <button data-test-id="cancelTeamData" className={this.props.classes.cancelButton} onClick={this.cancelLessonDeleteDialog}>Cancel</button>
                        <button data-test-id="deleteBranchData" className={this.props.classes.deleteButton} onClick={() => this.handleDeleteUserData()}>Delete</button>
                      </div>
                    </div>
                  </ModalContent>
                </ModalMainBody>}
              </div>
            </div>
            <div className={this.props.classes.assignContentDiv} >
              <div className={this.props.classes.assignContentInnerDiv} >
                <Typography className={this.props.classes.userInformationHeading3}>
                  Assign Content
                </Typography>
              </div>
              <div className={this.props.classes.assignContentInnerDiv2} >
                <span className={this.props.classes.userInformationName}>Select the content this branch will be allowed to view</span>
                <button data-test-id="userAssign" onClick={() => this.handleAssignContent(this.state.branchId)} className={this.props.classes.userInformationAssignText}>
                  Assign Content
                </button>
              </div>
            </div>
          </div>
          <div className={this.props.classes.root3}>
            <div className={this.props.classes.teamsDiv} >
              <div className={this.props.classes.teamsInnerDiv} >
                <Typography className={this.props.classes.userInformationHeading4}>
                  Teams
                </Typography>
              </div>
              <div className={this.props.classes.teamsInnerDiv2} >
                <span className={this.props.classes.userInformationHeading5}>All users in selected teams will inherit content assigned.</span>
              </div>
            </div>
            <div className={this.props.classes.assignContentBtnDiv} >
              <div className={this.props.classes.assignContentBtnInnerDiv} data-test-id="addTeam" onClick={this.toggleDropdown}>
                <img className={this.props.classes.assignContentImg} src={plusIcon} />
                <span className={this.props.classes.userInformationAssignText2}>Add Teams</span>
              </div>
              {this.state.dropdownAddTeams && (
                <div className={this.props.classes.dropdownOutsideDiv} ref={this.dropdownRef}>
                  <MaterialUIBox
                    p={3}
                    className={this.props.classes.teamsDropdown}
                  >
                    <DropdownContent>
                      <TextFieldSearch
                        data-test-id="teamSearch"
                        placeholder="Search Team Name"
                        variant="outlined"
                        value={this.state.placeholderSearchTeamsText}
                        onChange={this.handleTeamsSearchChange}
                        InputProps={{
                          startAdornment: <img src={searchIcon}
                            className={this.props.classes.teamSearchIcon}
                          />,
                        }}
                      />
                      <MaterialUIBox data-test-id="checkData1" className="checkboxList">
                        {this.state.teamsListing && this.state.teamsListing
                          .filter((team: any) => team.attributes.name.toLowerCase().includes(this.state.placeholderSearchTeamsText.toLowerCase()))
                          .map((team: any) => {
                            const isChecked = this.state.teamsSelectedItems && this.state.teamsSelectedItems.find((item: { id: number, value: string }) => item.value === team.attributes.name);
                            return (
                              <FormControlLabel
                                key={team.id}
                                className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"}
                                control={
                                  <CheckboxMain
                                    data-test-id="teamLabelsTestId"
                                    checked={isChecked ? true : false}
                                    name={team.attributes.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      this.tagsCheckboxChangeHandler(event, team.id);
                                    }}
                                  />
                                }
                                label={team.attributes.name}
                              />
                            );
                          })}
                      </MaterialUIBox>
                    </DropdownContent>
                  </MaterialUIBox>
                </div>
              )}
              {this.state.teamsSelectedItems.map(team => (
                <div key={team.id}>
                  <Chip
                    data-test-id="addTeamName"
                    label={team.value}
                    onClick={() => {
                      this.navigateToManageTeam(team.id)
                    }}
                    onDelete={() => this.handleClearTeamNames(team)}
                    variant="outlined"
                    className={this.props.classes.selectedTeamsList}
                    deleteIcon={<img src={closeIcon}
                      className={this.props.classes.selectedTeamsDeleteIcon}
                    />}
                  />
                </div>
              ))}
            </div>
          </div>
          

            <Box className={this.props.classes.contractHeading}>
              <Grid container justifyContent={"space-between"} style={{padding: "16px"}}>
              <Typography variant="h6">
                Contracts
              </Typography>

              <Box>
                  <Button
                    startIcon={<img src={AddIcon} />}
                    onClick={() => this.openPDFFileSelect()}
                    data-test-id="add-pdf-button"
                  >
                    <span className={this.props.classes.addPDFText}>Add PDF</span>
                  </Button>
                  <input
                    type="file"
                    accept=".pdf"
                    data-test-id="upload-pdf"
                    style={{display: "none"}}
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                  />
              </Box>
              </Grid>

              <Paper elevation={1} className={this.props.classes.contractListWrapper}>
                {this.state.contracts?.length > 0 &&
                this.state.contracts.map((contract: AddContractResponse , index) => {
                  const currentDate = new Date(contract.attributes.created_at); 
                  const formattedDate = moment(currentDate).format('MM-DD-YYYY');
                  return(
                  <Box
                  key={index}
                  className={this.props.classes.documentRow}
                  >
                    <Box style={{ display: 'flex', alignItems: 'center', gap: 1, width: "40%" }}>
                      <img src={Document} style={{paddingRight: "16px"}} />
                      <Typography>{contract.attributes.file.filename}</Typography>
                    </Box>

                    <Typography color="textSecondary">{formattedDate}</Typography>

                    <Box style={{ display: 'flex', gap: 1 }}>
                      <IconButton color="primary" data-test-id="download-pdf" onClick={() => this.downloadFile(contract.attributes.file.filename ,contract.attributes.file.url)}>
                        <img src={Download}/>
                      </IconButton>
                      <IconButton color="secondary" data-test-id="delete-pdf" onClick={() => this.deleteContractFile(Number(contract.id))}>
                        <img src={Delete}/>
                      </IconButton>
                    </Box>
                  </Box>
                  )
              })}

              {this.state.contracts.length === 0 &&
                  <Box
                    className={this.props.classes.noDocumentFound}
                  >
                    There are no contracts uploaded yet.
                  </Box>
              }
                

              </Paper>
            </Box>
          <div className={this.props.classes.saveChangesDiv} >
            <SaveAndCreate
              data-test-id="saveChangesBtn"
              onClick={this.teamLessonDialog}
              className={this.state.teamsSelectedItems.length > 0 ? this.props.classes.saveChangesEnableBtn : this.props.classes.saveChangesDisableBtn}
              disabled={!this.state.teamsSelectedItems.length}
            >
              <Typography className={this.props.classes.typo}>Save Changes</Typography>
            </SaveAndCreate>
            {<ModalMainBody
                  open={this.state.openTeamDialog}
                >
                  <ModalContent>
                    <IconAlign onClick={this.teamLessonDialog}><CloseIcon /></IconAlign>
                    <ModalText style={{marginBottom:"30px"}}>{`Are you sure you want to save changes?`}</ModalText>
                    <div style={{ width: "85%" }}>
                      <div className={this.props.classes.buttonContainer}>
                        <button data-test-id="cancelBranchData" className={this.props.classes.cancelButton} onClick={this.teamLessonDialog}>Cancel</button>
                        <button data-test-id="SaveChangesData" className={this.props.classes.deleteButton} onClick={() =>  this.updateTeamsToUser(this.state.teamsSelectedItems)}>Save</button>
                      </div>
                    </div>
                  </ModalContent>
                </ModalMainBody>}
          </div>
        </div>
        )}
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    saveChangesDiv: {
      display: "flex",
      justifyContent: "center",
      marginTop: "35px",
      width: "97%"
    },
    saveChangesDisableBtn: {
      display: "flex",
      padding: "20px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E5E2E1",
      borderRadius: "16px",
      color: "#929090",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      width: "411px",
      height: "56px",
      font: "normal 600 16px Poppins",
      textTransform: "capitalize"
    },
    saveChangesEnableBtn: {
      width: "411px",
      height: "56px",
      display: "flex",
      padding: "20px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E47004",
      borderRadius: "16px",
      color: "#E47004",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "capitalize"

    },
    selectedTeamsDeleteIcon: {
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      width: "24px",
      height: "24px",
      cursor: "pointer"
    },
    selectedTeamsList: {
      borderColor: '#652786',
      color: '#652786',
      width: "170px",
      height: "40px",
      borderRadius: "12px !important",
      border: "1px solid #652786 !important",
      backgroundColor: "#FFFFFF !important",
      fontSize: "14px",
      fontFamily: "Poppins !important",
      fontWeight: 400,
      textAlign: "center",
      lineHeight: "16px",
      margin: "0 0 20px 15px",
      position: "relative"
    },
    teamSearchIcon: {
      width: "20px",
      height: "20px",
      position: "relative",
      zIndex: 999,
      marginRight: "6px"
    },
    teamsDropdown: {
      padding: '16px 16px 20px 16px',
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      width: "380px",
      boxSizing: "border-box",
      position: "absolute",
      zIndex: 9999,
      marginTop: "8px",
      overflow: "auto",
      height: "auto"
    },
    dropdownOutsideDiv: {
      position: "relative",
      top: "42px",
      right: "185px"
    },
    container: {
      width: "100%",
      margin: "0px 20px"
    },
    root2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    root3: {
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      marginTop: "25px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    statusStyle: {
      fontSize: "14px",
      width: '96px',
      height: '40px',
      borderRadius: '88px',
      backgroundColor: '#E0D4E7',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '6px 9px',
      gap: "10px",
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#542070",
    },
    userInfoDiv: {
      borderBottom: "1px solid #E5E2E1",
      padding: "15px 0px 22px 0px",
      width: "97%"
    },
    userInfoInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "18px"
    },
    userInfoInnerDiv3: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      flexWrap: "wrap",
    },
    userInfoInnerDiv4: {
      display: "flex"
    },
    userInfoInnerDiv5: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",

    },
    branchInfoInnerDiv5: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      borderLeft: "1px solid #E5E2E1",
      paddingLeft: "18px"
    },
    userInfoInnerDiv6: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "20px",
      gap: "8px",
      paddingRight: "15px"
    },
    userInfoDeleteUserDiv: {
      display: "flex",
      alignItems: "end",

      '@media (max-width: 960px)': {
        marginTop: "20px",
      },
    },
    userInfoDeleteUserInnerDiv: {
      display: "flex",
      alignItems: "center",
      '@media (max-width: 960px)': {
        flexWrap: "wrap"
      },
    },
    assignContentDiv: {
      padding: "18px 0px 18px 0px",
      width: "97%"
    },
    assignContentInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "7px"
    },
    userInfoInnerDiv2: {
      display: "flex",
      alignItems: "center"
    },
    statusInactiveStyle: {
      height: '40px',
      width: '96px',
      backgroundColor: '#FFD9DF',
      borderRadius: '88px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      gap: "10px",
      padding: '6px 9px',
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#E70E6B",
    },
    userInformationHeading2: {
      font: "600 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationName: {
      font: "400 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationActiveUser: {
      font: "400 18px Poppins",
      color: "#1C1B1B",
      marginRight: "8px"
    },
    userInformationsubName: {
      font: "normal 600 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationsubName2: {
      font: "normal 600 18px Poppins",
      color: "#1C1B1B",
      textAlign: "center"
    },
    userInformationDeleteDiv: {
      display: "flex",
      alignItems: "center",
      width: "160px",
      height: "44px",
      cursor: 'pointer !important'
    },
    userInformationDeleteText: {
      font: "normal 600 16px Poppins",
      color: "#652786",
      marginLeft: "14px",
      width: "100%"
    },
    userInformationHeading3: {
      font: "normal 500 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationAssignText: {
      font: "normal 600 16px Poppins",
      width: "183px",
      height: "42px",
      borderRadius: "12px",
      backgroundColor: "#652786",
      color: "#FFFFFF",
      border: "none",
      cursor: 'pointer !important',
      "@media (max-width: 820px)": {
        marginTop: "15px"
      },
    },
    userInformationHeading4: {
      font: "normal 500 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationHeading5: {
      font: "normal 400 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationAssignText2: {
      font: "normal 600 16px Poppins",
      color: "#FFFFFF",
    },
    assignContentInnerDiv2: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      alignItems: "center",
      textAlign: "center",
      "@media (max-width: 820px)": {
        alignItems: "flex-start",
        textAlign: "left",
        flexWrap: "wrap"
      },
    },
    teamsDiv: {
      padding: "20px 0px 20px 0px",
      marginLeft: "20px"
    },
    teamsInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "7px"
    },
    teamsInnerDiv2: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      alignItems: "center",
      textAlign: "center"
    },
    assignContentBtnDiv: {
      border: "1px solid #E5E2E1",
      padding: "20px",
      borderRadius: "12px",
      width: "97%",
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "20px",
      background: "#FCF8F8"
    },
    assignContentBtnInnerDiv: {
      width: "182px",
      height: "42px",
      borderRadius: "12px",
      backgroundColor: "#652786",
      color: "white",
      gap: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: 'pointer !important'
    },
    assignContentImg: {
      height: "24px",
      width: "24px"
    },
    editIconImg: {
      height: "24px",
      width: "24px",
      marginLeft: "15px",
      cursor: "pointer"
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "0px 2px 0px 2px"
    },
    cancelButton: {
      width: "48%",
      height: "42px",
      fontWeight: 600,
      background: "white",
      fontSize: "16px",
      border: "1px solid #E47004",
      borderRadius: "12px",
      color: "#E47004",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    deleteButton: {
      width: "48%",
      border: "none",
      height: "42px",
      background: "#652786",
      borderRadius: "12px",
      fontWeight: 600,
      fontSize: "16px",
      color: "white",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    contractHeading: {
      width: "97%",
      padding: "20px 24px",
      boxShadow: "1px 2px 10px 0px #00000011",
      borderRadius: "18px"
    },
     contractListWrapper : {
      padding: "20px",
      backgroundColor: "#FCF8F8",
      border: "1px solid var(--sys-surface-DisabledContainer, #E5E2E1)",
      borderRadius: "18px",
      gap: "20px",
      display: "flex",
      flexDirection: "column"
     },
     addPDFText : {
      fontSize: "16px",
      fontWidth: 600,
      colot: "#652786"
     },
     documentRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "18px 24px",
      borderBottom: "1px solid rgb(224, 224, 224)",
      boxShadow: "1px 2px 10px 0px #00000011",
      background: "var(--sys-color-OnPrimary, #FFFFFF)",
      borderRadius: "12px",
      height: "72px"
     },
     noDocumentFound: {
      color: "1C1B1B",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px"
     }
  });

const ModalMainBody = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "9px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth: "400px",
  },
  "@media (max-width: 400px)": {
    maxWidth: "330px",
  }
});

const ModalText = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 600,
  fontSize: "22px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const ModalText1 = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 400,
  fontSize: "16px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const IconAlign = styled(Box)({
  position: "absolute",
  top: "-10",
  right: "-10",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white",
  cursor: 'pointer !important'
});

const DropdownContent = styled(MaterialUIBox)({
  width: "348px",
  maxHeight: "204px",
  "& .checkboxList": {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "3px"
  },
  "& .checkboxContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
    fontFamily: "Poppins",
    textTransform: "capitalize"
  }, "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#484646"
  },
  "& .selectedStyle": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent !important",
  },
  "& .selectedStyle .MuiTypography-body1": {
    color: "#652786 !important"
  },
  "& .checkboxContent:hover": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent",
  },
  "& .applyBtn": {
    display: "block",
    margin: "auto",
    color: "#542070",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    borderBottom: "1px solid #E5E2E1",
    fontFamily: "Poppins"
  },
  "& .react-calendar": {
    border: "unset",
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .react-calendar abbr": {
    textDecoration: "none"
  },
  "& .react-calendar__month-view__days__day": {
    width: "31px",
    height: "31px",
    borderRadius: "50%",
    fontWeight: 400,
    fontSize: "14px !important"
  },
  "& .react-calendar__month-view__weekdays": {
    fontWeight: 400,
    fontSize: "10px !important"
  },
  "& .react-calendar__tile--now:enabled": {
    backgroundColor: "#652786",
    color: "#fff"
  },
  "& .react-calendar__tile--now:enabled:hover": {
    backgroundColor: "#652786"
  },
  "& .react-calendar__month-view__days__day--weekend": {
    color: "rgba(0, 0, 0, 0.87)"
  },
  "& .react-calendar__month-view__days__day--neighboringMonth": {
    color: "#757575"
  },
  "& .react-calendar__tile--active": {
    color: "#fff",
    backgroundColor: "#652786 !important"
  },
  "& .react-calendar__year-view__months__month": {
    width: "74px",
    height: "74px",
    borderRadius: "50%"
  },
  "& .react-calendar__navigation__prev2-button": {
    display: "none"
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  "& .react-calendar__navigation button:enabled:hover": {
    backgroundColor: "unset"
  },
  "& .react-calendar__navigation button": {
    minWidth: "auto"
  },
  "& .calendarBtnContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "16px"
  }
});
const TextFieldSearch = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    color: '#1C1B1B',
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    backgroundColor: "#FFFFFF",
    border: "none !important"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
    border: "none !important"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
    border: "none !important"
  },
  "& .MuiSvgIcon-root": {
    position: "relative",
    zIndex: 1,
    color: "gray"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    height: "56px",
    width: "100%",
    padding: "16px 20px",
    border: "1px solid #652786 !important"
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "6px"
  }
});
const CheckboxMain = styled(Checkbox)({
  color: '#652786 !important',
});

const SaveAndCreate = styled(Button)({
  width: "411px",
  height: "56px",
  padding: "20px 32px",
  "@media (max-width:960px)": {
    width: "240px",
    height: "56px",
    padding: "10px 21px",
  },
})

export default withStyles(styles)(BranchDetails);
// Customizable Area End