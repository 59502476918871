import React from "react";
// Customizable Area Start
import { Box, Typography, Checkbox, Button ,  IconButton} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import SelectContentController, {
    Props
} from "./SelectContentController.web";
import { noDataImage, Vector ,GroupImg} from "./assets";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ToastContainer} from "react-toastify";
import Modal from "@material-ui/core/Modal";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const webStyle = {
    addButton: {
        width: "165px",
        border: "none",
        height: "42px",
        background: "#652786",
        borderRadius: "12px",
        fontWeight: 600,
        fontSize: "16px",
        color: "white",
        cursor: "pointer"
    },
    disabledAdd: {
        width: "165px",
        border: "none",
        height: "42px",
        background: "rgb(228,226,225)",
        borderRadius: "12px",
        fontWeight: 600,
        fontSize: "16px",
        color: "white",
    },
    removeButton: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #E47004",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
    },
    removedisableButton: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid rgb(228,226,225)",
        color: "rgba(0, 0, 0, 0.41)",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
    },
    addButtonBoxstyle: {
        display: "flex",
        padding: "16px 16px 20px",
        justifyContent: "flex-end",
        alignItems: "end",
        gap: "24px",
    },
    removeButtonBoxStyle: {
        display: "flex",
        padding: "16px 16px 20px",
        justifyContent: "flex-start",
        gap: "24px",
    },

    scrollContainer: {
        height: "100%"
    },
};
// Customizable Area End

export default class SelectContent extends SelectContentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderModal = () => {
        return (
            <Modal
                open={this.state.isLeave}
                onClose={this.handleLeaveClose}
            >
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                    <DivModal>
                        <IconButton
                              onClick={this.handleLeaveClose} 
                            style={{
                                position: 'absolute', right: "-5px",
                                top: "-8px",
                                background: "#652786",
                                width: "10px",
                                height: "10px"
                            }}
                        >
                            <CloseIcon style={{ color: "white", fontSize: "14px" }} />
                        </IconButton>
                        <Typography variant="h6" style={{ fontSize: "22px", paddingBottom: "20px", fontWeight: 600}}>
                            Save to avoid losing changes
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <ButtonModal
                                onClick={this.handleBackAchievement}
                                variant="outlined"
                            >
                                Leave
                            </ButtonModal>
                            <ButtonDarkModal
                                onClick={this.handleLeaveClose}
                                variant="contained"
                            >
                                Keep Editing
                            </ButtonDarkModal>
                        </div>
                    </DivModal>
                </Box>
            </Modal>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isCourseSelected, assignedData, selectedUnassigned, selectedAssigned } = this.state;
        const dataToDisplay = isCourseSelected ? this.state.courseListData : this.state.programListData;
        return (
            <> 
                <IconBack
                 onClick={this.handleBackCreate}
                />
                <Wrapper>
                    <div className="headerBox">
                    <div className="iconContainer"> 
                            {
                                this.state?.icon &&
                                <span className={this.state.isPresent == "false" ? "iconBox" : "iconBoxReward"}>
                                    <img src={this.state?.icon} style={{ width: "30px", height: "30px" }} />
                                </span>
                            }   
                        <span className="iconsName">{this.state.achiName ? this.state.achiName : this.state.isRewardValue}</span>
                    </div>
                    <Box className="toggleContainer">
                        <Button fullWidth className={isCourseSelected ? "selectedToggle" : "notSelectedToggle"} variant='outlined' color="primary" onClick={() => this.handleToggle("course")} data-test-id="toggleButtonId">
                            Course
                        </Button>
                        <Button fullWidth className={isCourseSelected ? "notSelectedToggle" : "selectedToggle"} variant='outlined' onClick={this.handleToggle} data-test-id="program">
                            Program
                        </Button>
                    </Box>
                    </div>
                    <Box style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit,minmax(360px,1fr))", gap: "20px", width: "100%", marginTop: "38px" }}>
                        <Box className="unassignedContainer">
                            <Typography style={{ fontWeight: 600, fontSize: "22px", color: "#1C1B1B" }}>Library</Typography>
                            <Box style={webStyle.scrollContainer} className="boxContainer">
                                {dataToDisplay?.map((item) => (
                                    <Box
                                        className={selectedUnassigned.includes(item.id) ? "rowBoxActive" : "rowBox"}
                                        key={item.id}
                                        onClick={() => this.handleSelect(item.id, 'unassigned')}
                                        data-test-id="selectCheckboxid"
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox
                                                style={{ color: selectedUnassigned.includes(item.id) ? "#652786" : "grey" }}
                                                checked={selectedUnassigned.includes(item.id)}
                                                onChange={(event) => {
                                                    event.stopPropagation();
                                                    this.handleSelect(item.id, 'unassigned');
                                                }}
                                            />
                                            <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
                                                <img style={{ width: "40px", height: "40px" }} src={item.attributes.thumbnail_image.url} />
                                                <Title>{item.attributes.title}</Title>
                                            </div>
                                        </div>
                                        <Statuses>{item.attributes.status}</Statuses>
                                    </Box>
                                ))}
                            </Box>
                            <Box style={webStyle.addButtonBoxstyle}>
                                <Button
                                    style={!selectedUnassigned.length ? webStyle.disabledAdd : webStyle.addButton }
                                    onClick={this.handleAdd}
                                    disabled={!selectedUnassigned.length}
                                    className="btnn"
                                    data-test-id="selectButtonId"
                                >
                                    Select &nbsp;&nbsp; <img src={Vector} style={{ width: "16px", height: "12px" }} />
                                </Button>
                            </Box>
                        </Box>

                        <Box className="assignedContainer">
                            <Typography style={{ fontWeight: 600, fontSize: "22px", color: "#1C1B1B" }} data-test-id="selecteId" >Selected</Typography>
                            <Box style={webStyle.scrollContainer} className="boxContainer">
                                {this.state.assignedData.length > 0 ?
                                    <>
                                        {assignedData.map((item: { id: string; attributes: { thumbnail_image: { url: string; }; title: string; status: string }; }) => (
                                            <Box
                                                className={selectedAssigned.includes(item.id) ? "rowBoxActive" : "rowBox"}
                                                key={item.id}
                                                onClick={() => this.handleSelect(item.id, 'assigned')}
                                                data-test-id="assignedButtonId"
                                            >
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Checkbox
                                                        style={{ color: selectedAssigned.includes(item.id) ? "#652786" : "grey" }}
                                                        checked={selectedAssigned.includes(item.id)}
                                                        onChange={(event) => {
                                                            event.stopPropagation();
                                                            this.handleSelect(item.id, 'assigned');
                                                        }}
                                                    />
                                                    <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
                                                        <img style={{ width: "40px", height: "40px" }} src={item.attributes.thumbnail_image.url} />
                                                        <Title variant="body1">{item.attributes.title}</Title>
                                                    </div>
                                                </div>
                                                <Statuses>{item.attributes.status}</Statuses>
                                            </Box>
                                        ))}
                                    </>
                                    :
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                        <img src={noDataImage} className="imageClass" />
                                        <p style={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}>Select the content you want to have the achievement/reward</p>
                                    </Box>
                                }
                            </Box>

                            <Box style={webStyle.removeButtonBoxStyle}>
                                <Button
                                    style={!selectedAssigned.length ? webStyle.removedisableButton : webStyle.removeButton}
                                    onClick={this.handleRemove}
                                    disabled={!selectedAssigned.length}
                                    className="btnn"
                                    data-test-id="removeButtonId"
                                >
                                    <ArrowBackIcon style={{ fontSize: "20px" }} />&nbsp;&nbsp;Remove
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                
                <div style={{display: "flex" ,alignItems: "center", justifyContent: "center"}}>
                <Button className={this.state.assignedData.length > 0 ? "goback" : "gobackDisable" } disabled={this.state.assignedData.length === 0} onClick={this.handleGoBack} data-test-id="selectGobackId">Select and Go back</Button>
                </div>
                <ToastContainer position="top-right" />
                </Wrapper> 
                {this.renderModal()} 
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const Statuses = styled("div")({
    backgroundColor: "#E0D4E7",
    textAlign: "center",
    padding: "6px 9px",
    color: "#542070",
    fontSize: "14px",
    borderRadius: "88px",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "125px",
    height: "40px",
    "@media (max-width : 520px)" : {
        fontSize: "14px",
        width: "110px",
    },
    "@media (max-width : 480px)" : {
        fontSize: "12px",
        width: "100px",
    },
    "@media (max-width : 420px)" : {
        fontSize: "12px",
        width: "90px",
    }
});

const Title = styled(Typography)({
    color: "#484646", 
    fontSize: "16px", 
    fontWeight: 400,
    "@media (max-width : 520px)" : {
        fontSize: "14px",
    },
    "@media (max-width : 480px)" : {
        fontSize: "12px",
    },
    "@media (max-width : 420px)" : {
        fontSize: "12px",
    }
});

const Wrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flexFlow: "wrap",
    padding: "20px",
    "& .goback": {
        marginTop: "40px",
        cursor: "pointer",
        textTransform: "capitalize",
        width: "400px",
        height: "42px",
        padding: "12px 24px",
        borderRadius: "12px",
        border: "1px solid #E47004",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600
    },
    "& .gobackDisable": {
        marginTop: "40px",
        textTransform: "capitalize",
        width: "400px",
        height: "42px",
        cursor: "pointer",
        color: "rgba(0, 0, 0, 0.41)",
        fontFamily: "Poppins",
        fontSize: "16px",
        padding: "12px 24px",
        borderRadius: "12px",
        border: "1px solid rgb(228,226,225)",
        fontWeight: 600
    },
    "& .unassignedContainer": {
        boxSizing: 'border-box',
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)",
        height: "695px",
        padding: "20px",
        borderRadius: "16px",
    }
    ,
    "& .assignedContainer": {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)",
        height: "695px",
        padding: "20px",
        borderRadius: "16px",
    },
    "& .removeBtnContainer": {

    },
    "& .rowBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)",
        padding: "16px 24px",
        borderRadius: "12px",
        maxWidth: "588px",
        height: "72px",
        cursor: "pointer",
        margin: "10px 5px",
       "@media (max-width : 520px)" : {
           padding: "16px 12px",
        },
        "@media (max-width : 480px)" : {
           padding: "16px 8px",
        },
        "@media (max-width : 420px)" : {
           padding: "16px 2px",
        }
    },
    "& .rowBoxActive": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)",
        padding: "16px 24px",
        borderRadius: "12px",
        maxWidth: "588px",
        height: "72px",
        cursor: "pointer",
        margin: "10px 5px",
        background: "#ebe1ef",
        "@media (max-width : 520px)" : {
           padding: "16px 12px",
        },
        "@media (max-width : 480px)" : {
           padding: "16px 8px",
        },
        "@media (max-width : 420px)" : {
           padding: "16px 2px",
        }
    },
    "& .selectedToggle": {
        textTransform: "none",
        color: "#1C1B1B",
        border: "1px solid red",
        borderRadius: "8px",
        background: "white",
        borderColor: "transparent",
        transform: "scale(1.3)",
        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Poppins"
    },
    "& .notSelectedToggle": {
        textTransform: "none",
        color: "#1C1B1B",
        borderColor: "transparent",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins"
    },
    "& .toggleContainer": {
        marginLeft: "45px !important",
        width: "370px",
        height: "48px",
        display: "flex",
        background: "#fdf7f8",
        gap: "20px",
        borderRadius: "12px",
        padding: "4px 8px",
        boxShadow: "1px 1px 9px 1px #0000001D",
        "@media (max-width : 920px)" : {
            marginLeft: "15px !important",
            marginTop: "20px !important",
        }
    },
    "& .boxContainer": {
        overflowY: "auto",
        overflowX: "hidden"
    },
    "& .btnn": {
        textTransform: "capitalize"
    },
    "& .imageClass": {
        width: "540px",
        height: "404px"
    },
    "& .iconContainer": {
        width: "304px",
        height: "72px",
        display: "flex",
        alignItems: "center",
        background:"white",
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)"
    },
    "& .headerBox": {
        display: "flex" , 
        alignItems:"center",
        flexFlow: "wrap",
        gap: "30px"
    },
    "& .iconsName": {
       color: "#545650", 
       fontWeight: 500, 
       fontSize: "16px",
       marginLeft: "15px"
    },
    "& .iconBox": {
       backgroundColor: "#652786",
       width: "48px",
       height: "48px",
       borderRadius: "8px",
       marginLeft: "20px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center"
    },
    "& .iconBoxReward": {
        backgroundColor: "#E70E6B",
        width: "48px",
        height: "48px",
        borderRadius: "8px",
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
     },
    "& .Toastify__progress-bar":{
        backgroundColor: "green !important"
    }

});


const ButtonModal = styled(Button) ({
    width: "214px",
    height: "42px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "12px",
    border : "1px solid #E47004",
    color: "#E47004",
    textTransform: "capitalize",
    "@media(max-width: 535px)": {
      width: "120px",
    },
    "@media(max-width: 398px)": {
      width: "100px",
    }
  });
  
  const ButtonDarkModal = styled(Button) ({
    width: "214px",
    height: "42px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "12px",
    color: "white",
    background: "#652786",
    textTransform: "capitalize",
    marginLeft: "20px",
    "&.MuiButton-contained:hover": {
     backgroundColor: "#652786 !important",
     color: "white !important"
    },
    "@media(max-width: 520px)": {
      width: "135px",
    }
  });

  const IconBack = styled(ArrowBackIosIcon) ({
    cursor: "pointer",
     color: "#92929D", 
     position: "fixed",
    zIndex: 1300,
    left: "135px",
    top: "28px",
    "@media(max-width: 600px)": {
      left: "35px",
    },
    "@media(max-width: 400px)": {
      left: "35px",
    }
  });

  const DivModal = styled("div") ({
    backgroundColor: "white", 
    height: "150px", 
    borderRadius: "12px", 
    padding: "30px 30px", 
    position: 'relative', 
    left: -28,
    "@media(max-width: 395px)": {
        height: "186px",
        width: "360px" ,
        left: -20,
      }
  });
// Customizable Area End