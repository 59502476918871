// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";
const configJSON = require("./config.js");
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";

export interface BranchResponse {
  data: {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      branch_status: string,
      parent_id: number,
      teams: null,
      is_primary_branch: boolean,
      unique_accounts_count: number,
      created_by: string,
      parent_branch_name: string,
      active_user_accounts: number
    }
  }
}

interface UserTeams {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    is_selected: boolean,
  };
}

export interface AddContractResponse{
  id: number;
  type: string;
  attributes: {
      created_at: string;
      branch_detail_id: number;
      file: {
          id: number;
          url: string;
          filename: string;
      }
  }
}

export interface CheckboxSelectedListType { id: number, value: string, _destroy:boolean }

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  branchData: Array<BranchResponse>;
  branchId: number;
  parentBranch: string;
  branchName: string;
  branchManager: string;
  branchStatus: string;
  openUserDeleteDialog: boolean;
  openTeamDialog: boolean;
  dropdownAddTeams: boolean;
  anchorEl: HTMLElement | null;
  placeholderSearchTeamsText: string;
  teamsListing: Array<UserTeams>;
  teamsSelectedItems: Array<CheckboxSelectedListType>;
  teamsToDelete: Array<CheckboxSelectedListType>;
  loading: boolean;
  contracts: AddContractResponse[];
}

interface SS {
  id: any;
}

export default class BranchDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleSetSubmittingCallback: Function = () => { };
  branchListApiCallId: string = "";
  deleteUserDataApiCallId: string = "";
  getTeamListApiCallId: string = "";
  updateTeamsToUserApiCallId: string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;
  fileInputRef: React.RefObject<HTMLInputElement> | undefined;
  addContractApiCallId: string = "";
  deleteContractApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      branchData: [],
      branchId: 0,
      parentBranch: "",
      branchName: "",
      branchManager: "",
      branchStatus: "",
      openUserDeleteDialog: false,
      openTeamDialog:false,
      dropdownAddTeams: false,
      anchorEl: null,
      placeholderSearchTeamsText: "",
      teamsListing: [],
      teamsSelectedItems: [],
      teamsToDelete: [],
      loading: false,
      contracts: []
    };
    this.dropdownRef = React.createRef();
    this.fileInputRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    let userDataId = this.props?.navigation?.getParam('id')
    this.getBranchList(Number(userDataId));
    this.setState({ teamsSelectedItems: [] });
    this.getTeamsListings();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.branchListApiCallId) {
        const teamsSelectedItemsData = responseJson.data.attributes.teams?.map((team: any) => ({
          id: Number(team.id),
          value: team.name
        })) || [];

        const teamsSelectedItemsIds: number[] = teamsSelectedItemsData.map((item: {id: number, name: string}) =>Number(item.id))
        const teamsSteamsListingFromStorage: Array<CheckboxSelectedListType> = await getStorageData("tempSelectedTeamInBranch", true) || [];
        const uniqueTeamData  = teamsSteamsListingFromStorage.filter((team) => !teamsSelectedItemsIds.includes(team.id))
        const combineItemsData = [...teamsSelectedItemsData, ...uniqueTeamData];

        const uniqueTeams: CheckboxSelectedListType[] = Array.from(new Set(teamsSelectedItemsData.map((team: CheckboxSelectedListType) => team.id)))
        .map(id => teamsSelectedItemsData.find((team: CheckboxSelectedListType) => team.id === id)!);
        this.setState({
          branchData: responseJson.data,
          branchId: responseJson.data.attributes.id,
          parentBranch: responseJson.data.attributes.parent_branch_name,
          branchName: responseJson.data.attributes.name,
          branchManager: responseJson.data.attributes.created_by,
          branchStatus: responseJson.data.attributes.branch_status,
          teamsSelectedItems: combineItemsData,
          teamsToDelete: uniqueTeams,
          contracts: responseJson.data?.attributes?.branch_contracts
        })
         await removeStorageData("tempSelectedTeamInBranch");
      } else if (this.getTeamListApiCallId === apiRequestCallId) {
        this.setState({
          teamsListing: responseJson.data
        })
      }
      if (apiRequestCallId === this.deleteUserDataApiCallId) {
        toast.success("Branch deleted successfully");
        this.props.navigation.navigate("ShowBranch")
      }
      if (apiRequestCallId === this.updateTeamsToUserApiCallId) {
        this.updateTeamsToUserResponse(from, message, responseJson)
      }
      this.handleAddContractResponse(apiRequestCallId, responseJson)
      this.handleDeleteContractResponse(apiRequestCallId, responseJson)
    }
  }

  handleAddContractResponse = (apiRequestCallId: string, responseJson: {data: AddContractResponse}) => {
    if(apiRequestCallId === this.addContractApiCallId) {
      this.setState({contracts: [...this.state.contracts, responseJson.data] as AddContractResponse[]})
    }
  }

  handleDeleteContractResponse = (apiRequestCallId: string, responseJson: {data: {}}) => {
    if(apiRequestCallId === this.deleteContractApiCallId) {
      
    }
  }

  updateTeamsToUserResponse = (from: string, message: Message, responseJson: any) => {
    this.setState({ loading: true });
    if (responseJson?.data) {
      toast.success(`Teams update successfully`, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(); 
      }, 1000); 
    } else {
      responseJson?.errors.map((err: { message: string }) => {
        toast.error(err.message);
      });
    }
    setTimeout(() => {
      this.setState({ placeholderSearchTeamsText:"",openTeamDialog: !this.state.openTeamDialog, loading: false }); 
  }, 3000);
  };

  handleAssignContent = (id: number) =>{
    const branchId = this.state.branchId;
    this.props.navigation.navigate("BranchAssignContent", {id: branchId});
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownAddTeams: !prevState.dropdownAddTeams,
      placeholderSearchTeamsText:""
    }));
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownAddTeams: false,
    })
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.dropdownHandlerClose();
    }
  };

  handleTeamsSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchTeamsText: event.target.value,
    })
  }

  tagsCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, teamId: number) => {
    const { checked, name } = event.target;
    const parsedTeamId = Number(teamId);
    if (checked) {
      const isAlreadySelected = this.state.teamsSelectedItems.find(team => team.id === parsedTeamId);
      if (!isAlreadySelected) {
        this.setState(prevState => ({
          teamsSelectedItems: [...prevState.teamsSelectedItems, { id: parsedTeamId, value: name, _destroy: false }]
        }));
      }
    } else {
      this.setState(prevState => ({
        teamsSelectedItems: prevState.teamsSelectedItems.filter(team => team.id !== parsedTeamId)
      }));
    }
  }

  handleClearTeamNames = (team: CheckboxSelectedListType) => {
    const numericTeamId = Number(team.id);
    this.setState(prevState => ({
      teamsSelectedItems: prevState.teamsSelectedItems.filter(t => t.id !== numericTeamId),
    }));
  };

  getBranchList = async (branchId: number) => {
    this.branchListApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.branchListEndPoint2}/${branchId}`,
    });
  };

  handleDeleteUserData = () => {
    this.deleteBranchValue();
    this.setState({ openUserDeleteDialog: !this.state.openUserDeleteDialog })
  }

  UpdateData = ( teamsAccountsAttributes: any[]) => {
    const selectedTeamIds = this.state.teamsSelectedItems.map((team: any) => team.id);
    const teamsToDeleteIds = this.state.teamsToDelete.map((team: any) => team.id
    );
    const teamsToRemove = teamsToDeleteIds.filter((id: number) => !selectedTeamIds.includes(id));
    const teamsToAdd = selectedTeamIds.filter((id: number) => !teamsToDeleteIds.includes(id));
    if (teamsToRemove.length) {
      teamsToRemove.forEach((id: number) => {
        teamsAccountsAttributes.push({
          id: id,        
          _destroy: true,
        });
      });
    }
    if (teamsToAdd.length) {
      teamsToAdd.forEach((team_id: number) => {
        teamsAccountsAttributes.push({
          team_id: team_id, 
          _destroy: false,
        });
      });
    }
    return teamsAccountsAttributes;
  };

  updateTeamsToUser = async (values: any) => {
    const teamsAccountsAttributes: any[] = [];
    this.UpdateData(teamsAccountsAttributes);
    let userData = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    const userApiData = {
      "branch_data": {
        "branch_teams_attributes": teamsAccountsAttributes
      }
    };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateTeamsToUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(userApiData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.branchListEndPoint2}/${userData}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  deleteBranchValue() {
    let branchData = this.props?.navigation?.getParam('id')
    this.deleteBranchData({
      content_Types: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethod,
      end_Point: `${configJSON.branchListEndPoint2}/${branchData}`,
    });
  }

  getTeamsListings = () => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTeamListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamListingApiEndPoint}?search=${this.state.placeholderSearchTeamsText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  deleteBranchData(data: {
    content_Types?: string;
    end_Point?: string;
    method?: string;
    body?: {};
    type?: string;
  }) {
    let branchData = this.props?.navigation?.getParam('id')
    const { content_Types, method, end_Point } = data;
    const header = {
      "Content-Type": content_Types,
      token: localStorage.getItem("token"),
    };
    const deleteBody = {
      "branch_data":{
          "parent_id": branchData
      }
    }
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(deleteBody)
    );
    this.deleteUserDataApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      end_Point
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  cancelLessonDeleteDialog = () => {
    this.setState({ openUserDeleteDialog: !this.state.openUserDeleteDialog })
  }

  teamLessonDialog = () =>{
    this.setState({openTeamDialog:!this.state.openTeamDialog})
  }
  
  navigateToManageTeam = (teamId: number) => {
    setStorageData("tempSelectedTeamInBranch", JSON.stringify(this.state.teamsSelectedItems))
    this.props.navigation.navigate("CreateTeam", {type:"Edit", id: teamId, redirectToBranch: "redirectToBranch"})
  } 
  openPDFFileSelect = () => {
    if(this.state.contracts.length >= 3) {
      toast.warning("You can add more than 3 contract files")
    } else{
    this.fileInputRef?.current?.click()}
  }

  handleFileChange = (event: any) => {
    const file = event?.target?.files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.warning("You can not upload pdf size more than 2 MB")
    }
    else {
      this.addContractFile(file)
    }
  };

  addContractFile = (file: File) => {

    let branchId = this.props?.navigation?.getParam('id')
    const header = {
      token: localStorage.getItem("token"),
    };

    const formData = new FormData();
    formData.append("branch_id", branchId)
    formData.append("file", file);

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.addContractApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addContractEndPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  deleteContractFile = (contractId: number) => {

    const filterContractFiles = this.state.contracts.filter((contract) => Number(contract.id) !== contractId)
    this.setState({contracts: filterContractFiles});

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteContractApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteContractEndPoint}${contractId}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

}
// Customizable Area End